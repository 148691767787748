@import "../../assets/scss/base.scss";

.lp-history {
  padding: 50px 0;
  .history__container {
    .history__title {
      text-align: center;
      color: #141313;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px;
      }
    }
    .history__icon {
      height: 12px;
      width: 40px;
      object-fit: contain;
      display: block;
      margin: 25px auto;
      color: #dfb452;
    }

    .history__main {
      max-width: 780px;
      margin: 0 auto;
      align-items: flex-start;

      @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
      }

      .history__text {
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #141313;

        padding: 10px;
        text-align: justify;

        @media only screen and (max-width: $bp-medium) {
          padding: 0 20px;
        }
        .history__accent {
          font-family: "merriwather", serif;
          font-size: 28px;
          line-height: 35px;
          text-align: center;
          color: #b79c66;
          margin-right: 20px;
        }
      }
      .history__left {
        .history__text {
        }
      }
      .history__right {
        .history__text {
        }
      }
    }
  }
}
