body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "icons";
  src: url(/static/media/icons.70534caa.eot?#iefix) format("embedded-opentype"), url(/static/media/icons.dae39dee.woff2) format("woff2"), url(/static/media/icons.30fdb2e1.woff) format("woff"), url(/static/media/icons.a92c590b.ttf) format("truetype"), url(/static/media/icons.835c04fe.svg#icons) format("svg"); }

span[class^="af-icon-"]:before, span[class*=" af-icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.af-icon-3d:before {
  content: "\F101"; }

.af-icon-3d_2:before {
  content: "\F102"; }

.af-icon-accent:before {
  content: "\F103"; }

.af-icon-alpine:before {
  content: "\F104"; }

.af-icon-angle-down-solid:before {
  content: "\F105"; }

.af-icon-angle-left-solid:before {
  content: "\F106"; }

.af-icon-angle-right-solid:before {
  content: "\F107"; }

.af-icon-angle-up-solid:before {
  content: "\F108"; }

.af-icon-apartment-finish-line:before {
  content: "\F109"; }

.af-icon-arrow-bottom:before {
  content: "\F10A"; }

.af-icon-arrow-down:before {
  content: "\F10B"; }

.af-icon-arrow-left:before {
  content: "\F10C"; }

.af-icon-arrow-right:before {
  content: "\F10D"; }

.af-icon-arrow-top:before {
  content: "\F10E"; }

.af-icon-bank:before {
  content: "\F10F"; }

.af-icon-bars-solid:before {
  content: "\F110"; }

.af-icon-beauty:before {
  content: "\F111"; }

.af-icon-benefits-center:before {
  content: "\F112"; }

.af-icon-benefits-comfort:before {
  content: "\F113"; }

.af-icon-benefits-infrastructure:before {
  content: "\F114"; }

.af-icon-benefits-key:before {
  content: "\F115"; }

.af-icon-benefits-kids:before {
  content: "\F116"; }

.af-icon-benefits-nature:before {
  content: "\F117"; }

.af-icon-benefits-park:before {
  content: "\F118"; }

.af-icon-benefits-safety:before {
  content: "\F119"; }

.af-icon-bottom:before {
  content: "\F11A"; }

.af-icon-building:before {
  content: "\F11B"; }

.af-icon-button-left:before {
  content: "\F11C"; }

.af-icon-button-right:before {
  content: "\F11D"; }

.af-icon-camera:before {
  content: "\F11E"; }

.af-icon-church:before {
  content: "\F11F"; }

.af-icon-close:before {
  content: "\F120"; }

.af-icon-construction:before {
  content: "\F121"; }

.af-icon-culture:before {
  content: "\F122"; }

.af-icon-door:before {
  content: "\F123"; }

.af-icon-education:before {
  content: "\F124"; }

.af-icon-elevator:before {
  content: "\F125"; }

.af-icon-envelope:before {
  content: "\F126"; }

.af-icon-facebook:before {
  content: "\F127"; }

.af-icon-health-service:before {
  content: "\F128"; }

.af-icon-instagram:before {
  content: "\F129"; }

.af-icon-left:before {
  content: "\F12A"; }

.af-icon-list-item:before {
  content: "\F12B"; }

.af-icon-logo:before {
  content: "\F12C"; }

.af-icon-long-arrow-alt-left-solid:before {
  content: "\F12D"; }

.af-icon-long-arrow-alt-right-solid:before {
  content: "\F12E"; }

.af-icon-mail:before {
  content: "\F12F"; }

.af-icon-map:before {
  content: "\F130"; }

.af-icon-meeting-calculator:before {
  content: "\F131"; }

.af-icon-meeting-camera:before {
  content: "\F132"; }

.af-icon-meeting-chat:before {
  content: "\F133"; }

.af-icon-meeting-comfort:before {
  content: "\F134"; }

.af-icon-meeting-join:before {
  content: "\F135"; }

.af-icon-meeting-mail:before {
  content: "\F136"; }

.af-icon-meeting-pen:before {
  content: "\F137"; }

.af-icon-meeting-safety:before {
  content: "\F138"; }

.af-icon-meeting-time:before {
  content: "\F139"; }

.af-icon-menu:before {
  content: "\F13A"; }

.af-icon-minus-square:before {
  content: "\F13B"; }

.af-icon-nursery:before {
  content: "\F13C"; }

.af-icon-pdf:before {
  content: "\F13D"; }

.af-icon-pharmacy:before {
  content: "\F13E"; }

.af-icon-phone:before {
  content: "\F13F"; }

.af-icon-pin:before {
  content: "\F140"; }

.af-icon-playground:before {
  content: "\F141"; }

.af-icon-plus-square:before {
  content: "\F142"; }

.af-icon-pub:before {
  content: "\F143"; }

.af-icon-recreation:before {
  content: "\F144"; }

.af-icon-right:before {
  content: "\F145"; }

.af-icon-scroll:before {
  content: "\F146"; }

.af-icon-search:before {
  content: "\F147"; }

.af-icon-shop:before {
  content: "\F148"; }

.af-icon-system:before {
  content: "\F149"; }

.af-icon-table-arrow-down:before {
  content: "\F14A"; }

.af-icon-table-arrow-up:before {
  content: "\F14B"; }

.af-icon-tasks-solid:before {
  content: "\F14C"; }

.af-icon-thin-arrow-left:before {
  content: "\F14D"; }

.af-icon-thin-arrow-right:before {
  content: "\F14E"; }

.af-icon-tick:before {
  content: "\F14F"; }

.af-icon-times-solid:before {
  content: "\F150"; }

.af-icon-top:before {
  content: "\F151"; }

.af-icon-walls:before {
  content: "\F152"; }

.af-icon-window:before {
  content: "\F153"; }

.af-icon-youtube:before {
  content: "\F154"; }

@font-face {
  font-family: "montserrat-bold";
  src: url(/static/media/montserrat-bold-webfont.a939cae8.eot);
  src: url(/static/media/montserrat-bold-webfont.a939cae8.eot) format("embedded-opentype"), url(/static/media/Montserrat-Bold.ade91f47.ttf) format("truetype"), url(/static/media/montserrat-bold-webfont.a1b7067e.woff) format("woff"), url(/static/media/montserrat-bold-webfont.b4c693c2.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "montserrat-medium";
  src: url(/static/media/montserrat-medium-webfont.ae7d2c11.eot);
  src: url(/static/media/montserrat-medium-webfont.ae7d2c11.eot) format("embedded-opentype"), url(/static/media/Montserrat-Medium.c8b6e083.ttf) format("truetype"), url(/static/media/montserrat-medium-webfont.64218132.woff) format("woff"), url(/static/media/montserrat-medium-webfont.33929f9a.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "montserrat-light";
  src: url(/static/media/montserrat-light-webfont.7256e332.eot);
  src: url(/static/media/montserrat-light-webfont.7256e332.eot) format("embedded-opentype"), url(/static/media/Montserrat-Light.409c7f79.ttf) format("truetype"), url(/static/media/montserrat-light-webfont.6f2db543.woff) format("woff"), url(/static/media/montserrat-light-webfont.2aa035fb.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "montserrat";
  src: url(/static/media/montserrat-regular-webfont.c87fdbd6.eot);
  src: url(/static/media/montserrat-regular-webfont.c87fdbd6.eot) format("embedded-opentype"), url(/static/media/Montserrat-Regular.ee653992.ttf) format("truetype"), url(/static/media/montserrat-regular-webfont.523737fc.woff) format("woff"), url(/static/media/montserrat-regular-webfont.bcf70e65.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "merriweather-black";
  src: url(/static/media/merriweather-black-webfont.00e110f0.eot);
  src: url(/static/media/merriweather-black-webfont.00e110f0.eot) format("embedded-opentype"), url(/static/media/Merriweather-Black.bfc959a1.ttf) format("truetype"), url(/static/media/merriweather-black-webfont.c35480bd.woff) format("woff"), url(/static/media/merriweather-black-webfont.a69a7694.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "merriweather-bold";
  src: url(/static/media/merriweather-bold-webfont.0f999f3f.eot);
  src: url(/static/media/merriweather-bold-webfont.0f999f3f.eot) format("embedded-opentype"), url(/static/media/Merriweather-Bold.9ccb885c.ttf) format("truetype"), url(/static/media/merriweather-bold-webfont.33b8b39d.woff) format("woff"), url(/static/media/merriweather-bold-webfont.644fb214.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "merriweather-light";
  src: url(/static/media/merriweather-light-webfont.45ebb933.eot);
  src: url(/static/media/merriweather-light-webfont.45ebb933.eot) format("embedded-opentype"), url(/static/media/Merriweather-Light.9341552d.ttf) format("truetype"), url(/static/media/merriweather-light-webfont.0d04746b.woff) format("woff"), url(/static/media/merriweather-light-webfont.cd19363c.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "merriweather";
  src: url(/static/media/merriweather-regular-webfont.83676903.eot);
  src: url(/static/media/merriweather-regular-webfont.83676903.eot) format("embedded-opentype"), url(/static/media/Merriweather-Regular.c97a9fc2.ttf) format("truetype"), url(/static/media/merriweather-regular-webfont.f76b11db.woff) format("woff"), url(/static/media/merriweather-regular-webfont.11aeb407.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  position: relative;
  box-sizing: border-box; }

.text-basic {
  font-family: "montserrat", sans-serif;
  font-size: 17px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal; }

.title-basic {
  font-family: "montserrat-bold", sans-serif;
  font-size: 38px;
  line-height: 47px;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal; }

.button-basic {
  font-family: "montserrat-medium", sans-serif;
  display: block;
  color: #fff;
  outline: 0;
  text-decoration: none;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none; }

.button-white {
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden;
  z-index: 1; }
  .button-white:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: white;
    -webkit-transform: translate3d(-100%, -50%, 0);
    transform: translate3d(-100%, -50%, 0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }
  .button-white:hover {
    color: #141313; }
    .button-white:hover:before {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }

.button-black {
  border: 2px solid #141313;
  color: #141313;
  background-color: transparent;
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden;
  z-index: 1; }
  .button-black:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #141313;
    -webkit-transform: translate3d(-100%, -50%, 0);
    transform: translate3d(-100%, -50%, 0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }
  .button-black:hover {
    color: #fff; }
    .button-black:hover:before {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }

.button-white-other {
  border: 2px solid #efefef;
  color: #141313;
  background-color: #efefef;
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden;
  z-index: 1; }
  .button-white-other:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: white;
    border: 2px solid #fff;
    -webkit-transform: translate3d(-100%, -50%, 0);
    transform: translate3d(-100%, -50%, 0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }
  .button-white-other:hover:before {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-header {
  background: transparent;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  max-width: 100vw; }
  .lp-header.header-moving, .lp-header.black {
    background: #141414;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #141414), color-stop(9%, rgba(20, 20, 20, 0.92)), color-stop(57%, rgba(20, 20, 20, 0.84)), color-stop(84%, rgba(52, 52, 52, 0.8)), color-stop(95%, rgba(65, 65, 65, 0.8)), color-stop(100%, rgba(110, 110, 110, 0.8)));
    background: linear-gradient(to bottom, #141414 0%, rgba(20, 20, 20, 0.92) 9%, rgba(20, 20, 20, 0.84) 57%, rgba(52, 52, 52, 0.8) 84%, rgba(65, 65, 65, 0.8) 95%, rgba(110, 110, 110, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#141414', endColorstr='#6e6e6e', GradientType=0 ); }
  .lp-header .nav__toApartments {
    width: 100%;
    height: 0px;
    background-color: #fff;
    color: #141313;
    align-items: center;
    justify-content: center;
    display: none;
    text-transform: unset;
    transition: height 0.3s ease-in-out;
    opacity: 0; }
    .lp-header .nav__toApartments .icon {
      font-size: 30px;
      margin-right: 10px;
      line-height: unset; }
    .lp-header .nav__toApartments .text {
      font-family: "montserrat-medium", sans-serif;
      font-size: 16px; }
    @media only screen and (max-width: 610px) {
      .lp-header .nav__toApartments {
        display: flex; } }
    .lp-header .nav__toApartments.active {
      opacity: 1;
      height: 50px; }
  .lp-header .header__navigation {
    background: rgba(20, 20, 20, 0.95);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: -100;
    transition: all 0.5s ease-in-out; }
    .lp-header .header__navigation.active {
      display: block; }
    .lp-header .header__navigation .navigation__container {
      width: 100%;
      height: 100%;
      position: relative; }
      .lp-header .header__navigation .navigation__container .nav__list {
        display: flex;
        width: 100%;
        height: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 85px 0 0 0; }
        @media only screen and (max-width: 1079px) {
          .lp-header .header__navigation .navigation__container .nav__list {
            flex-direction: column;
            padding-top: 150px; } }
        .lp-header .header__navigation .navigation__container .nav__list .nav__list-item {
          width: 25%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid rgba(240, 240, 240, 0.7); }
          .lp-header .header__navigation .navigation__container .nav__list .nav__list-item:last-of-type {
            border-right: 0; }
            @media only screen and (max-width: 1079px) {
              .lp-header .header__navigation .navigation__container .nav__list .nav__list-item:last-of-type {
                border-bottom: 0; } }
          @media only screen and (max-width: 1079px) {
            .lp-header .header__navigation .navigation__container .nav__list .nav__list-item {
              width: 100%;
              border-right: 0;
              max-height: 120px; } }
          @media only screen and (max-width: 610px) {
            .lp-header .header__navigation .navigation__container .nav__list .nav__list-item {
              max-height: 75px; } }
          .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 150px; }
            @media only screen and (max-width: 1079px) {
              .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box {
                height: auto; } }
            .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box .nav__link {
              display: block;
              text-decoration: none;
              color: #ffffff;
              font-family: "montserrat-medium", sans-serif;
              font-size: 32px;
              line-height: 40px;
              transition: 0.5s;
              padding-bottom: 30px; }
              @media only screen and (max-width: 1079px) {
                .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box .nav__link {
                  padding-bottom: 10px; } }
              @media only screen and (max-width: 610px) {
                .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box .nav__link {
                  padding-bottom: 6px;
                  font-size: 20px;
                  line-height: 24px; } }
              .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box .nav__link:hover {
                cursor: pointer; }
            .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box .nav__sublink {
              display: block;
              text-decoration: none;
              transition: 0.5s;
              font-family: "montserrat", sans-serif;
              font-size: 16px;
              line-height: 20px;
              color: #ffffff;
              padding-bottom: 10px; }
              @media only screen and (max-width: 1079px) {
                .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box .nav__sublink {
                  padding-bottom: 5px; } }
              @media only screen and (max-width: 610px) {
                .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box .nav__sublink {
                  padding-bottom: 0; } }
              .lp-header .header__navigation .navigation__container .nav__list .nav__list-item .nav__list-item-box .nav__sublink:hover {
                cursor: pointer; }
  .lp-header .header__container {
    padding: 20px 0; }
    @media only screen and (max-width: 610px) {
      .lp-header .header__container {
        padding: 16px 0; } }
    .lp-header .header__container .header-left__col {
      display: flex;
      align-items: center; }
      .lp-header .header__container .header-left__col .header__menu-button {
        border: 0;
        outline: 0;
        width: auto;
        height: auto;
        background: transparent;
        display: flex;
        align-items: center;
        cursor: pointer; }
        .lp-header .header__container .header-left__col .header__menu-button .header__menu {
          color: #fff;
          margin-left: 20px;
          font-size: 32px; }
          @media only screen and (max-width: 1079px) {
            .lp-header .header__container .header-left__col .header__menu-button .header__menu {
              font-size: 28px; } }
        .lp-header .header__container .header-left__col .header__menu-button .header__menu-text {
          font-size: 12px;
          color: #ffffff;
          text-transform: uppercase;
          font-family: "montserrat-medium", sans-serif;
          margin-left: 10px;
          display: none; }
          .lp-header .header__container .header-left__col .header__menu-button .header__menu-text.active {
            display: block; }
            @media only screen and (max-width: 610px) {
              .lp-header .header__container .header-left__col .header__menu-button .header__menu-text.active {
                display: none; } }
    .lp-header .header__container .header-main__col .header__logo-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-decoration: none; }
      .lp-header .header__container .header-main__col .header__logo-box .header__logo {
        display: block;
        height: 45px;
        width: auto;
        margin: 0 auto; }
        @media only screen and (max-width: 610px) {
          .lp-header .header__container .header-main__col .header__logo-box .header__logo {
            height: 38px; } }
      .lp-header .header__container .header-main__col .header__logo-box .header__logo-title {
        text-transform: uppercase;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.3em;
        padding: 2px 0; }
      .lp-header .header__container .header-main__col .header__logo-box .header__logo-text {
        text-transform: uppercase;
        font-size: 10px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.2em; }
    .lp-header .header__container .header-right__col {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .lp-header .header__container .header-right__col .header__nav {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none; }
        @media only screen and (max-width: 1079px) {
          .lp-header .header__container .header-right__col .header__nav {
            display: none; } }
        .lp-header .header__container .header-right__col .header__nav .header__nav-item {
          padding: 0 15px; }
          .lp-header .header__container .header-right__col .header__nav .header__nav-item .header__link {
            color: #fff;
            text-decoration: none;
            font-size: 14px;
            line-height: 16px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.27);
            text-transform: uppercase;
            font-family: "montserrat-bold", sans-serif; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.meeting-label {
  width: 100%;
  height: 50px;
  background-color: #b79c66;
  align-items: center;
  justify-content: center;
  display: none; }
  @media only screen and (max-width: 610px) {
    .meeting-label {
      display: flex; } }
  .meeting-label .meeting-label__link {
    display: block;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-family: "montserrat-medium", sans-serif;
    font-size: 16px;
    line-height: 19px; }
    .meeting-label .meeting-label__link .meeting-label__icon {
      color: #fff;
      font-size: 22px;
      margin-left: 12px; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.header__side-contact {
  height: auto;
  position: absolute;
  right: 0;
  top: 186px;
  display: none; }
  @media only screen and (max-width: 610px) {
    .header__side-contact {
      display: block; } }
  .header__side-contact .side__link {
    display: block;
    background: #35363a;
    width: 40px;
    height: 40px;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; }
    .header__side-contact .side__link.social {
      background: rgba(53, 54, 58, 0.7); }
    .header__side-contact .side__link .side__icon {
      color: #fff;
      font-size: 16px; }
    .header__side-contact .side__link .af-icon-mail {
      font-size: 13px; }


.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-contact-form {
  position: relative; }
  .lp-contact-form .form__sent-box {
    background: #b79c66;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
    width: 90%;
    height: auto;
    min-height: 120px;
    max-width: 320px;
    padding: 20px;
    border-radius: 3px; }
    .lp-contact-form .form__sent-box .form__sent {
      position: relative;
      text-align: center; }
      .lp-contact-form .form__sent-box .form__sent .form__sent-info {
        font-size: 18px;
        font-family: "montserrat-bold", sans-serif;
        padding-top: 40px; }
      .lp-contact-form .form__sent-box .form__sent .form__sent-close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer; }
  .lp-contact-form .contact__form .contact-form__inputs {
    flex-wrap: wrap; }
    .lp-contact-form .contact__form .contact-form__inputs .contact-form__radio {
      padding-bottom: 45px; }
      @media only screen and (max-width: 610px) {
        .lp-contact-form .contact__form .contact-form__inputs .contact-form__radio {
          padding-bottom: 25px; } }
      .lp-contact-form .contact__form .contact-form__inputs .contact-form__radio .radio__text {
        padding: 32px 0 20px;
        font-size: 16px;
        line-height: 19px;
        color: #35363a;
        font-family: "montserrat-medium", sans-serif; }
        @media only screen and (max-width: 1079px) {
          .lp-contact-form .contact__form .contact-form__inputs .contact-form__radio .radio__text {
            padding: 20px 0 20px 0; } }
      .lp-contact-form .contact__form .contact-form__inputs .contact-form__radio .radio__box {
        display: flex;
        justify-content: space-between;
        max-width: 750px; }
        @media only screen and (max-width: 610px) {
          .lp-contact-form .contact__form .contact-form__inputs .contact-form__radio .radio__box {
            flex-wrap: wrap;
            max-width: 100%;
            justify-content: flex-start; } }
    .lp-contact-form .contact__form .contact-form__inputs .contact-form__agreement-box {
      display: flex;
      align-items: center;
      padding-bottom: 20px; }
  .lp-contact-form .contact__form .required-info {
    display: block;
    font-family: "montserrat-light", sans-serif;
    line-height: 15px;
    font-size: 12px;
    padding-left: 35px; }
  @media only screen and (max-width: 1079px) {
    .lp-contact-form .contact__form .form__button-box {
      text-align: center; } }
  .lp-contact-form .contact__form .form__button-box .form__button {
    width: 264px;
    height: 56px;
    background: #b79c66;
    border: 1px solid #b79c66;
    margin-top: 50px;
    font-size: 16px;
    line-height: 56px;
    font-family: "montserrat-medium", sans-serif; }
    @media only screen and (max-width: 1079px) {
      .lp-contact-form .contact__form .form__button-box .form__button {
        margin: 30px auto 0 auto; } }
  .lp-contact-form .contact__form textarea,
  .lp-contact-form .contact__form input[type="email"],
  .lp-contact-form .contact__form input[type="text"] {
    font-family: "montserrat", sans-serif;
    font-size: 16px;
    height: 56px;
    line-height: 56px;
    color: #35363a;
    padding: 0 0 0 8px;
    border: none;
    border: 1px solid #d7d7d7;
    background: #fff;
    outline: 0;
    transition: 0.3s;
    display: block;
    width: calc(100% - 58px);
    margin: 10px 0;
    box-sizing: border-box; }
    @media only screen and (max-width: 1079px) {
      .lp-contact-form .contact__form textarea,
      .lp-contact-form .contact__form input[type="email"],
      .lp-contact-form .contact__form input[type="text"] {
        max-width: 100%;
        width: 100%;
        margin: 10px auto; } }
    @media only screen and (max-width: 610px) {
      .lp-contact-form .contact__form textarea,
      .lp-contact-form .contact__form input[type="email"],
      .lp-contact-form .contact__form input[type="text"] {
        font-size: 12px; } }
    .lp-contact-form .contact__form textarea ::-webkit-input-placeholder, .lp-contact-form .contact__form input[type="email"] ::-webkit-input-placeholder, .lp-contact-form .contact__form input[type="text"] ::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #35363a;
      opacity: 1;
      /* Firefox */ }
    .lp-contact-form .contact__form textarea ::placeholder,
    .lp-contact-form .contact__form input[type="email"] ::placeholder,
    .lp-contact-form .contact__form input[type="text"] ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #35363a;
      opacity: 1;
      /* Firefox */ }
    .lp-contact-form .contact__form textarea :-ms-input-placeholder,
    .lp-contact-form .contact__form input[type="email"] :-ms-input-placeholder,
    .lp-contact-form .contact__form input[type="text"] :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #35363a; }
    .lp-contact-form .contact__form textarea ::-ms-input-placeholder,
    .lp-contact-form .contact__form input[type="email"] ::-ms-input-placeholder,
    .lp-contact-form .contact__form input[type="text"] ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #35363a; }
    .lp-contact-form .contact__form textarea:focus,
    .lp-contact-form .contact__form input[type="email"]:focus,
    .lp-contact-form .contact__form input[type="text"]:focus {
      border: 1px solid #000000; }
    .lp-contact-form .contact__form textarea.error,
    .lp-contact-form .contact__form input[type="email"].error,
    .lp-contact-form .contact__form input[type="text"].error {
      border: 1px solid #ff0000; }
    .lp-contact-form .contact__form textarea:disabled,
    .lp-contact-form .contact__form input[type="email"]:disabled,
    .lp-contact-form .contact__form input[type="text"]:disabled {
      color: #658385; }
  .lp-contact-form .contact__form textarea {
    color: #35363a;
    border: 1px solid #d7d7d7;
    background: #fff;
    width: calc(100% - 58px);
    height: 112px;
    padding: 0 0 0 8px;
    margin: 10px 0; }
    @media only screen and (max-width: 1079px) {
      .lp-contact-form .contact__form textarea {
        max-width: 100%;
        width: 100%;
        margin: 10px auto; } }
    .lp-contact-form .contact__form textarea ::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #35363a;
      opacity: 1;
      /* Firefox */ }
    .lp-contact-form .contact__form textarea ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #35363a;
      opacity: 1;
      /* Firefox */ }
    .lp-contact-form .contact__form textarea :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #35363a; }
    .lp-contact-form .contact__form textarea ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #35363a; }
  @media only screen and (max-width: 610px) {
    .lp-contact-form .contact__form .radio__wrapper {
      width: 50%;
      padding: 10px 0 20px 0; } }
  .lp-contact-form .contact__form input[type="radio"] {
    display: none; }
  .lp-contact-form .contact__form input[type="radio"] + label {
    position: relative;
    display: block;
    padding-left: 35px;
    color: #35363a;
    font-size: 16px;
    font-family: "montserrat", sans-serif; }
  .lp-contact-form .contact__form input[type="radio"] + label::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border: 1px solid #707070;
    cursor: pointer;
    transition: 0.3s; }
  .lp-contact-form .contact__form input[type="radio"]:checked + label::before {
    background: #b79c66;
    border: 1px solid #b79c66; }
  .lp-contact-form .contact__form input[type="checkbox"] {
    display: none; }
  .lp-contact-form .contact__form .contact-form__privacy-info {
    color: #35363a;
    font-size: 16px;
    font-family: "montserrat", sans-serif;
    line-height: 23px;
    margin: 0;
    padding: 0 0 25px 0; }
  .lp-contact-form .contact__form .agreement-span {
    display: inline-block;
    font-family: "montserrat", sans-serif;
    line-height: 22px;
    font-size: 14px;
    margin-left: 12px;
    color: #35363a;
    max-width: 80%; }
  .lp-contact-form .contact__form input[type="checkbox"] + label {
    width: 24px;
    height: 24px;
    border: 1px solid #707070;
    cursor: pointer;
    transition: 0.3s;
    text-align: center; }
    .lp-contact-form .contact__form input[type="checkbox"] + label span {
      opacity: 0;
      color: #fff;
      line-height: 24px; }
  .lp-contact-form .contact__form input[type="checkbox"]:checked + label {
    background: #b79c66;
    border: 1px solid #b79c66; }
    .lp-contact-form .contact__form input[type="checkbox"]:checked + label span {
      opacity: 1; }
  .lp-contact-form .contact__form input[type="checkbox"].error + label {
    border-color: #ff0000; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-contact {
  position: relative;
  background-color: rgba(193, 198, 200, 0.1);
  z-index: 1;
  padding: 0 0 118px 0; }
  @media only screen and (max-width: 1079px) {
    .lp-contact {
      color: #000;
      padding: 0; } }
  @media only screen and (max-width: 1079px) {
    .lp-contact .contact__container {
      flex-direction: column-reverse; } }
  .lp-contact .contact__container .contact__info-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box; }
    @media only screen and (max-width: 1250px) {
      .lp-contact .contact__container .contact__info-box {
        padding: 0 20px; } }
    @media only screen and (max-width: 1079px) {
      .lp-contact .contact__container .contact__info-box {
        width: 100%;
        padding: 48px 20px;
        height: auto;
        background-repeat: no-repeat;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-color: rgba(0, 0, 0, 0.8);
        background-image: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.8)), color-stop(100%, rgba(0, 0, 0, 0.8))), url(/static/media/contact-section.1e74ff18.jpg);
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/static/media/contact-section.1e74ff18.jpg); } }
    .lp-contact .contact__container .contact__info-box::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% + 400px);
      height: calc(100% + 118px);
      display: block;
      background-repeat: no-repeat;
      z-index: -1;
      background-size: cover;
      background-position: center;
      background-color: rgba(0, 0, 0, 0.8);
      background-image: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.8)), color-stop(100%, rgba(0, 0, 0, 0.8))), url(/static/media/contact-section.1e74ff18.jpg);
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/static/media/contact-section.1e74ff18.jpg); }
      @media only screen and (max-width: 1079px) {
        .lp-contact .contact__container .contact__info-box::before {
          display: none; } }
    .lp-contact .contact__container .contact__info-box .contact__subtitle {
      font-family: "montserrat-medium", sans-serif;
      font-size: 18px;
      line-height: 22px;
      color: #fff;
      margin: 0;
      padding: 0 0 32px 0;
      max-width: 340px; }
      @media only screen and (max-width: 1079px) {
        .lp-contact .contact__container .contact__info-box .contact__subtitle {
          max-width: 100%; } }
    .lp-contact .contact__container .contact__info-box .contact__info {
      text-align: justify;
      display: block;
      font-family: "montserrat", sans-serif;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      box-sizing: border-box;
      max-width: 340px;
      padding: 12px 0 0 0;
      margin: 0; }
      @media only screen and (max-width: 1079px) {
        .lp-contact .contact__container .contact__info-box .contact__info {
          max-width: 100%; } }
    .lp-contact .contact__container .contact__info-box .contact__text {
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      margin: 0;
      padding: 0 0 20px 0; }
      .lp-contact .contact__container .contact__info-box .contact__text:first-of-type {
        padding: 0 0 32px 0; }
      .lp-contact .contact__container .contact__info-box .contact__text span {
        display: block; }
      .lp-contact .contact__container .contact__info-box .contact__text a {
        display: block;
        text-decoration: none;
        color: #fff; }
  .lp-contact .contact__container .contact__form-box {
    padding: 50px 0 0 50px;
    box-sizing: border-box; }
    @media only screen and (max-width: 1250px) {
      .lp-contact .contact__container .contact__form-box {
        padding: 50px 20px 0 20px; } }
    @media only screen and (max-width: 1079px) {
      .lp-contact .contact__container .contact__form-box {
        padding: 30px 20px 48px 20px; } }
    .lp-contact .contact__container .contact__form-box .contact__title {
      font-family: "montserrat-bold", sans-serif;
      font-size: 42px;
      line-height: 52px;
      padding: 0 0 47px 0;
      color: #35363a; }
      @media only screen and (max-width: 1079px) {
        .lp-contact .contact__container .contact__form-box .contact__title {
          padding: 0 0 20px 0;
          text-align: center; } }
      @media only screen and (max-width: 610px) {
        .lp-contact .contact__container .contact__form-box .contact__title {
          font-size: 28px;
          line-height: 34px; } }
    .lp-contact .contact__container .contact__form-box .contact__subtitle {
      font-family: "montserrat-medium", sans-serif;
      color: #b79c66;
      font-size: 28px;
      line-height: 34px;
      padding-bottom: 20px; }
      @media only screen and (max-width: 1079px) {
        .lp-contact .contact__container .contact__form-box .contact__subtitle {
          text-align: center;
          padding-bottom: 15px; } }
      @media only screen and (max-width: 610px) {
        .lp-contact .contact__container .contact__form-box .contact__subtitle {
          font-size: 16px;
          line-height: 21px; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-home-banner {
  width: 100%;
  max-width: 100vw;
  height: 62vw;
  max-height: 100vh;
  min-height: 800px;
  position: relative; }
  .lp-home-banner * {
    box-sizing: border-box; }
  @media only screen and (max-width: 1400px) {
    .lp-home-banner {
      height: 100vh; } }
  @media only screen and (max-width: 1400px) {
    .lp-home-banner {
      height: 100vh; } }
  @media only screen and (max-width: 610px) {
    .lp-home-banner {
      min-height: 500px;
      max-height: calc(100vh - 80px); } }
  .lp-home-banner .home-banner__container {
    height: 100%;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 1079px) {
      .lp-home-banner .home-banner__container {
        display: flex;
        flex-direction: column;
        justify-content: center; } }
    @media only screen and (max-width: 610px) {
      .lp-home-banner .home-banner__container {
        justify-content: flex-start; } }
    @media only screen and (max-width: 610px) {
      .lp-home-banner .home-banner__container .home-banner__left {
        margin-top: 165px; } }
    .lp-home-banner .home-banner__container .home-banner__left .home-banner__subtitle {
      text-transform: uppercase;
      font-family: "montserrat-bold", sans-serif;
      font-size: 32px;
      line-height: 39px;
      color: #fff;
      padding: 0 0 15px 20px; }
      @media only screen and (max-width: 1079px) {
        .lp-home-banner .home-banner__container .home-banner__left .home-banner__subtitle {
          padding: 0 20px 15px 20px;
          text-align: center; } }
      @media only screen and (max-width: 610px) {
        .lp-home-banner .home-banner__container .home-banner__left .home-banner__subtitle {
          font-size: 16px;
          line-height: 19px; } }
    .lp-home-banner .home-banner__container .home-banner__left .home-banner__title {
      font-family: "montserrat-bold", sans-serif;
      font-size: 66px;
      line-height: 81px;
      color: #fff;
      padding: 200px 0 54px 20px; }
      .lp-home-banner .home-banner__container .home-banner__left .home-banner__title span {
        font-size: 37px;
        white-space: nowrap; }
        @media only screen and (max-width: 1079px) {
          .lp-home-banner .home-banner__container .home-banner__left .home-banner__title span {
            white-space: normal; } }
      @media only screen and (max-width: 1079px) {
        .lp-home-banner .home-banner__container .home-banner__left .home-banner__title {
          padding: 0 20px 18px 20px;
          text-align: center; } }
      @media only screen and (max-width: 610px) {
        .lp-home-banner .home-banner__container .home-banner__left .home-banner__title {
          font-size: 38px;
          line-height: 47px; }
          .lp-home-banner .home-banner__container .home-banner__left .home-banner__title span {
            font-size: 24px; } }
    .lp-home-banner .home-banner__container .home-banner__left .home-banner__text {
      font-family: "montserrat-bold", sans-serif;
      font-size: 18px;
      line-height: 22px;
      color: #fff;
      padding: 0 0 18px 20px; }
      @media only screen and (max-width: 1079px) {
        .lp-home-banner .home-banner__container .home-banner__left .home-banner__text {
          padding: 0 20px 18px 20px;
          max-width: 288px;
          margin: 0 auto; } }
      @media only screen and (max-width: 610px) {
        .lp-home-banner .home-banner__container .home-banner__left .home-banner__text {
          font-size: 16px;
          line-height: 19px; } }
    .lp-home-banner .home-banner__container .home-banner__left .home-banner__button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 62px;
      line-height: 62px;
      width: 276px;
      text-transform: unset;
      margin: 0 0 20px 20px; }
      @media only screen and (max-width: 1079px) {
        .lp-home-banner .home-banner__container .home-banner__left .home-banner__button {
          height: 52px;
          line-height: 52px;
          width: 288px;
          margin: 0 auto 20px auto; } }
      .lp-home-banner .home-banner__container .home-banner__left .home-banner__button .home-banner__button-icon {
        font-size: 30px;
        margin-right: 10px;
        line-height: unset; }
      .lp-home-banner .home-banner__container .home-banner__left .home-banner__button .home-banner__button-text {
        font-family: "montserrat-medium", sans-serif;
        font-size: 16px; }
    .lp-home-banner .home-banner__container .home-banner__media {
      width: 100%;
      padding: 150px 0 25px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 60px;
      left: 0; }
      @media only screen and (max-width: 1079px) {
        .lp-home-banner .home-banner__container .home-banner__media {
          display: none; } }
      .lp-home-banner .home-banner__container .home-banner__media .contact__icon-box {
        color: #fff;
        text-decoration: none;
        text-align: center;
        font-size: 14px;
        font-family: "montsserat-bold", sans-serif;
        line-height: 18px; }
      .lp-home-banner .home-banner__container .home-banner__media .contact__icon {
        color: #fff;
        display: block;
        margin-top: 10px;
        font-size: 32px; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.banner__side-contact {
  width: 50px;
  height: auto;
  position: fixed;
  z-index: 1;
  right: 0;
  top: 30%; }
  @media only screen and (max-width: 610px) {
    .banner__side-contact {
      width: 35px;
      top: 100px;
      display: none; } }
  .banner__side-contact .side__link {
    display: block;
    background: #35363a;
    width: 50px;
    height: 50px;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 610px) {
      .banner__side-contact .side__link {
        width: 35px;
        height: 35px; } }
    .banner__side-contact .side__link.social {
      background: rgba(53, 54, 58, 0.7); }
    .banner__side-contact .side__link.meeting {
      margin-top: 25px;
      background-color: #b79c66; }
      @media only screen and (max-width: 610px) {
        .banner__side-contact .side__link.meeting {
          margin-top: 15px; } }
    .banner__side-contact .side__link.meeting-text {
      background-color: #b79c66;
      text-transform: uppercase;
      height: 226px;
      font-family: "montserrat-medium", sans-serif;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff; }
      @media only screen and (max-width: 610px) {
        .banner__side-contact .side__link.meeting-text {
          font-size: 14px;
          line-height: 18px;
          height: auto;
          padding: 10px 0; } }
      .banner__side-contact .side__link.meeting-text span {
        -webkit-writing-mode: tb-rl;
                writing-mode: tb-rl;
        -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
        white-space: nowrap; }
    .banner__side-contact .side__link .side__icon {
      color: #fff;
      font-size: 22px; }
      @media only screen and (max-width: 610px) {
        .banner__side-contact .side__link .side__icon {
          font-size: 16px; } }
    .banner__side-contact .side__link .af-icon-mail {
      font-size: 18px; }
      @media only screen and (max-width: 610px) {
        .banner__side-contact .side__link .af-icon-mail {
          font-size: 13px; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-dluga-section {
  width: 100%;
  max-width: 100vw;
  height: 59vw;
  max-height: 100vh;
  background: url(/static/media/dluga15-exterior-tram.8567ed83.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media only screen and (max-width: 1079px) {
    .lp-dluga-section {
      background: transparent;
      height: auto;
      max-height: auto; } }
  .lp-dluga-section .dluga-section__container {
    height: 100%; }
    @media only screen and (max-width: 1079px) {
      .lp-dluga-section .dluga-section__container {
        flex-direction: column; } }
    .lp-dluga-section .dluga-section__container .dluga-section__left {
      background-color: #f8f8f8;
      height: 415px;
      position: relative; }
      @media only screen and (max-width: 1079px) {
        .lp-dluga-section .dluga-section__container .dluga-section__left {
          width: 100%;
          height: auto;
          padding-bottom: 42px; } }
      .lp-dluga-section .dluga-section__container .dluga-section__left:before {
        content: "";
        display: block;
        min-width: 100%;
        width: calc(100% + 200px);
        height: 415px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f8f8f8;
        -webkit-transform: translateX(-99%);
                transform: translateX(-99%); }
        @media only screen and (max-width: 1079px) {
          .lp-dluga-section .dluga-section__container .dluga-section__left:before {
            display: none; } }
      .lp-dluga-section .dluga-section__container .dluga-section__left .dluga-section__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 42px;
        line-height: 52px;
        color: #363636;
        padding: 80px 20px 0 20px; }
        @media only screen and (max-width: 1079px) {
          .lp-dluga-section .dluga-section__container .dluga-section__left .dluga-section__title {
            padding: 42px 20px 0 20px; } }
        @media only screen and (max-width: 610px) {
          .lp-dluga-section .dluga-section__container .dluga-section__left .dluga-section__title {
            font-size: 28px;
            line-height: 34px; } }
      .lp-dluga-section .dluga-section__container .dluga-section__left .dluga-section__text {
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #363636;
        padding: 32px 20px 32px 20px;
        max-width: 380px; }
        @media only screen and (max-width: 1079px) {
          .lp-dluga-section .dluga-section__container .dluga-section__left .dluga-section__text {
            padding: 32px 20px 32px 20px; } }
      .lp-dluga-section .dluga-section__container .dluga-section__left .dluga-section__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 47px;
        line-height: 47px;
        width: 221px;
        text-transform: unset;
        margin: 0 20px 0 20px;
        font-family: "montserrat-medium", sans-serif;
        font-size: 16px; }
    .lp-dluga-section .dluga-section__container .dluga-section__right {
      display: none; }
      @media only screen and (max-width: 1079px) {
        .lp-dluga-section .dluga-section__container .dluga-section__right {
          display: block; } }
      .lp-dluga-section .dluga-section__container .dluga-section__right .dluga__background {
        width: 100%;
        height: auto;
        object-fit: contain; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-centrum {
  width: 100%;
  max-width: 100vw;
  height: 640px;
  position: relative; }
  @media only screen and (max-width: 1079px) {
    .lp-centrum {
      background: transparent;
      height: auto; } }
  .lp-centrum:after {
    content: "";
    display: block;
    width: 67%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    background: url(/static/media/space-background.5281ca26.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 25%; }
    @media only screen and (max-width: 1079px) {
      .lp-centrum:after {
        display: none; } }
  .lp-centrum .centrum__container {
    height: 100%; }
    @media only screen and (max-width: 1079px) {
      .lp-centrum .centrum__container {
        flex-direction: column; } }
    .lp-centrum .centrum__container .centrum__left {
      background-color: #141313;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media only screen and (max-width: 1079px) {
        .lp-centrum .centrum__container .centrum__left {
          height: auto;
          width: 100%;
          padding-bottom: 42px; } }
      .lp-centrum .centrum__container .centrum__left:before {
        content: "";
        display: block;
        min-width: 100%;
        width: calc(100% + 200px);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #141313;
        -webkit-transform: translateX(-99%);
                transform: translateX(-99%); }
        @media only screen and (max-width: 1079px) {
          .lp-centrum .centrum__container .centrum__left:before {
            display: none; } }
      .lp-centrum .centrum__container .centrum__left:after {
        content: "";
        display: block;
        width: 48px;
        height: 48px;
        position: absolute;
        top: 50%;
        right: -5px;
        background-color: #141313;
        -webkit-transform: rotate(45deg) translateY(-50%);
                transform: rotate(45deg) translateY(-50%); }
        @media only screen and (max-width: 1079px) {
          .lp-centrum .centrum__container .centrum__left:after {
            display: none; } }
      .lp-centrum .centrum__container .centrum__left .centrum__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 38px;
        line-height: 47px;
        color: #fff;
        padding: 0 20px; }
        @media only screen and (max-width: 1079px) {
          .lp-centrum .centrum__container .centrum__left .centrum__title {
            padding: 42px 20px 0 20px; } }
        @media only screen and (max-width: 610px) {
          .lp-centrum .centrum__container .centrum__left .centrum__title {
            font-size: 28px;
            line-height: 34px; } }
      .lp-centrum .centrum__container .centrum__left .centrum__text {
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #fff;
        padding: 32px 20px 32px 20px;
        max-width: 380px; }
        @media only screen and (max-width: 1079px) {
          .lp-centrum .centrum__container .centrum__left .centrum__text {
            padding: 32px 20px 32px 20px; } }
      .lp-centrum .centrum__container .centrum__left .centrum__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 47px;
        line-height: 47px;
        width: 221px;
        text-transform: unset;
        margin: 0 0 0 20px;
        font-family: "montserrat-medium", sans-serif;
        font-size: 16px; }
    .lp-centrum .centrum__container .centrum__right {
      display: none; }
      @media only screen and (max-width: 1079px) {
        .lp-centrum .centrum__container .centrum__right {
          display: block; } }
      .lp-centrum .centrum__container .centrum__right .centrum__background {
        width: 100%;
        height: auto;
        object-fit: contain; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-radius {
  width: 100%;
  max-width: 100vw;
  height: 56vw;
  max-height: 57vw;
  position: relative;
  overflow: hidden; }
  .lp-radius .radius__background-wrapper {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    transition: 0.5 ease-in-out; }
    .lp-radius .radius__background-wrapper .radius__background {
      width: calc(100% + 400px);
      height: calc(100% + 400px);
      object-fit: cover;
      object-position: center; }
  .lp-radius .radius__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 102%;
    height: 102%;
    object-fit: cover;
    transition: 0.2 ease-in-out; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-investment {
  width: 100%;
  max-width: 100vw;
  height: 1280px;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 1079px) {
    .lp-investment {
      background: transparent;
      height: auto; } }
  .lp-investment:after {
    content: "";
    display: block;
    width: 69%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: url(/static/media/investment-background.2a6c06cc.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    @media only screen and (max-width: 1079px) {
      .lp-investment:after {
        display: none; } }
  .lp-investment .investment__container {
    height: 100%; }
    .lp-investment .investment__container .investment__top {
      display: flex;
      justify-content: flex-end;
      height: 50%;
      z-index: 1; }
      @media only screen and (max-width: 1079px) {
        .lp-investment .investment__container .investment__top {
          height: auto;
          flex-direction: column-reverse; } }
      .lp-investment .investment__container .investment__top .investment__top-left {
        display: none;
        width: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-investment .investment__container .investment__top .investment__top-left {
            display: block; } }
        .lp-investment .investment__container .investment__top .investment__top-left .investment__background {
          width: 100%;
          height: auto;
          object-fit: contain; }
          @media only screen and (max-width: 610px) {
            .lp-investment .investment__container .investment__top .investment__top-left .investment__background {
              width: 100%;
              height: 100vw;
              object-fit: cover;
              object-position: center; } }
      .lp-investment .investment__container .investment__top .investment__top-right {
        background-color: #ffffff;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        @media only screen and (max-width: 1079px) {
          .lp-investment .investment__container .investment__top .investment__top-right {
            height: auto; } }
        .lp-investment .investment__container .investment__top .investment__top-right:before {
          content: "";
          display: block;
          min-width: 100%;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          -webkit-transform: translateX(99%);
                  transform: translateX(99%); }
          @media only screen and (max-width: 1079px) {
            .lp-investment .investment__container .investment__top .investment__top-right:before {
              display: none; } }
        .lp-investment .investment__container .investment__top .investment__top-right .investment__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px 0 115px; }
          @media only screen and (max-width: 1250px) {
            .lp-investment .investment__container .investment__top .investment__top-right .investment__title {
              padding: 42px 20px 0 20px; } }
          @media only screen and (max-width: 610px) {
            .lp-investment .investment__container .investment__top .investment__top-right .investment__title {
              font-size: 28px;
              line-height: 34px; } }
        .lp-investment .investment__container .investment__top .investment__top-right .investment__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #141313;
          padding: 32px 20px 32px 115px;
          max-width: 380px; }
          @media only screen and (max-width: 1250px) {
            .lp-investment .investment__container .investment__top .investment__top-right .investment__text {
              padding: 32px 20px 32px 20px; } }
        .lp-investment .investment__container .investment__top .investment__top-right .investment__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;
          text-transform: unset;
          margin: 0 0 0 115px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px; }
          @media only screen and (max-width: 1250px) {
            .lp-investment .investment__container .investment__top .investment__top-right .investment__button {
              margin: 0 0 0 20px; } }
          @media only screen and (max-width: 1079px) {
            .lp-investment .investment__container .investment__top .investment__top-right .investment__button {
              margin: 0 0 42px 20px; } }
    .lp-investment .investment__container .investment__bottom {
      height: 50%; }
      @media only screen and (max-width: 1079px) {
        .lp-investment .investment__container .investment__bottom {
          height: auto;
          flex-direction: column; } }
      .lp-investment .investment__container .investment__bottom .investment__bottom-left {
        display: flex;
        height: 100%;
        z-index: 1; }
        @media only screen and (max-width: 1079px) {
          .lp-investment .investment__container .investment__bottom .investment__bottom-left {
            flex-direction: column;
            height: auto; } }
        .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left {
          width: 100%;
          background-color: #141313;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative; }
          @media only screen and (max-width: 1079px) {
            .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left {
              width: 100%; } }
          .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left:before {
            content: "";
            display: block;
            min-width: 100%;
            width: calc(100% + 200px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #141313;
            -webkit-transform: translateX(-99%);
                    transform: translateX(-99%); }
            @media only screen and (max-width: 1079px) {
              .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left:before {
                display: none; } }
          .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left .investment__title {
            font-family: "montserrat-bold", sans-serif;
            font-size: 38px;
            line-height: 47px;
            color: #fff;
            padding: 0 20px; }
            @media only screen and (max-width: 1250px) {
              .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left .investment__title {
                padding: 42px 20px 0 20px; } }
            @media only screen and (max-width: 610px) {
              .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left .investment__title {
                font-size: 28px;
                line-height: 34px; } }
          .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left .investment__text {
            font-family: "montserrat", sans-serif;
            font-size: 17px;
            line-height: 30px;
            color: #fff;
            padding: 32px 20px;
            max-width: 380px; }
          .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left .investment__button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 47px;
            line-height: 47px;
            width: 221px;
            text-transform: unset;
            margin: 0 0 0 20px;
            font-family: "montserrat-medium", sans-serif;
            font-size: 16px; }
            @media only screen and (max-width: 1079px) {
              .lp-investment .investment__container .investment__bottom .investment__bottom-left .investment__left-bottom-left .investment__button {
                margin: 0 0 42px 20px; } }
      .lp-investment .investment__container .investment__bottom .investment__bottom-right {
        position: relative;
        height: 100%;
        z-index: -1; }
        @media only screen and (max-width: 1079px) {
          .lp-investment .investment__container .investment__bottom .investment__bottom-right {
            width: 100%;
            height: auto; } }
        .lp-investment .investment__container .investment__bottom .investment__bottom-right .investment__background {
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover; }
          @media only screen and (max-width: 1079px) {
            .lp-investment .investment__container .investment__bottom .investment__bottom-right .investment__background {
              width: 100%;
              height: auto;
              object-fit: contain;
              position: relative;
              top: unset;
              left: unset; } }
          @media only screen and (max-width: 610px) {
            .lp-investment .investment__container .investment__bottom .investment__bottom-right .investment__background {
              width: auto;
              height: 100vw;
              object-fit: cover; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-business {
  width: 100%;
  max-width: 100vw;
  height: 640px;
  position: relative;
  overflow-x: hidden; }
  @media only screen and (max-width: 1079px) {
    .lp-business {
      background: transparent;
      height: auto; } }
  .lp-business:after {
    content: "";
    display: block;
    width: 69%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: url(/static/media/business-background.b66069a9.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    @media only screen and (max-width: 1079px) {
      .lp-business:after {
        display: none; } }
  .lp-business .business__container {
    height: 100%; }
    .lp-business .business__container .business__top {
      display: flex;
      justify-content: flex-end;
      height: 100%; }
      @media only screen and (max-width: 1079px) {
        .lp-business .business__container .business__top {
          height: auto;
          flex-direction: column-reverse; } }
      .lp-business .business__container .business__top .business__top-left {
        display: none;
        width: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-business .business__container .business__top .business__top-left {
            display: block; } }
        .lp-business .business__container .business__top .business__top-left .business__background {
          width: 100%;
          height: auto;
          object-fit: contain; }
          @media only screen and (max-width: 610px) {
            .lp-business .business__container .business__top .business__top-left .business__background {
              width: 100%;
              height: 100vw;
              object-fit: cover;
              object-position: center; } }
      .lp-business .business__container .business__top .business__top-right {
        background-color: #ffffff;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        @media only screen and (max-width: 1079px) {
          .lp-business .business__container .business__top .business__top-right {
            height: auto; } }
        .lp-business .business__container .business__top .business__top-right:before {
          content: "";
          display: block;
          min-width: 100%;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          -webkit-transform: translateX(99%);
                  transform: translateX(99%); }
          @media only screen and (max-width: 1079px) {
            .lp-business .business__container .business__top .business__top-right:before {
              display: none; } }
        .lp-business .business__container .business__top .business__top-right .business__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px 0 115px; }
          @media only screen and (max-width: 1250px) {
            .lp-business .business__container .business__top .business__top-right .business__title {
              padding: 42px 20px 0 20px; } }
          @media only screen and (max-width: 610px) {
            .lp-business .business__container .business__top .business__top-right .business__title {
              font-size: 28px;
              line-height: 34px; } }
        .lp-business .business__container .business__top .business__top-right .business__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #141313;
          padding: 32px 20px 32px 115px;
          max-width: 380px; }
          @media only screen and (max-width: 1250px) {
            .lp-business .business__container .business__top .business__top-right .business__text {
              padding: 32px 20px 32px 20px; } }
        .lp-business .business__container .business__top .business__top-right .business__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;
          text-transform: unset;
          margin: 0 0 0 115px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px; }
          @media only screen and (max-width: 1250px) {
            .lp-business .business__container .business__top .business__top-right .business__button {
              margin: 0 0 0 20px; } }
          @media only screen and (max-width: 1079px) {
            .lp-business .business__container .business__top .business__top-right .business__button {
              margin: 0 0 42px 20px; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-time {
  width: 100%;
  max-width: 100vw;
  height: 1280px;
  position: relative;
  overflow-x: hidden; }
  @media only screen and (max-width: 1079px) {
    .lp-time {
      background: transparent;
      height: auto; } }
  .lp-time:after {
    content: "";
    display: block;
    width: 69%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: url(/static/media/time-background.35f4dd62.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    @media only screen and (max-width: 1079px) {
      .lp-time:after {
        display: none; } }
  .lp-time .time__container {
    height: 100%; }
    .lp-time .time__container .time__top {
      display: flex;
      justify-content: flex-end;
      height: 50%; }
      @media only screen and (max-width: 1079px) {
        .lp-time .time__container .time__top {
          height: auto;
          flex-direction: column; } }
      .lp-time .time__container .time__top .time__top-left {
        display: none;
        width: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-time .time__container .time__top .time__top-left {
            display: block; } }
        .lp-time .time__container .time__top .time__top-left .time__background {
          width: 100%;
          height: auto;
          object-fit: contain; }
          @media only screen and (max-width: 610px) {
            .lp-time .time__container .time__top .time__top-left .time__background {
              width: 100%;
              height: 100vw;
              object-fit: cover;
              object-position: center; } }
      .lp-time .time__container .time__top .time__top-right {
        background-color: #ffffff;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        @media only screen and (max-width: 1079px) {
          .lp-time .time__container .time__top .time__top-right {
            height: auto; } }
        .lp-time .time__container .time__top .time__top-right:before {
          content: "";
          display: block;
          min-width: 100%;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          -webkit-transform: translateX(99%);
                  transform: translateX(99%); }
          @media only screen and (max-width: 1079px) {
            .lp-time .time__container .time__top .time__top-right:before {
              display: none; } }
        .lp-time .time__container .time__top .time__top-right .time__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px 0 115px; }
          @media only screen and (max-width: 1250px) {
            .lp-time .time__container .time__top .time__top-right .time__title {
              padding: 42px 20px 0 20px; } }
          @media only screen and (max-width: 610px) {
            .lp-time .time__container .time__top .time__top-right .time__title {
              font-size: 28px;
              line-height: 34px; } }
        .lp-time .time__container .time__top .time__top-right .time__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #141313;
          padding: 32px 20px 32px 115px;
          max-width: 380px; }
          @media only screen and (max-width: 1250px) {
            .lp-time .time__container .time__top .time__top-right .time__text {
              padding: 32px 20px 32px 20px; } }
        .lp-time .time__container .time__top .time__top-right .time__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;
          text-transform: unset;
          margin: 0 0 0 115px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px; }
          @media only screen and (max-width: 1250px) {
            .lp-time .time__container .time__top .time__top-right .time__button {
              margin: 0 0 0 20px; } }
          @media only screen and (max-width: 1079px) {
            .lp-time .time__container .time__top .time__top-right .time__button {
              margin: 0 0 42px 20px; } }
    .lp-time .time__container .time__bottom {
      height: 50%; }
      @media only screen and (max-width: 1079px) {
        .lp-time .time__container .time__bottom {
          height: auto;
          flex-direction: column; } }
      .lp-time .time__container .time__bottom .time__bottom-left {
        position: relative;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-time .time__container .time__bottom .time__bottom-left {
            width: 100%;
            height: auto; } }
        .lp-time .time__container .time__bottom .time__bottom-left:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: url(/static/media/time1.5b62bcc7.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; }
          @media only screen and (max-width: 1079px) {
            .lp-time .time__container .time__bottom .time__bottom-left:before {
              display: none; } }
        .lp-time .time__container .time__bottom .time__bottom-left .time__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none; }
          @media only screen and (max-width: 1079px) {
            .lp-time .time__container .time__bottom .time__bottom-left .time__background {
              display: block; } }
          @media only screen and (max-width: 610px) {
            .lp-time .time__container .time__bottom .time__bottom-left .time__background {
              width: auto;
              height: 100vw;
              object-fit: cover; } }
      .lp-time .time__container .time__bottom .time__bottom-right {
        position: relative;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-time .time__container .time__bottom .time__bottom-right {
            width: 100%;
            height: auto; } }
        .lp-time .time__container .time__bottom .time__bottom-right:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url(/static/media/time2.d4af1fc8.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; }
          @media only screen and (max-width: 1079px) {
            .lp-time .time__container .time__bottom .time__bottom-right:before {
              display: none; } }
        .lp-time .time__container .time__bottom .time__bottom-right .time__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none; }
          @media only screen and (max-width: 1079px) {
            .lp-time .time__container .time__bottom .time__bottom-right .time__background {
              display: block; } }
          @media only screen and (max-width: 610px) {
            .lp-time .time__container .time__bottom .time__bottom-right .time__background {
              width: auto;
              height: 100vw;
              object-fit: cover; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-apartments-new {
  width: 100%;
  max-width: 100vw;
  height: 640px;
  position: relative;
  overflow-x: hidden; }
  @media only screen and (max-width: 1079px) {
    .lp-apartments-new {
      background: transparent;
      height: auto; } }
  .lp-apartments-new .apartments-new__container {
    height: 100%; }
    .lp-apartments-new .apartments-new__container .apartments-new__top {
      height: 100%; }
      @media only screen and (max-width: 1079px) {
        .lp-apartments-new .apartments-new__container .apartments-new__top {
          height: auto;
          flex-direction: column; } }
      .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        width: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left {
            flex-direction: column;
            height: auto; } }
        .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left {
          width: 50%;
          background-color: #efefef;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative; }
          @media only screen and (max-width: 1079px) {
            .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left {
              width: 100%; } }
          .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left:before {
            content: "";
            display: block;
            min-width: 100%;
            width: calc(100% + 200px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #efefef;
            -webkit-transform: translateX(-99%);
                    transform: translateX(-99%); }
            @media only screen and (max-width: 1079px) {
              .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left:before {
                display: none; } }
          .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left:after {
            content: "";
            display: block;
            width: 48px;
            height: 48px;
            position: absolute;
            top: 50%;
            right: -5px;
            background-color: #efefef;
            -webkit-transform: rotate(45deg) translateY(-50%);
                    transform: rotate(45deg) translateY(-50%);
            z-index: 1; }
            @media only screen and (max-width: 1079px) {
              .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left:after {
                top: unset;
                right: unset;
                bottom: -38px;
                left: 50%;
                -webkit-transform: rotate(45deg) translateX(-50%) translateY(0);
                        transform: rotate(45deg) translateX(-50%) translateY(0); } }
          .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left .apartments-new__title {
            font-family: "montserrat-bold", sans-serif;
            font-size: 38px;
            line-height: 47px;
            color: #141313;
            padding: 0 20px; }
            @media only screen and (max-width: 1250px) {
              .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left .apartments-new__title {
                padding: 42px 20px 0 20px; } }
            @media only screen and (max-width: 610px) {
              .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left .apartments-new__title {
                font-size: 28px;
                line-height: 34px; } }
          .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left .apartments-new__text {
            font-family: "montserrat", sans-serif;
            font-size: 17px;
            line-height: 30px;
            color: #141313;
            padding: 32px 20px;
            max-width: 380px; }
          .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left .apartments-new__button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 47px;
            line-height: 47px;
            width: 221px;
            text-transform: unset;
            margin: 0 0 0 20px;
            font-family: "montserrat-medium", sans-serif;
            font-size: 16px; }
            @media only screen and (max-width: 1079px) {
              .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-left .apartments-new__button {
                margin: 0 0 42px 20px; } }
        .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-right {
          display: flex;
          flex-wrap: wrap;
          background-color: #fff;
          position: relative;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 100%; }
          @media only screen and (max-width: 1079px) {
            .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-right {
              height: auto;
              width: 100%; } }
          @media only screen and (max-width: 610px) {
            .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-right {
              flex-direction: column;
              justify-content: center;
              padding-top: 30px; } }
          .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-right .apartments-new__box {
            box-sizing: border-box;
            width: 50%;
            height: 225px;
            padding: 0 20px; }
            @media only screen and (max-width: 610px) {
              .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-right .apartments-new__box {
                width: 80%; } }
            .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-right .apartments-new__box .apartments-new__box-title {
              text-align: center;
              color: #b79c66;
              font-family: "montserrat-bold", sans-serif;
              font-size: 72px;
              line-height: 88px;
              padding: 0 0 15px 0;
              margin: 0; }
            .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-left .apartments-new__left-top-right .apartments-new__box .apartments-new__box-text {
              line-height: 24px;
              font-size: 16px;
              color: #363636;
              text-align: center;
              padding: 0;
              margin: 0; }
      .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-right {
        position: relative;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-right {
            width: 100%;
            height: auto; } }
        .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-right:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url(/static/media/apartments-new1.4b21bc36.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; }
          @media only screen and (max-width: 1079px) {
            .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-right:before {
              display: none; } }
        .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-right .apartments-new__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none; }
          @media only screen and (max-width: 1079px) {
            .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-right .apartments-new__background {
              display: block; } }
          @media only screen and (max-width: 610px) {
            .lp-apartments-new .apartments-new__container .apartments-new__top .apartments-new__top-right .apartments-new__background {
              width: auto;
              height: 100vw;
              object-fit: cover; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-estate-new {
  width: 100%;
  max-width: 100vw;
  height: 50vw;
  max-height: 50vw; }
  .lp-estate-new .estate-new__bacground-wrapper {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden; }
    .lp-estate-new .estate-new__bacground-wrapper .estate-new__background {
      width: 100%;
      height: calc(100% + 200px);
      object-fit: cover;
      object-position: center; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-space {
  width: 100%;
  max-width: 100vw;
  height: 1280px;
  position: relative;
  overflow-x: hidden; }
  @media only screen and (max-width: 1079px) {
    .lp-space {
      background: transparent;
      height: auto; } }
  .lp-space .space__container {
    height: 100%; }
    .lp-space .space__container .space__top {
      height: 50%; }
      @media only screen and (max-width: 1079px) {
        .lp-space .space__container .space__top {
          height: auto;
          flex-direction: column-reverse; } }
      .lp-space .space__container .space__top .space__top-left {
        position: relative;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-space .space__container .space__top .space__top-left {
            width: 100%;
            height: auto; } }
        .lp-space .space__container .space__top .space__top-left:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: url(/static/media/space-new-background.a721ee71.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; }
          @media only screen and (max-width: 1079px) {
            .lp-space .space__container .space__top .space__top-left:before {
              display: none; } }
        .lp-space .space__container .space__top .space__top-left .space__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none; }
          @media only screen and (max-width: 1079px) {
            .lp-space .space__container .space__top .space__top-left .space__background {
              display: block; } }
          @media only screen and (max-width: 610px) {
            .lp-space .space__container .space__top .space__top-left .space__background {
              width: auto;
              height: 100vw;
              object-fit: cover; } }
      .lp-space .space__container .space__top .space__top-right {
        display: flex;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-space .space__container .space__top .space__top-right {
            flex-direction: column;
            height: auto; } }
        .lp-space .space__container .space__top .space__top-right .space__right-top-left {
          width: 50%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative; }
          @media only screen and (max-width: 1079px) {
            .lp-space .space__container .space__top .space__top-right .space__right-top-left {
              width: 100%; } }
          .lp-space .space__container .space__top .space__top-right .space__right-top-left .space__title {
            font-family: "montserrat-bold", sans-serif;
            font-size: 38px;
            line-height: 47px;
            color: #141313;
            padding: 0 20px 0 80px; }
            @media only screen and (max-width: 1250px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-left .space__title {
                padding: 42px 20px 0 20px; } }
            @media only screen and (max-width: 610px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-left .space__title {
                font-size: 28px;
                line-height: 34px; } }
          .lp-space .space__container .space__top .space__top-right .space__right-top-left .space__text {
            font-family: "montserrat", sans-serif;
            font-size: 17px;
            line-height: 30px;
            color: #141313;
            padding: 32px 20px 32px 80px;
            max-width: 380px; }
            @media only screen and (max-width: 1250px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-left .space__text {
                padding: 32px 20px; } }
          .lp-space .space__container .space__top .space__top-right .space__right-top-left .space__button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 47px;
            line-height: 47px;
            width: 221px;
            text-transform: unset;
            margin: 0 0 0 80px;
            font-family: "montserrat-medium", sans-serif;
            font-size: 16px; }
            @media only screen and (max-width: 1250px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-left .space__button {
                margin: 0 0 0 20px; } }
            @media only screen and (max-width: 1079px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-left .space__button {
                margin: 0 0 42px 20px; } }
        .lp-space .space__container .space__top .space__top-right .space__right-top-right {
          width: 50%;
          position: relative;
          text-align: center; }
          @media only screen and (max-width: 1079px) {
            .lp-space .space__container .space__top .space__top-right .space__right-top-right {
              width: 100%;
              height: auto; } }
          @media only screen and (max-width: 610px) {
            .lp-space .space__container .space__top .space__top-right .space__right-top-right {
              margin-bottom: 50px; } }
          .lp-space .space__container .space__top .space__top-right .space__right-top-right:before {
            content: "";
            display: block;
            width: 130%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(/static/media/K2-P4-1.28aeaf3b.jpg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center; }
            @media only screen and (max-width: 1400px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-right:before {
                width: 115%; } }
            @media only screen and (max-width: 1250px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-right:before {
                width: 100%; } }
            @media only screen and (max-width: 1079px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-right:before {
                display: none; } }
          .lp-space .space__container .space__top .space__top-right .space__right-top-right .space__background {
            width: 80%;
            height: auto;
            object-fit: contain;
            display: none;
            margin: 0 auto; }
            @media only screen and (max-width: 1079px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-right .space__background {
                display: block; } }
            @media only screen and (max-width: 610px) {
              .lp-space .space__container .space__top .space__top-right .space__right-top-right .space__background {
                width: 100%; } }
    .lp-space .space__container .space__bottom {
      height: 50%; }
      @media only screen and (max-width: 1079px) {
        .lp-space .space__container .space__bottom {
          height: auto;
          flex-direction: column-reverse; } }
      .lp-space .space__container .space__bottom .space__bottom-left {
        position: relative;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-space .space__container .space__bottom .space__bottom-left {
            width: 100%;
            height: auto; } }
        .lp-space .space__container .space__bottom .space__bottom-left:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: url(/static/media/space2.655e9e0d.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; }
          @media only screen and (max-width: 1079px) {
            .lp-space .space__container .space__bottom .space__bottom-left:before {
              display: none; } }
        .lp-space .space__container .space__bottom .space__bottom-left .space__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none; }
          @media only screen and (max-width: 1079px) {
            .lp-space .space__container .space__bottom .space__bottom-left .space__background {
              display: block; } }
          @media only screen and (max-width: 610px) {
            .lp-space .space__container .space__bottom .space__bottom-left .space__background {
              width: auto;
              height: 100vw;
              object-fit: cover; } }
      .lp-space .space__container .space__bottom .space__bottom-right {
        position: relative;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-space .space__container .space__bottom .space__bottom-right {
            width: 100%;
            height: auto; } }
        .lp-space .space__container .space__bottom .space__bottom-right:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url(/static/media/space3.b2ea5358.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; }
          @media only screen and (max-width: 1079px) {
            .lp-space .space__container .space__bottom .space__bottom-right:before {
              display: none; } }
        .lp-space .space__container .space__bottom .space__bottom-right .space__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none; }
          @media only screen and (max-width: 1079px) {
            .lp-space .space__container .space__bottom .space__bottom-right .space__background {
              display: block; } }
          @media only screen and (max-width: 610px) {
            .lp-space .space__container .space__bottom .space__bottom-right .space__background {
              width: auto;
              height: 100vw;
              object-fit: cover; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-region {
  width: 100%;
  max-width: 100vw;
  height: 1280px;
  position: relative;
  overflow: hidden; }
  .lp-region:before {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f8f8f8; }
    @media only screen and (max-width: 1079px) {
      .lp-region:before {
        display: none; } }
  @media only screen and (max-width: 1079px) {
    .lp-region {
      background: transparent;
      height: auto; } }
  .lp-region .region__container {
    height: 100%; }
    .lp-region .region__container .region__top {
      height: 50%;
      z-index: 0; }
      @media only screen and (max-width: 1079px) {
        .lp-region .region__container .region__top {
          height: auto;
          flex-direction: column; } }
      .lp-region .region__container .region__top .region__top-left {
        position: relative;
        height: 100%;
        background: #f8f8f8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative; }
        @media only screen and (max-width: 1079px) {
          .lp-region .region__container .region__top .region__top-left {
            height: auto; } }
        .lp-region .region__container .region__top .region__top-left .region__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px; }
          @media only screen and (max-width: 1250px) {
            .lp-region .region__container .region__top .region__top-left .region__title {
              padding: 42px 20px 0 20px; } }
          @media only screen and (max-width: 610px) {
            .lp-region .region__container .region__top .region__top-left .region__title {
              font-size: 28px;
              line-height: 34px; } }
        .lp-region .region__container .region__top .region__top-left .region__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #363636;
          padding: 32px 20px;
          max-width: 380px; }
          @media only screen and (max-width: 1250px) {
            .lp-region .region__container .region__top .region__top-left .region__text {
              padding: 32px 20px; } }
        .lp-region .region__container .region__top .region__top-left .region__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;
          text-transform: unset;
          margin: 0 0 0 20px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px; }
          @media only screen and (max-width: 1079px) {
            .lp-region .region__container .region__top .region__top-left .region__button {
              margin: 0 0 42px 20px; } }
      .lp-region .region__container .region__top .region__top-right {
        display: flex;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-region .region__container .region__top .region__top-right {
            flex-direction: column;
            height: auto; } }
        .lp-region .region__container .region__top .region__top-right .region__right-top-left {
          width: 50%;
          position: relative;
          text-align: center; }
          @media only screen and (max-width: 1079px) {
            .lp-region .region__container .region__top .region__top-right .region__right-top-left {
              width: 100%;
              height: auto; } }
          .lp-region .region__container .region__top .region__top-right .region__right-top-left .region__background {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block; }
            @media only screen and (max-width: 610px) {
              .lp-region .region__container .region__top .region__top-right .region__right-top-left .region__background {
                width: auto;
                height: 100vw;
                object-fit: cover; } }
        .lp-region .region__container .region__top .region__top-right .region__right-top-right {
          display: flex;
          flex-wrap: wrap;
          background: #f8f8f8;
          position: relative;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 100%; }
          @media only screen and (max-width: 1079px) {
            .lp-region .region__container .region__top .region__top-right .region__right-top-right {
              height: auto;
              width: 100%; } }
          @media only screen and (max-width: 610px) {
            .lp-region .region__container .region__top .region__top-right .region__right-top-right {
              flex-direction: column;
              justify-content: center; } }
          .lp-region .region__container .region__top .region__top-right .region__right-top-right .region__box {
            padding: 25px 50px;
            box-sizing: border-box;
            max-width: 50%;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content; }
            @media only screen and (max-width: 610px) {
              .lp-region .region__container .region__top .region__top-right .region__right-top-right .region__box {
                max-width: 80%; } }
            .lp-region .region__container .region__top .region__top-right .region__right-top-right .region__box .region__box-title {
              text-align: center;
              color: #b79c66;
              font-family: "montserrat-bold", sans-serif;
              font-size: 72px;
              line-height: 88px;
              padding: 0 0 15px 0;
              margin: 0; }
            .lp-region .region__container .region__top .region__top-right .region__right-top-right .region__box .region__box-text {
              line-height: 24px;
              font-size: 16px;
              color: #363636;
              text-align: center;
              padding: 0;
              margin: 0; }
    .lp-region .region__container .region__bottom {
      height: 50%; }
      @media only screen and (max-width: 1079px) {
        .lp-region .region__container .region__bottom {
          height: auto;
          flex-direction: column-reverse; } }
      .lp-region .region__container .region__bottom .region__bottom-left {
        position: relative;
        height: 100%;
        z-index: -1; }
        @media only screen and (max-width: 1079px) {
          .lp-region .region__container .region__bottom .region__bottom-left {
            width: 100%;
            height: auto; } }
        .lp-region .region__container .region__bottom .region__bottom-left .region__background-bigger {
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          object-fit: cover; }
          @media only screen and (max-width: 1079px) {
            .lp-region .region__container .region__bottom .region__bottom-left .region__background-bigger {
              width: 100%;
              height: auto;
              object-fit: contain;
              position: relative;
              top: unset;
              left: unset; } }
          @media only screen and (max-width: 610px) {
            .lp-region .region__container .region__bottom .region__bottom-left .region__background-bigger {
              width: auto;
              height: 100vw;
              object-fit: cover; } }
      .lp-region .region__container .region__bottom .region__bottom-right {
        position: relative;
        height: 100%;
        z-index: 0; }
        @media only screen and (max-width: 1079px) {
          .lp-region .region__container .region__bottom .region__bottom-right {
            width: 100%;
            height: auto; } }
        .lp-region .region__container .region__bottom .region__bottom-right:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url(/static/media/region2.e80ffebd.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: left; }
          @media only screen and (max-width: 1079px) {
            .lp-region .region__container .region__bottom .region__bottom-right:before {
              display: none; } }
        .lp-region .region__container .region__bottom .region__bottom-right .region__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none; }
          @media only screen and (max-width: 1079px) {
            .lp-region .region__container .region__bottom .region__bottom-right .region__background {
              display: block; } }
          @media only screen and (max-width: 610px) {
            .lp-region .region__container .region__bottom .region__bottom-right .region__background {
              width: auto;
              height: 100vw;
              object-fit: cover; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-journal {
  width: 100%;
  max-width: 100vw;
  height: 640px;
  position: relative;
  overflow-x: hidden; }
  @media only screen and (max-width: 1079px) {
    .lp-journal {
      background: transparent;
      height: auto; } }
  .lp-journal .journal__container {
    height: 100%; }
    .lp-journal .journal__container .journal__top {
      height: 100%; }
      @media only screen and (max-width: 1079px) {
        .lp-journal .journal__container .journal__top {
          height: auto;
          flex-direction: column; } }
      .lp-journal .journal__container .journal__top .journal__top-left {
        position: relative;
        height: 100%;
        background: #f8f8f8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative; }
        @media only screen and (max-width: 1079px) {
          .lp-journal .journal__container .journal__top .journal__top-left {
            height: auto; } }
        .lp-journal .journal__container .journal__top .journal__top-left:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: #f8f8f8;
          z-index: -1; }
          @media only screen and (max-width: 1079px) {
            .lp-journal .journal__container .journal__top .journal__top-left:before {
              display: none; } }
        .lp-journal .journal__container .journal__top .journal__top-left .journal__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px; }
          @media only screen and (max-width: 1250px) {
            .lp-journal .journal__container .journal__top .journal__top-left .journal__title {
              padding: 42px 20px 0 20px; } }
          @media only screen and (max-width: 610px) {
            .lp-journal .journal__container .journal__top .journal__top-left .journal__title {
              font-size: 28px;
              line-height: 34px; } }
        .lp-journal .journal__container .journal__top .journal__top-left .journal__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #363636;
          padding: 32px 20px;
          max-width: 380px; }
          @media only screen and (max-width: 1250px) {
            .lp-journal .journal__container .journal__top .journal__top-left .journal__text {
              padding: 32px 20px; } }
        .lp-journal .journal__container .journal__top .journal__top-left .journal__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;
          text-transform: unset;
          margin: 0 0 0 20px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px; }
          @media only screen and (max-width: 1079px) {
            .lp-journal .journal__container .journal__top .journal__top-left .journal__button {
              margin: 0 0 42px 20px; } }
      .lp-journal .journal__container .journal__top .journal__top-right {
        display: flex;
        height: 100%; }
        @media only screen and (max-width: 1079px) {
          .lp-journal .journal__container .journal__top .journal__top-right {
            flex-direction: column;
            height: auto; } }
        .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-left {
          position: relative;
          width: 50%;
          height: 100%; }
          @media only screen and (max-width: 1079px) {
            .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-left {
              height: auto;
              width: 100%; } }
          .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-left .journal__background {
            width: 100%;
            height: 50%;
            object-fit: cover;
            display: block; }
        .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right {
          width: 50%;
          position: relative;
          text-align: center; }
          @media only screen and (max-width: 1079px) {
            .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right {
              width: 100%;
              height: auto; } }
          .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right:before {
            content: "";
            display: block;
            width: calc(100% + 200px);
            height: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(/static/media/journal-section1.261bace6.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center; }
            @media only screen and (max-width: 1079px) {
              .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right:before {
                display: none; } }
          .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right:after {
            content: "";
            display: block;
            width: calc(100% + 200px);
            height: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            background: url(/static/media/journal-section.71a8c04b.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center; }
            @media only screen and (max-width: 1079px) {
              .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right:after {
                display: none; } }
          .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right .journal__background {
            width: 100%;
            height: auto;
            object-fit: cover;
            display: none; }
            @media only screen and (max-width: 1079px) {
              .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right .journal__background {
                display: block; } }
            @media only screen and (max-width: 610px) {
              .lp-journal .journal__container .journal__top .journal__top-right .journal__right-top-right .journal__background {
                width: auto;
                height: 100vw;
                object-fit: cover; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-footer {
  background: #000000;
  padding: 50px 0; }
  @media only screen and (max-width: 1079px) {
    .lp-footer .footer__container {
      flex-direction: column; } }
  .lp-footer .footer__container .footer__left {
    text-align: left; }
    @media only screen and (max-width: 1079px) {
      .lp-footer .footer__container .footer__left {
        text-align: center; } }
    .lp-footer .footer__container .footer__left .footer__logo-link {
      display: block;
      text-decoration: none;
      width: auto;
      height: auto; }
      .lp-footer .footer__container .footer__left .footer__logo-link .footer__logo {
        display: block;
        width: 180px;
        height: auto;
        object-fit: contain; }
      @media only screen and (max-width: 1250px) {
        .lp-footer .footer__container .footer__left .footer__logo-link {
          padding-left: 20px; } }
    .lp-footer .footer__container .footer__left .footer__text {
      color: #fff;
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      line-height: 23px;
      padding: 15px 0;
      max-width: 360px;
      text-align: justify; }
      @media only screen and (max-width: 1250px) {
        .lp-footer .footer__container .footer__left .footer__text {
          padding-left: 20px; } }
      @media only screen and (max-width: 1079px) {
        .lp-footer .footer__container .footer__left .footer__text {
          padding: 15px 20px;
          max-width: 100%; } }
  @media only screen and (max-width: 1079px) {
    .lp-footer .footer__container .footer__main {
      text-align: center; } }
  .lp-footer .footer__container .footer__main .footer__title {
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    font-family: "montserrat-medium", sans-serif; }
    @media only screen and (max-width: 1079px) {
      .lp-footer .footer__container .footer__main .footer__title {
        padding: 25px 20px 0 20px; } }
  .lp-footer .footer__container .footer__main .footer__investments {
    padding-top: 52px;
    display: flex; }
    @media only screen and (max-width: 1079px) {
      .lp-footer .footer__container .footer__main .footer__investments {
        justify-content: center;
        padding-top: 25px; } }
    @media only screen and (max-width: 1079px) {
      .lp-footer .footer__container .footer__main .footer__investments {
        flex-wrap: wrap;
        justify-content: center; } }
    .lp-footer .footer__container .footer__main .footer__investments .footer__investment-link {
      display: block;
      text-decoration: none;
      margin: 0 10px;
      width: 120px;
      height: 80px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media only screen and (max-width: 1079px) {
        .lp-footer .footer__container .footer__main .footer__investments .footer__investment-link {
          margin: 10px; }
          .lp-footer .footer__container .footer__main .footer__investments .footer__investment-link:first-of-type {
            margin-left: 10px; } }
      .lp-footer .footer__container .footer__main .footer__investments .footer__investment-link:first-of-type {
        margin-left: 0; }
      .lp-footer .footer__container .footer__main .footer__investments .footer__investment-link .investment-logo {
        width: 90%;
        height: auto;
        margin: 0 auto;
        object-fit: contain; }
  .lp-footer .footer__container .footer__right .footer__links {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media only screen and (max-width: 1250px) {
      .lp-footer .footer__container .footer__right .footer__links {
        padding-right: 20px; } }
    @media only screen and (max-width: 1079px) {
      .lp-footer .footer__container .footer__right .footer__links {
        justify-content: center;
        align-items: center;
        padding: 25px 20px 0 20px; } }
    .lp-footer .footer__container .footer__right .footer__links .link {
      display: block;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 25px;
      text-align: right;
      margin: 10px 0; }
      .lp-footer .footer__container .footer__right .footer__links .link .af-icon-arrow-right {
        color: #dfb452;
        font-size: 8px;
        margin-left: 10px; }

.lp-visualization-page {
  margin-top: 86px; }
  .lp-visualization-page #homerweb {
    display: block; }


.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-banner {
  width: 100%;
  max-width: 100vw;
  height: 62vw;
  max-height: 100vh; }
  @media only screen and (max-width: 1400px) {
    .lp-banner {
      width: 100vw;
      height: 100vh; } }
  @media only screen and (max-width: 610px) {
    .lp-banner {
      width: 100vw;
      min-height: 500px; } }
  .lp-banner .banner__container {
    height: 100%;
    display: flex;
    align-items: flex-end; }
    .lp-banner .banner__container .banner__media {
      width: 100%;
      padding: 25px 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .lp-banner .banner__container .banner__media .contact__icon-box {
        color: #fff;
        text-decoration: none; }
      .lp-banner .banner__container .banner__media .contact__icon {
        color: #fff;
        display: block; }
      .lp-banner .banner__container .banner__media .af-icon-scroll {
        font-size: 42px; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-about {
  padding: 50px 0; }
  .lp-about .about__container .about__title {
    text-align: center;
    color: #141313; }
    @media only screen and (max-width: 1079px) {
      .lp-about .about__container .about__title {
        padding: 0 20px; } }
  .lp-about .about__container .about__icon {
    height: 12px;
    width: 40px;
    object-fit: contain;
    display: block;
    margin: 25px auto;
    color: #dfb452; }
  .lp-about .about__container .about__text {
    font-family: "montserrat", sans-serif;
    font-size: 17px;
    line-height: 30px;
    text-align: center;
    color: #141313;
    max-width: 780px;
    margin: 0 auto; }
    @media only screen and (max-width: 1079px) {
      .lp-about .about__container .about__text {
        padding: 0 20px; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-area {
  padding: 50px 0; }
  .lp-area .area__container .area__title {
    text-align: center;
    color: #141313;
    text-transform: uppercase;
    font-size: 38px;
    line-height: 47px; }
    @media only screen and (max-width: 1079px) {
      .lp-area .area__container .area__title {
        padding: 0 20px; } }
    @media only screen and (max-width: 610px) {
      .lp-area .area__container .area__title {
        font-size: 28px;
        line-height: 34px; } }
  .lp-area .area__container .area__icon {
    height: 12px;
    width: 40px;
    object-fit: contain;
    display: block;
    margin: 25px auto;
    color: #dfb452; }
  .lp-area .area__container .area__main {
    max-width: 780px;
    margin: 0 auto; }
    .lp-area .area__container .area__main .area__text {
      font-family: "montserrat", sans-serif;
      font-size: 17px;
      line-height: 30px;
      color: #141313;
      padding: 10px;
      text-align: center; }
      @media only screen and (max-width: 1079px) {
        .lp-area .area__container .area__main .area__text {
          padding: 0 20px; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-quality {
  margin: 50px 0;
  height: 650px;
  background: url(/static/media/quality-background.cc12570d.jpg);
  background-repeat: no-repeat;
  background-size: 60%;
  overflow: hidden; }
  @media only screen and (max-width: 1079px) {
    .lp-quality {
      background-size: cover; } }
  .lp-quality .quality__container {
    justify-content: flex-end;
    height: 100%; }
    @media only screen and (max-width: 1079px) {
      .lp-quality .quality__container {
        flex-direction: column;
        justify-content: flex-end; } }
    .lp-quality .quality__container .quality__right {
      height: 55%;
      background: rgba(20, 20, 20, 0.7);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(20, 20, 20, 0.7)), color-stop(59%, rgba(0, 0, 0, 0.9)), color-stop(81%, #141414), color-stop(100%, #141414));
      background: linear-gradient(to right, rgba(20, 20, 20, 0.7) 0%, rgba(0, 0, 0, 0.9) 59%, #141414 81%, #141414 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#141414', endColorstr='#141414', GradientType=1 );
      position: relative; }
      @media only screen and (max-width: 1079px) {
        .lp-quality .quality__container .quality__right {
          height: auto;
          padding: 20px 0; } }
      .lp-quality .quality__container .quality__right::after {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background-color: #141414;
        right: -99%;
        top: 0; }
      .lp-quality .quality__container .quality__right .quality__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 38px;
        line-height: 47px;
        color: #ffffff;
        padding: 30px 40px 50px 40px; }
        @media only screen and (max-width: 1079px) {
          .lp-quality .quality__container .quality__right .quality__title {
            padding: 20px; } }
        @media only screen and (max-width: 610px) {
          .lp-quality .quality__container .quality__right .quality__title {
            padding: 20px 20px 10px 20px;
            font-size: 28px;
            line-height: 34px; } }
      .lp-quality .quality__container .quality__right .quality__text {
        display: flex;
        align-items: center;
        padding: 0 40px; }
        @media only screen and (max-width: 610px) {
          .lp-quality .quality__container .quality__right .quality__text {
            padding: 0 20px; } }
        .lp-quality .quality__container .quality__right .quality__text .af-icon-list-item {
          font-size: 8px;
          letter-spacing: 0.28em;
          color: #b79c66; }
        .lp-quality .quality__container .quality__right .quality__text .text {
          margin-left: 10px;
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #ffffff; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-detail {
  padding: 50px 0; }
  @media only screen and (max-width: 610px) {
    .lp-detail {
      padding: 0; } }
  .lp-detail .detail__container .detail__main {
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto; }
    @media only screen and (max-width: 1079px) {
      .lp-detail .detail__container .detail__main {
        flex-direction: column;
        max-width: 100%; } }
    @media only screen and (max-width: 1079px) {
      .lp-detail .detail__container .detail__main .detail__col {
        text-align: center; } }
    .lp-detail .detail__container .detail__main .detail__image {
      padding: 10px 15px;
      position: relative;
      max-width: 380px; }
      @media only screen and (max-width: 1079px) {
        .lp-detail .detail__container .detail__main .detail__image {
          margin: 0 auto; } }
      @media only screen and (max-width: 610px) {
        .lp-detail .detail__container .detail__main .detail__image {
          max-width: 260px;
          padding: 10px; } }
      .lp-detail .detail__container .detail__main .detail__image::before {
        border: 1px solid #b79c66;
        width: 380px;
        height: 488px;
        position: absolute;
        content: "";
        display: block;
        left: -25px;
        top: 40px;
        z-index: -1; }
        @media only screen and (max-width: 610px) {
          .lp-detail .detail__container .detail__main .detail__image::before {
            width: 260px;
            height: 334px;
            left: -10px;
            top: 30px; } }
      .lp-detail .detail__container .detail__main .detail__image img, .lp-detail .detail__container .detail__main .detail__image picture {
        display: block;
        width: 380px;
        height: 488px;
        object-fit: contain; }
        @media only screen and (max-width: 610px) {
          .lp-detail .detail__container .detail__main .detail__image img, .lp-detail .detail__container .detail__main .detail__image picture {
            width: 260px;
            height: 334px; } }
    .lp-detail .detail__container .detail__main .detail__description {
      padding: 10px 15px; }
      @media only screen and (max-width: 610px) {
        .lp-detail .detail__container .detail__main .detail__description {
          padding: 10px 20px; } }
      .lp-detail .detail__container .detail__main .detail__description .detail__title {
        color: #141313;
        padding-bottom: 45px;
        font-size: 38px;
        line-height: 47px; }
        @media only screen and (max-width: 1079px) {
          .lp-detail .detail__container .detail__main .detail__description .detail__title {
            text-align: center;
            padding: 30px 0; } }
        @media only screen and (max-width: 610px) {
          .lp-detail .detail__container .detail__main .detail__description .detail__title {
            font-size: 28px;
            line-height: 34px; } }
      .lp-detail .detail__container .detail__main .detail__description .detail__text {
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #141313; }
        @media only screen and (max-width: 1079px) {
          .lp-detail .detail__container .detail__main .detail__description .detail__text {
            text-align: center;
            max-width: 780px;
            margin: 0 auto; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-estate {
  padding: 50px 0;
  height: 700px;
  background: url(/static/media/estate.e5e4c00d.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  position: relative; }
  @media only screen and (max-width: 1079px) {
    .lp-estate {
      height: 450px; } }
  @media only screen and (max-width: 610px) {
    .lp-estate {
      height: 200px;
      margin: 0; } }
  .lp-estate .estate__button-left, .lp-estate .estate__button-right {
    position: absolute;
    width: 54px;
    height: 54px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: #b79c66;
    border: 1px solid #b79c66;
    box-sizing: border-box; }
    @media only screen and (max-width: 610px) {
      .lp-estate .estate__button-left, .lp-estate .estate__button-right {
        width: 34px;
        height: 34px; } }
    .lp-estate .estate__button-left span, .lp-estate .estate__button-right span {
      color: #fff;
      font-size: 14px; }
      @media only screen and (max-width: 610px) {
        .lp-estate .estate__button-left span, .lp-estate .estate__button-right span {
          font-size: 12px; } }
  .lp-estate .estate__button-left {
    left: 0px;
    top: 50%; }
  .lp-estate .estate__button-right {
    right: 0px;
    top: 50%; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-benefits {
  padding: 50px 0; }
  .lp-benefits .benefits__container .benefits__title {
    text-align: center;
    margin-bottom: 50px;
    box-sizing: border-box;
    font-size: 38px;
    line-height: 47px; }
    @media only screen and (max-width: 1079px) {
      .lp-benefits .benefits__container .benefits__title {
        padding: 0 20px; } }
    @media only screen and (max-width: 610px) {
      .lp-benefits .benefits__container .benefits__title {
        margin-bottom: 35px;
        font-size: 28px;
        line-height: 34px; } }
  .lp-benefits .benefits__container .benefits__main {
    align-items: center;
    flex-wrap: wrap;
    max-width: 1180px;
    margin: 0 auto;
    padding: 40px; }
    @media only screen and (max-width: 800px) {
      .lp-benefits .benefits__container .benefits__main {
        flex-direction: column;
        padding: 0; } }
    @media only screen and (max-width: 800px) {
      .lp-benefits .benefits__container .benefits__main .benefits__col {
        width: 100%; } }
    .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box {
      padding: 25px;
      display: flex;
      align-items: center;
      box-sizing: border-box; }
      @media only screen and (max-width: 610px) {
        .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box {
          padding: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center; } }
      .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__icon {
        width: 80px;
        height: 80px;
        background: #b79c66;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media only screen and (max-width: 610px) {
          .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__icon {
            width: 60px;
            height: 60px;
            margin-right: 0;
            margin-bottom: 10px; } }
        .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__icon img {
          display: block;
          height: 45px;
          width: auto;
          object-fit: contain; }
          @media only screen and (max-width: 610px) {
            .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__icon img {
              height: 35px; } }
      .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__details {
        max-width: calc(100% - 100px); }
        @media only screen and (max-width: 610px) {
          .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__details {
            max-width: 100%; } }
        .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__details .benefits__details-text {
          font-size: 12px;
          line-height: 15px;
          padding: 8px 0;
          text-align: left; }
          @media only screen and (max-width: 610px) {
            .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__details .benefits__details-text {
              text-align: center; } }
          .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__details .benefits__details-text .text {
            display: block;
            font-family: "montserrat", sans-serif;
            font-size: 17px;
            line-height: 25px; }
          .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__details .benefits__details-text .accent {
            display: block;
            font-family: "montserrat-bold", sans-serif;
            font-size: 26px;
            line-height: 32px;
            padding-bottom: 10px; }
            @media only screen and (max-width: 610px) {
              .lp-benefits .benefits__container .benefits__main .benefits__col .benefits__box .benefits__details .benefits__details-text .accent {
                font-size: 20px;
                line-height: 24px; } }
  .lp-benefits .benefits__container .benefits__button {
    width: 291px;
    height: 54px;
    background: #b79c66;
    border: 1px solid #b79c66;
    font-size: 16px;
    line-height: 54px;
    margin: 35px auto; }


.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-map-section {
  position: relative;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin: 30px 0;
  overflow: hidden; }
  @media only screen and (max-width: 610px) {
    .lp-map-section {
      margin: 0; } }
  .lp-map-section .map-section__box {
    width: 100%;
    height: 540px;
    margin-top: 50px; }
    @media only screen and (max-width: 1079px) {
      .lp-map-section .map-section__box {
        height: 400px; } }
  .lp-map-section .map-section__container {
    justify-content: flex-end; }
    .lp-map-section .map-section__container .map-section__title {
      text-align: center;
      color: #141313;
      padding: 0 20px 35px 20px; }
    @media only screen and (max-width: 1079px) {
      .lp-map-section .map-section__container .map-section__top {
        text-align: center;
        padding: 0 20px; } }
    .lp-map-section .map-section__container .map-section__top .map-section__select {
      height: 47px;
      line-height: 47px;
      width: 221px;
      text-transform: unset;
      font-family: "montserrat-medium", sans-serif;
      font-size: 16px;
      background-color: #b79c66;
      border: 1px solid #b79c66;
      margin-top: 35px;
      cursor: pointer; }
      @media only screen and (max-width: 1079px) {
        .lp-map-section .map-section__container .map-section__top .map-section__select {
          margin: 35px auto 0 auto; } }
    .lp-map-section .map-section__container .map-section__top .map-section__list {
      margin: 0;
      padding: 0;
      list-style: none;
      flex-wrap: wrap;
      align-items: center; }
      .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item {
        font-size: 16px;
        line-height: 22px;
        color: #707070;
        font-family: "montserrat", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px 0;
        cursor: pointer; }
        .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item.active {
          color: #000000; }
        @media only screen and (max-width: 610px) {
          .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item {
            font-size: 14px;
            line-height: 18px; } }
        .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item .icon-box {
          width: 34px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px; }
          .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item .icon-box span {
            display: block;
            font-size: 22px;
            line-height: 22px;
            color: #707070; }
            .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item .icon-box span.active {
              color: #b79c66; }
          .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item .icon-box .af-icon-education {
            font-size: 12px;
            line-height: 12px; }
          .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item .icon-box .af-icon-healt-serive,
          .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item .icon-box .af-icon-culture,
          .lp-map-section .map-section__container .map-section__top .map-section__list .map-section__list-item .icon-box .af-icon-bank {
            font-size: 17px;
            line-height: 17px; }

.google-map {
  width: 100%;
  height: 100%; }

.map-marker .map-marker-circle {
  width: 34px;
  height: 34px;
  background: #2f2a31;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer; }
  .map-marker .map-marker-circle span {
    display: block;
    font-size: 17px;
    color: #cfae77; }
    .map-marker .map-marker-circle span::before {
      line-height: inherit; }
  .map-marker .map-marker-circle .map-pin-name {
    background: #141414;
    color: #fff;
    position: absolute;
    left: 40px;
    -webkit-transform: unset;
            transform: unset;
    margin: 0;
    padding: 5px 8px;
    font-size: 12px;
    line-height: 14px;
    width: 100px; }

.main-pin {
  display: block;
  position: relative;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  background: #fff;
  border-bottom: 4px solid #b79c66;
  width: 162px;
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75); }
  .main-pin:after {
    content: "";
    display: block;
    width: 28px;
    height: 25px;
    position: absolute;
    bottom: -25px;
    left: 10px;
    background: url(/static/media/map-pin-element.30c4f305.svg);
    background-size: contain;
    background-repeat: no-repeat; }
  .main-pin img {
    width: 117.4px;
    height: 33.35px;
    object-fit: contain; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-history {
  padding: 50px 0; }
  .lp-history .history__container .history__title {
    text-align: center;
    color: #141313; }
    @media only screen and (max-width: 1079px) {
      .lp-history .history__container .history__title {
        padding: 0 20px; } }
  .lp-history .history__container .history__icon {
    height: 12px;
    width: 40px;
    object-fit: contain;
    display: block;
    margin: 25px auto;
    color: #dfb452; }
  .lp-history .history__container .history__main {
    max-width: 780px;
    margin: 0 auto;
    align-items: flex-start; }
    @media only screen and (max-width: 1079px) {
      .lp-history .history__container .history__main {
        flex-direction: column; } }
    .lp-history .history__container .history__main .history__text {
      font-family: "montserrat", sans-serif;
      font-size: 17px;
      line-height: 30px;
      color: #141313;
      padding: 10px;
      text-align: justify; }
      @media only screen and (max-width: 1079px) {
        .lp-history .history__container .history__main .history__text {
          padding: 0 20px; } }
      .lp-history .history__container .history__main .history__text .history__accent {
        font-family: "merriwather", serif;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        color: #b79c66;
        margin-right: 20px; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-localization-view {
  box-sizing: border-box;
  margin: 30px 0;
  height: 665px;
  background: url(/static/media/map-background.61b5f296.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px 0;
  background-position-x: right;
  position: relative; }
  @media only screen and (max-width: 1079px) {
    .lp-localization-view {
      height: 450px; } }
  @media only screen and (max-width: 610px) {
    .lp-localization-view {
      height: 200px;
      margin: 0 0 50px 0; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-localization-area {
  margin: 50px 0;
  height: 600px;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 1079px) {
    .lp-localization-area {
      height: auto; } }
  .lp-localization-area::after {
    content: "";
    display: block;
    background: url(/static/media/dluga15-exterior-ujecie6-01.cd506874.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 690px;
    height: 500px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media only screen and (min-width: 1460px) {
      .lp-localization-area::after {
        width: 50%; } }
    @media only screen and (max-width: 1350px) {
      .lp-localization-area::after {
        width: 50%; } }
    @media only screen and (max-width: 1079px) {
      .lp-localization-area::after {
        display: none; } }
  .lp-localization-area .localization-area__container {
    justify-content: flex-end;
    height: 100%; }
    @media only screen and (max-width: 1079px) {
      .lp-localization-area .localization-area__container {
        flex-direction: column-reverse;
        justify-content: flex-end; } }
    .lp-localization-area .localization-area__container .localization-area__left .localization-area__image {
      width: 100%;
      height: auto;
      object-fit: contain;
      display: none; }
      @media only screen and (max-width: 1079px) {
        .lp-localization-area .localization-area__container .localization-area__left .localization-area__image {
          display: block; } }
    .lp-localization-area .localization-area__container .localization-area__right {
      height: 100%;
      background: #ededeb;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media only screen and (max-width: 1079px) {
        .lp-localization-area .localization-area__container .localization-area__right {
          height: auto;
          padding: 20px 0; } }
      .lp-localization-area .localization-area__container .localization-area__right::before {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 120px;
        background-color: #ededeb;
        left: -100px;
        top: 0; }
      .lp-localization-area .localization-area__container .localization-area__right::after {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background-color: #ededeb;
        right: -99%;
        top: 0; }
      .lp-localization-area .localization-area__container .localization-area__right .localization-area__title {
        color: #141313;
        padding: 0 40px 50px 40px; }
        @media only screen and (max-width: 1079px) {
          .lp-localization-area .localization-area__container .localization-area__right .localization-area__title {
            padding: 20px; } }
        @media only screen and (max-width: 610px) {
          .lp-localization-area .localization-area__container .localization-area__right .localization-area__title {
            padding: 20px 20px 10px 20px; } }
      .lp-localization-area .localization-area__container .localization-area__right .localization-area__text {
        padding: 0 40px;
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #141313; }
        @media only screen and (max-width: 1079px) {
          .lp-localization-area .localization-area__container .localization-area__right .localization-area__text {
            padding: 0 20px; } }

@charset "UTF-8";
.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-localization-info {
  padding: 50px 0;
  position: relative; }
  @media only screen and (max-width: 610px) {
    .lp-localization-info {
      padding: 0 0 50px 0; } }
  .lp-localization-info .localization-info__container {
    flex-wrap: wrap; }
    .lp-localization-info .localization-info__container .localization-info__left .localization-info__title {
      color: #141313;
      padding: 0 0 50px 50px; }
      @media only screen and (max-width: 610px) {
        .lp-localization-info .localization-info__container .localization-info__left .localization-info__title {
          padding: 0 20px 35px 20px; } }
    .lp-localization-info .localization-info__container .localization-info__left .localization-info__text {
      font-family: "montserrat", sans-serif;
      font-size: 17px;
      line-height: 30px;
      color: #141313;
      padding: 0 0 50px 50px; }
      @media only screen and (max-width: 1079px) {
        .lp-localization-info .localization-info__container .localization-info__left .localization-info__text {
          padding: 0 50px 70px 50px; } }
      @media only screen and (max-width: 610px) {
        .lp-localization-info .localization-info__container .localization-info__left .localization-info__text {
          padding: 0 20px 70px 20px; } }
    .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent {
      /* Slider */
      /* Arrows */
      /* Slider */ }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item {
        display: block;
        text-decoration: none; }
        .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item .slider-item__image {
          width: 408px;
          height: 408px;
          position: relative;
          overflow: hidden; }
          @media only screen and (max-width: 1079px) {
            .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item .slider-item__image {
              width: 300px;
              height: 300px; } }
          @media only screen and (max-width: 610px) {
            .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item .slider-item__image {
              width: 95%;
              max-width: 300px;
              max-height: 300px; } }
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item .slider-item__image img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-loading .slick-list {
        background: #fff center center no-repeat; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: -70px;
        display: block;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        outline: none;
        background: transparent;
        border: 0;
        z-index: 1; }
        @media only screen and (max-width: 1079px) {
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev,
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
            top: -40px; } }
        .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev::before,
        .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next::before {
          position: relative;
          top: -3px; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:hover,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:focus,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:hover,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:focus {
        color: #000;
        outline: none; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:hover:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:focus:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:hover:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:focus:before {
        opacity: 1; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev.slick-disabled:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next.slick-disabled:before {
        opacity: 0.5; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:before {
        font-size: 30px;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev {
        right: 200px;
        left: auto; }
        @media only screen and (max-width: 740px) {
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev {
            left: 50px;
            right: auto; } }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-prev {
        right: 15px;
        left: auto; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev {
        display: block;
        content: "";
        background-image: url(/static/media/thin-arrow-left.feecfefe.svg);
        background-position: center;
        background-size: contain;
        width: 80px;
        height: 20px;
        background-repeat: no-repeat; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-prev:before {
        content: "\2192"; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
        right: 100px; }
        @media only screen and (max-width: 740px) {
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
            right: 50px; } }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-next {
        right: auto;
        left: 15px; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
        display: block;
        content: "";
        background-image: url(/static/media/thin-arrow-right.2b4bef1b.svg);
        background-position: center;
        background-size: contain;
        width: 80px;
        height: 20px;
        background-repeat: no-repeat; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-next:before {
        content: "\2190"; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-list:focus {
        outline: none; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-list.dragging {
        cursor: pointer;
        cursor: hand; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slider .slick-track,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-track:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-track:after {
        display: table;
        content: ""; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-track:after {
        clear: both; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-loading .slick-track {
        visibility: hidden; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-slide {
        float: right; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slide img {
        display: block; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slide.slick-loading img {
        display: none; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slide.dragging img {
        pointer-events: none; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-initialized .slick-slide {
        display: block; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-loading .slick-slide {
        visibility: hidden; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-arrow.slick-hidden {
        display: none; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-gallery {
  padding: 100px 0; }
  @media only screen and (max-width: 610px) {
    .lp-gallery {
      padding: 150px 0 50px 0; } }
  .lp-gallery .gallery_container {
    flex-wrap: wrap;
    justify-content: center; }
    .lp-gallery .gallery_container .gallery__title {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #0f0e0f; }
      @media only screen and (max-width: 1079px) {
        .lp-gallery .gallery_container .gallery__title {
          padding: 0 20px; } }
    .lp-gallery .gallery_container .gallery__icon {
      height: 12px;
      width: 40px;
      object-fit: contain;
      display: block;
      margin: 25px auto;
      color: #dfb452; }
    .lp-gallery .gallery_container .gallery__text {
      font-family: "montserrat-light", sans-serif;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      max-width: 780px;
      margin: 0 auto;
      padding-bottom: 50px; }
      @media only screen and (max-width: 1079px) {
        .lp-gallery .gallery_container .gallery__text {
          padding: 0 20px 50px 20px; } }
    .lp-gallery .gallery_container .gallery__button {
      width: 210px;
      height: 54px;
      background: #b79c66;
      border: 1px solid #b79c66;
      margin: 60px auto;
      text-transform: unset;
      line-height: 54px; }
      .lp-gallery .gallery_container .gallery__button span {
        color: #fff; }
    .lp-gallery .gallery_container .gallery__main {
      flex-wrap: wrap; }
      .lp-gallery .gallery_container .gallery__main .gallery-image-box {
        padding: 7px;
        box-sizing: border-box; }
        .lp-gallery .gallery_container .gallery__main .gallery-image-box .gallery-image {
          margin: 0 auto;
          display: block;
          width: 100%;
          height: auto;
          object-fit: cover;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          transition: all 0.3s ease 0s; }
          .lp-gallery .gallery_container .gallery__main .gallery-image-box .gallery-image:hover {
            box-shadow: 0 4px 15px rgba(5, 5, 6, 0.1);
            -webkit-box-shadow: 0 3px 20px rgba(5, 5, 6, 0.1);
            cursor: pointer; }
  .lp-gallery .image__container-outside {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 901; }
    .lp-gallery .image__container-outside .image__container {
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 90%;
      max-width: 90vw;
      height: 100%;
      max-height: 51vw;
      z-index: 1000;
      background-color: transparent; }
      @media only screen and (max-width: 1079px) {
        .lp-gallery .image__container-outside .image__container {
          width: 100%;
          max-width: 100vw;
          height: 100%;
          max-height: 56vw; } }
      .lp-gallery .image__container-outside .image__container .image__container-inside {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: center; }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button {
          z-index: 100;
          position: absolute;
          width: 54px;
          height: 54px;
          background: #b79c66;
          border: 1px solid #b79c66;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          @media only screen and (max-width: 610px) {
            .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button {
              width: 30px;
              height: 30px;
              font-size: 16px; } }
          .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button span {
            display: block;
            color: #fff; }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-left {
          bottom: 20px;
          left: calc(50% - 60px); }
          @media only screen and (max-width: 610px) {
            .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-left {
              left: calc(50% - 35px); } }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-right {
          bottom: 20px;
          right: calc(50% - 60px);
          background: #ffffff; }
          .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-right span {
            color: #b79c66; }
          @media only screen and (max-width: 610px) {
            .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-right {
              right: calc(50% - 35px); } }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-close {
          top: 0;
          right: 0; }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-image {
          width: auto;
          height: 100%;
          object-fit: contain; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-gallery {
  padding: 100px 0; }
  @media only screen and (max-width: 610px) {
    .lp-gallery {
      padding: 150px 0 50px 0; } }
  .lp-gallery .gallery_container {
    flex-wrap: wrap;
    justify-content: center; }
    .lp-gallery .gallery_container .gallery__title {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #0f0e0f; }
      @media only screen and (max-width: 1079px) {
        .lp-gallery .gallery_container .gallery__title {
          padding: 0 20px; } }
    .lp-gallery .gallery_container .gallery__icon {
      height: 12px;
      width: 40px;
      object-fit: contain;
      display: block;
      margin: 25px auto;
      color: #dfb452; }
    .lp-gallery .gallery_container .gallery__text {
      font-family: "montserrat-light", sans-serif;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      max-width: 780px;
      margin: 0 auto;
      padding-bottom: 50px;
      width: 100%; }
      @media only screen and (max-width: 1079px) {
        .lp-gallery .gallery_container .gallery__text {
          padding: 25px 20px 25px; } }
    .lp-gallery .gallery_container .gallery__button {
      width: 210px;
      height: 54px;
      background: #b79c66;
      border: 1px solid #b79c66;
      margin: 60px auto;
      text-transform: unset;
      line-height: 54px; }
      .lp-gallery .gallery_container .gallery__button span {
        color: #fff; }
    .lp-gallery .gallery_container .gallery__main {
      flex-wrap: wrap; }
      .lp-gallery .gallery_container .gallery__main .gallery-image-box {
        padding: 7px;
        box-sizing: border-box; }
        .lp-gallery .gallery_container .gallery__main .gallery-image-box .gallery-image {
          margin: 0 auto;
          display: block;
          width: 100%;
          height: auto;
          object-fit: cover;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          transition: all 0.3s ease 0s; }
          .lp-gallery .gallery_container .gallery__main .gallery-image-box .gallery-image:hover {
            box-shadow: 0 4px 15px rgba(5, 5, 6, 0.1);
            -webkit-box-shadow: 0 3px 20px rgba(5, 5, 6, 0.1);
            cursor: pointer; }
  .lp-gallery .image__container-outside {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 901; }
    .lp-gallery .image__container-outside .image__container {
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 90%;
      max-width: 90vw;
      height: 100%;
      max-height: 51vw;
      z-index: 1000;
      background-color: transparent; }
      @media only screen and (max-width: 1079px) {
        .lp-gallery .image__container-outside .image__container {
          width: 100%;
          max-width: 100vw;
          height: 100%;
          max-height: 56vw; } }
      .lp-gallery .image__container-outside .image__container .image__container-inside {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: center; }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button {
          z-index: 100;
          position: absolute;
          width: 54px;
          height: 54px;
          background: #b79c66;
          border: 1px solid #b79c66;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          @media only screen and (max-width: 610px) {
            .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button {
              width: 30px;
              height: 30px;
              font-size: 16px; } }
          .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button span {
            display: block;
            color: #fff; }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-left {
          bottom: 20px;
          left: calc(50% - 60px); }
          @media only screen and (max-width: 610px) {
            .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-left {
              left: calc(50% - 35px); } }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-right {
          bottom: 20px;
          right: calc(50% - 60px);
          background: #ffffff; }
          .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-right span {
            color: #b79c66; }
          @media only screen and (max-width: 610px) {
            .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-right {
              right: calc(50% - 35px); } }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-button-close {
          top: 0;
          right: 0; }
        .lp-gallery .image__container-outside .image__container .image__container-inside .gallery-image {
          width: auto;
          height: 100%;
          object-fit: contain; }


.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-buildings-banner {
  width: 100%;
  height: auto;
  margin-top: 85px;
  max-width: 100vw; }
  @media only screen and (max-width: 610px) {
    .lp-buildings-banner {
      margin-top: 70px; } }
  .lp-buildings-banner .buildings-banner__wrapper {
    width: 100%;
    max-width: 100%;
    height: 52vw;
    overflow: hidden;
    position: relative; }
    @media only screen and (max-width: 1079px) {
      .lp-buildings-banner .buildings-banner__wrapper {
        height: 62vw; } }
    .lp-buildings-banner .buildings-banner__wrapper .buildings-banner__image {
      width: 150vw;
      max-width: 150vw;
      height: 67vw;
      object-fit: contain;
      z-index: 0;
      position: absolute;
      top: -140px;
      right: 0; }
      @media only screen and (max-width: 1079px) {
        .lp-buildings-banner .buildings-banner__wrapper .buildings-banner__image {
          top: 0; } }
    .lp-buildings-banner .buildings-banner__wrapper .buildings-banner__mapper {
      position: absolute;
      right: 0;
      top: -140px;
      width: 150vw;
      max-width: 150vw;
      height: 67vw;
      z-index: 1; }
      @media only screen and (max-width: 1079px) {
        .lp-buildings-banner .buildings-banner__wrapper .buildings-banner__mapper {
          top: 0; } }
  .lp-buildings-banner .buildings-banner__container {
    height: 100%;
    display: flex;
    align-items: flex-end; }
    .lp-buildings-banner .buildings-banner__container .buildings-banner__media {
      width: 100%;
      padding: 25px 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .lp-buildings-banner .buildings-banner__container .buildings-banner__media .contact__icon-box {
        color: #fff;
        text-decoration: none; }
      .lp-buildings-banner .buildings-banner__container .buildings-banner__media .contact__icon {
        color: #fff;
        display: block; }
      .lp-buildings-banner .buildings-banner__container .buildings-banner__media .af-icon-scroll {
        font-size: 42px; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.buildings-banner__label {
  width: 220px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.16);
  position: absolute;
  z-index: 3;
  padding: 20px;
  box-sizing: border-box; }
  @media only screen and (max-width: 1079px) {
    .buildings-banner__label {
      display: none; } }
  .buildings-banner__label .buildings-banner__label-title {
    font-family: "montserrat-bold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    text-align: center;
    padding: 0 0 6px 0;
    color: #35363a;
    text-transform: uppercase;
    text-align: center; }
  .buildings-banner__label .buildings-banner__label-text {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    text-align: center;
    padding: 0;
    color: #35363a; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.building__label {
  width: 206px;
  background-color: #35363a;
  position: absolute;
  z-index: 3;
  padding: 22px 32px;
  box-sizing: border-box;
  left: 62%;
  top: calc(20% + 70px); }
  @media only screen and (max-width: 1600px) {
    .building__label {
      top: calc(16% + 70px); } }
  @media only screen and (max-width: 1450px) {
    .building__label {
      top: calc(10% + 70px); } }
  @media only screen and (max-width: 1250px) {
    .building__label {
      top: calc(6% + 70px);
      width: 160px;
      padding: 15px 20px; } }
  @media only screen and (max-width: 1079px) {
    .building__label {
      width: 66px;
      padding: 8px 11px;
      left: 64%;
      top: calc(30% + 28px); } }
  @media only screen and (max-width: 850px) {
    .building__label {
      top: calc(25% + 28px); } }
  @media only screen and (max-width: 610px) {
    .building__label {
      left: 60%;
      top: calc(15% + 28px); } }
  .building__label.t {
    top: 20%;
    left: 33%; }
    @media only screen and (max-width: 1600px) {
      .building__label.t {
        top: 16%; } }
    @media only screen and (max-width: 1450px) {
      .building__label.t {
        top: 10%; } }
    @media only screen and (max-width: 1250px) {
      .building__label.t {
        top: 6%;
        width: 160px;
        padding: 15px 20px; } }
    @media only screen and (max-width: 1079px) {
      .building__label.t {
        width: 66px;
        padding: 8px 11px;
        left: 36%;
        top: 30%; } }
    @media only screen and (max-width: 850px) {
      .building__label.t {
        top: 25%; } }
    @media only screen and (max-width: 610px) {
      .building__label.t {
        left: 28%;
        top: 15%; } }
  .building__label .building__label-text {
    font-family: "montserrat", sans-serif;
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    text-align: center;
    padding: 0;
    color: #fff; }
    .building__label .building__label-text span {
      font-family: "montserrat-bold", sans-serif; }
    @media only screen and (max-width: 1250px) {
      .building__label .building__label-text {
        font-size: 16px;
        line-height: 20px; } }
    @media only screen and (max-width: 1079px) {
      .building__label .building__label-text {
        font-size: 8px;
        line-height: 12px; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-floor-view {
  padding: 50px 0 100px 0;
  height: 780px;
  box-sizing: border-box; }
  @media only screen and (max-width: 1079px) {
    .lp-floor-view {
      height: auto;
      min-height: 720px;
      padding: 50px 0 0 0; } }
  @media only screen and (max-width: 610px) {
    .lp-floor-view {
      padding: 25px 0 0 0; } }
  .lp-floor-view .floor-view__container {
    height: 100%; }
    .lp-floor-view .floor-view__container .floor-view__title {
      line-height: 40px;
      text-align: center;
      color: #0f0e0f;
      padding: 0 0 35px 0;
      font-family: "montserrat-medium", sans-serif; }
      @media only screen and (max-width: 1079px) {
        .lp-floor-view .floor-view__container .floor-view__title {
          padding: 0 20px 20px 20px; } }
      @media only screen and (max-width: 610px) {
        .lp-floor-view .floor-view__container .floor-view__title {
          font-size: 28px;
          line-height: 34px; } }
    .lp-floor-view .floor-view__container .floor-view__main {
      align-items: flex-start;
      height: 100%; }
      @media only screen and (max-width: 1079px) {
        .lp-floor-view .floor-view__container .floor-view__main {
          flex-direction: column; } }
      @media only screen and (max-width: 1079px) {
        .lp-floor-view .floor-view__container .floor-view__main .floor-view__left {
          display: flex;
          align-items: center;
          justify-content: center; } }
      @media only screen and (max-width: 800px) {
        .lp-floor-view .floor-view__container .floor-view__main .floor-view__left {
          flex-direction: column;
          align-items: center;
          justify-content: center; } }
      .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box {
        width: 100%;
        display: flex;
        padding: 40px 0;
        box-sizing: border-box; }
        @media only screen and (max-width: 1250px) {
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box {
            padding: 20px; } }
        @media only screen and (max-width: 1079px) {
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box {
            flex-direction: column; } }
        @media only screen and (max-width: 610px) {
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box {
            padding: 0 20px; } }
        @media only screen and (max-width: 350px) {
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box {
            padding: 0 15px; } }
        .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box-select {
          display: none;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0;
          border-bottom: 1px solid #d7d7d7;
          border-top: 1px solid #d7d7d7;
          margin: 0; }
          @media only screen and (max-width: 1079px) {
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box-select {
              display: flex; } }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box-select .box-select__text {
            color: #35363a;
            font-family: "montserrat-medium", sans-serif;
            font-size: 16px;
            line-height: 19px;
            padding: 0;
            margin: 0; }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box-select .box-select__icon {
            color: #35363a;
            font-size: 21px; }
        .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box {
          margin-right: 60px; }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box:first-of-type {
            margin-right: 40px; }
            @media only screen and (max-width: 1079px) {
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box:first-of-type {
                margin: 0; } }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box.active {
            display: block; }
          @media only screen and (max-width: 1079px) {
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box {
              margin: 0;
              padding: 15px 0;
              display: none; } }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .text {
            font-family: "montserrat-bold", sans-serif;
            font-size: 13px;
            line-height: 17px;
            color: #35363a;
            text-transform: uppercase;
            padding: 0 0 7px 0;
            margin: 0; }
            @media only screen and (max-width: 1079px) {
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .text {
                padding: 0 0 10px 0; } }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose {
            display: flex;
            flex-direction: column;
            align-items: center; }
            @media only screen and (max-width: 1079px) {
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose {
                flex-direction: row; } }
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-building__button {
              width: 60px;
              height: 60px;
              background: transparent;
              outline: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: "montserrat-medium", sans-serif;
              font-size: 28px;
              line-height: 60px;
              color: #35363a;
              border: 0;
              margin: 5px 0; }
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-building__button.active {
                background-color: #af9b69;
                color: #fff; }
              @media only screen and (max-width: 1079px) {
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-building__button {
                  margin: 5px 16px 5px 0; } }
              @media only screen and (max-width: 350px) {
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-building__button {
                  margin: 5px 5px 5px 0; } }
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-storey__button {
              width: 140px;
              height: 60px;
              background: transparent;
              outline: 0;
              font-family: "montserrat-medium", sans-serif;
              font-size: 16px;
              color: #35363a;
              border: 0;
              margin: 5px 0;
              padding: 10px 8px;
              box-sizing: border-box;
              text-align: left; }
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-storey__button.active {
                background-color: #f9f9f9;
                border-left: 3px solid #af9b69; }
              @media only screen and (max-width: 1079px) {
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-storey__button {
                  margin: 5px 16px 5px 0; } }
              @media only screen and (max-width: 350px) {
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-storey__button {
                  margin: 5px 5px 5px 0; } }
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-storey__button span {
                display: block;
                font-size: 14px;
                font-family: "montserrat", sans-serif; }
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button {
              width: 140px;
              height: 40px;
              background: transparent;
              outline: 0;
              font-family: "montserrat-medium", sans-serif;
              font-size: 16px;
              color: #35363a;
              border: 0;
              margin: 5px 0;
              padding: 10px 8px;
              box-sizing: border-box;
              text-align: left; }
              @media only screen and (max-width: 1079px) {
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button {
                  margin: 5px 16px 5px 0; } }
              @media only screen and (max-width: 350px) {
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button {
                  margin: 5px 5px 5px 0; } }
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button.active {
                background-color: #f9f9f9;
                border-left: 3px solid #af9b69; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button.active.m1 {
                  border-right: 17px solid #f9f093; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button.active.m2 {
                  border-right: 17px solid #f8b67b; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button.active.m1_ {
                  border-right: 17px solid #f3978c; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button.active.m3 {
                  border-right: 17px solid #ed7560; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button.active.m4 {
                  border-right: 17px solid #e8452f; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__left .floor-view__box .box .box-choose .choose-flats__button.active.u {
                  border-right: 17px solid #bec0e2; }
      .lp-floor-view .floor-view__container .floor-view__main .floor-view__right {
        display: flex;
        height: 100%;
        position: relative;
        justify-content: center; }
        @media only screen and (max-width: 1079px) {
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__right {
            box-sizing: border-box;
            padding: 20px;
            flex-direction: column;
            min-height: 560px;
            justify-content: flex-end; } }
        .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-mapper {
          height: 100%;
          width: calc(100% - 250px);
          opacity: 0;
          z-index: -1; }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-mapper.active {
            opacity: 1;
            z-index: 0; }
          @media only screen and (max-width: 1250px) {
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-mapper {
              width: calc(100% - 300px); } }
          @media only screen and (max-width: 1079px) {
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-mapper {
              width: 100%;
              margin: 0 auto; } }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-mapper svg {
            max-width: 100%;
            object-fit: contain; }
            @media only screen and (max-width: 1079px) {
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-mapper svg {
                min-height: 430px; } }
        .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-loader {
          height: 100%;
          position: absolute;
          top: 40%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          left: calc((100% - 250px) / 2);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2; }
          @media only screen and (max-width: 1250px) {
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-loader {
              left: calc((100% - 300px) / 2); } }
          @media only screen and (max-width: 1079px) {
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-loader {
              left: 50%;
              min-height: 430px; } }
        .lp-floor-view .floor-view__container .floor-view__main .floor-view__right img {
          max-width: 100%;
          width: 100%;
          max-height: 100%;
          object-fit: contain; }
        .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info {
          width: 250px;
          padding-bottom: 100px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end; }
          @media only screen and (max-width: 1079px) {
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info {
              width: 100%;
              max-width: 320px;
              display: block;
              flex-direction: unset;
              justify-content: unset;
              padding: 0; } }
          .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status {
            width: 100%;
            box-sizing: border-box;
            padding: 70px 0 0 0; }
            @media only screen and (max-width: 1079px) {
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 25px 0 0 0; } }
            .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status .status {
              display: flex;
              align-items: center;
              padding: 5px 0;
              width: 100%;
              box-sizing: border-box; }
              @media only screen and (max-width: 1079px) {
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status .status {
                  width: 50%;
                  padding: 5px 0 10px 0; } }
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status .status .status-item {
                width: 16px;
                height: 16px;
                display: block;
                box-sizing: border-box; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status .status .status-item.free {
                  border: 1px solid #c1c6c8;
                  background: transparent; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status .status .status-item.reserved {
                  background: #c0c6c7;
                  border: 1px solid #c0c6c7; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status .status .status-item.sold {
                  background: #020202;
                  border: 1px solid #020202;
                  opacity: 0.7; }
                .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status .status .status-item.current {
                  border: 3px solid green; }
              .lp-floor-view .floor-view__container .floor-view__main .floor-view__right .floor-view__info .floor-view__status .status .status-text {
                font-family: "montserrat", sans-serif;
                font-size: 15px;
                line-height: 17px;
                color: #35363a;
                margin-left: 10px; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.floor-view__label {
  padding: 24px;
  height: auto;
  border: 1px solid #c0c6c7;
  width: 180px; }
  @media only screen and (max-width: 1079px) {
    .floor-view__label {
      display: none; } }
  .floor-view__label .floor-view__label-title {
    font-family: "montserrat-bold", sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    padding: 0 0 15px 0;
    color: #35363a; }
  .floor-view__label .floor-view__label-text {
    font-family: "montserrat-medium", sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding: 0 0 15px 0;
    margin: 0;
    color: #35363a;
    text-align: center; }
    .floor-view__label .floor-view__label-text span {
      font-family: "montserrat", sans-serif;
      display: block;
      padding: 0 0 2px 0; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px; }

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #707070;
  margin: -4px 0 0 -4px; }

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s; }

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px; }

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s; }

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px; }

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s; }

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px; }

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s; }

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px; }

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s; }

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px; }

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s; }

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px; }

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s; }

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px; }

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s; }

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px; }

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-apartment-view {
  position: relative;
  padding: 50px 0;
  overflow: hidden; }
  .lp-apartment-view .apartment-view__container .apartment-view__title {
    line-height: 40px;
    text-align: center;
    color: #0f0e0f;
    padding: 0 0 25px 0;
    font-family: "montserrat-medium", sans-serif; }
    @media only screen and (max-width: 1079px) {
      .lp-apartment-view .apartment-view__container .apartment-view__title {
        padding: 0 20px 25px 20px; } }
    @media only screen and (max-width: 610px) {
      .lp-apartment-view .apartment-view__container .apartment-view__title {
        font-size: 28px;
        line-height: 34px; } }
  @media only screen and (max-width: 1079px) {
    .lp-apartment-view .apartment-view__container .apartment-view__data {
      width: 100%; } }
  .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-view__info {
    font-size: 14px;
    line-height: 16px;
    padding: 0 0 20px 0;
    margin: 0;
    max-width: 940px;
    margin: 0 auto; }
    @media only screen and (max-width: 1079px) {
      .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-view__info {
        padding: 0 20px 20px 20px; } }
    @media only screen and (max-width: 610px) {
      .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-view__info {
        max-width: 100%; } }
  .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 940px;
    margin: 0 auto;
    box-sizing: border-box; }
    @media only screen and (max-width: 1079px) {
      .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data {
        padding: 0 20px; } }
    @media only screen and (max-width: 610px) {
      .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data {
        max-width: 100%; } }
    .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data .apartment-data__item {
      width: 25%;
      text-align: left;
      padding: 0;
      margin: 0;
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #35363a;
      padding: 0 0 20px 0;
      box-sizing: border-box; }
      .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data .apartment-data__item span {
        font-family: "montserrat-medium", sans-serif;
        margin-left: 5px;
        text-transform: none;
        color: #35363a; }
        .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data .apartment-data__item span s {
          margin-right: 5px; }
      .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data .apartment-data__item a {
        font-family: "montserrat-medium", sans-serif;
        margin-left: 5px;
        text-transform: none;
        color: #35363a;
        text-decoration: none; }
      @media only screen and (max-width: 1079px) {
        .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data .apartment-data__item {
          width: 50%; } }
      @media only screen and (max-width: 610px) {
        .lp-apartment-view .apartment-view__container .apartment-view__data .apartment-data .apartment-data__item {
          width: 100%; } }
  .lp-apartment-view .apartment-view__container .apartment-view__links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    padding: 0 20px;
    justify-content: center;
    align-items: center; }
    .lp-apartment-view .apartment-view__container .apartment-view__links .apartment-view__link {
      display: block;
      height: 50px;
      line-height: 50px;
      border: 1px solid #c1c5c7;
      background-color: transparent;
      padding: 0 32px;
      font-size: 16px;
      color: #38393d;
      font-family: "montserrat", sans-serif;
      margin: 0 10px;
      text-transform: unset; }
      @media only screen and (max-width: 610px) {
        .lp-apartment-view .apartment-view__container .apartment-view__links .apartment-view__link {
          display: none; } }
      .lp-apartment-view .apartment-view__container .apartment-view__links .apartment-view__link.accent {
        color: #fff;
        border: 1px solid #af9b69;
        background: #af9b69; }
        @media only screen and (max-width: 610px) {
          .lp-apartment-view .apartment-view__container .apartment-view__links .apartment-view__link.accent {
            display: block; } }
      @media only screen and (max-width: 1079px) {
        .lp-apartment-view .apartment-view__container .apartment-view__links .apartment-view__link {
          margin: 10px; } }
  .lp-apartment-view .apartment-view__container .apartment-view__slider {
    text-align: center;
    padding: 20px 0; }
    @media only screen and (max-width: 610px) {
      .lp-apartment-view .apartment-view__container .apartment-view__slider {
        padding: 0; } }
    .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel {
      height: 100%; }
      .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .carousel-status {
        display: none; }
      .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .slide {
        background-color: #fff;
        height: 100%; }
        .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .slide div {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
          .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .slide div img {
            object-fit: contain;
            vertical-align: middle;
            max-width: 100%;
            height: auto;
            max-height: 420px; }
            @media only screen and (max-width: 1079px) {
              .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .slide div img {
                max-height: 400px; } }
            @media only screen and (max-width: 610px) {
              .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .slide div img {
                max-height: 250px; } }
      .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumbs-wrapper {
        margin: 0; }
      .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel ul.thumbs,
      .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel ul.animated {
        padding: 0;
        margin: 0;
        margin-top: 25px; }
      @media only screen and (max-width: 1079px) {
        .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumbs {
          display: flex;
          flex-wrap: wrap;
          -webkit-transform: unset;
                  transform: unset;
          transition-duration: unset; } }
      .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumb {
        width: 180px !important;
        height: 120px;
        overflow: hidden;
        margin: 0 10px;
        cursor: pointer;
        border: none;
        transition: 0.15s ease-in; }
        @media only screen and (max-width: 610px) {
          .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumb {
            width: 136px !important;
            height: 96px;
            margin: 8px; } }
        .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumb:focus {
          outline: none;
          border: none; }
        .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumb.selected, .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumb:hover {
          border: none; }
        .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumb img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          max-height: 120px; }
          @media only screen and (max-width: 610px) {
            .lp-apartment-view .apartment-view__container .apartment-view__slider .carousel .thumb img {
              width: 136px;
              height: 96px; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.apartment-new-slick {
  width: 100%;
  max-width: 100%; }
  .apartment-new-slick .apartment-new-slick-child {
    width: calc(100% - 420px) !important;
    border: none;
    outline: none; }
    @media only screen and (max-width: 850px) {
      .apartment-new-slick .apartment-new-slick-child {
        width: 100% !important; } }
    .apartment-new-slick .apartment-new-slick-child .apartment-new-slick__image {
      display: block;
      width: auto;
      height: 100%;
      max-height: 440px;
      object-fit: contain;
      margin: 0 auto;
      cursor: pointer; }
      @media only screen and (max-width: 1079px) {
        .apartment-new-slick .apartment-new-slick-child .apartment-new-slick__image {
          max-height: 400px; } }
      @media only screen and (max-width: 610px) {
        .apartment-new-slick .apartment-new-slick-child .apartment-new-slick__image {
          max-height: 250px; } }
  .apartment-new-slick .image__container-outside {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 901; }
    .apartment-new-slick .image__container-outside .image__container {
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 90%;
      max-width: 90vw;
      height: 100%;
      max-height: 51vw;
      z-index: 1000;
      background-color: transparent; }
      @media only screen and (max-width: 1079px) {
        .apartment-new-slick .image__container-outside .image__container {
          width: 100%;
          max-width: 100vw;
          height: 100%;
          max-height: 56vw; } }
      .apartment-new-slick .image__container-outside .image__container .image__container-inside {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden; }
        .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button {
          z-index: 100;
          position: absolute;
          width: 54px;
          height: 54px;
          background: #141414;
          border: 0;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          @media only screen and (max-width: 610px) {
            .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button {
              width: 30px;
              height: 30px;
              font-size: 16px; } }
          .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button span {
            display: block;
            color: #fff; }
        .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button-left {
          bottom: 20px;
          left: calc(50% - 60px); }
          @media only screen and (max-width: 610px) {
            .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button-left {
              left: calc(50% - 35px); } }
        .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button-right {
          bottom: 20px;
          right: calc(50% - 60px);
          background: #ffffff; }
          .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button-right span {
            color: #141414; }
          @media only screen and (max-width: 610px) {
            .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button-right {
              right: calc(50% - 35px); } }
        .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-button-close {
          position: absolute;
          top: 0;
          right: 0; }
        .apartment-new-slick .image__container-outside .image__container .image__container-inside .gallery-image {
          width: auto;
          height: 100%;
          object-fit: contain; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 0; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none;
  border: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ""; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: #fff;
  z-index: 1;
  opacity: 0.25;
  transition: ease-in-out 0.2s; }
  .slick-prev:hover,
  .slick-next:hover {
    opacity: 0.75; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  border: none; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
  border: none; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25; }

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[dir="rtl"] .slick-prev {
  right: 0;
  left: auto; }

[dir="rtl"] .slick-next {
  right: auto;
  left: 0; }

.slick-prev {
  left: 0;
  display: block;
  content: "";
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/media/images/arrow-left.svg");
  width: 36px;
  height: 36px; }
  @media only screen and (max-width: 610px) {
    .slick-prev {
      width: 20px;
      height: 20px; } }

.slick-next {
  right: 0;
  display: block;
  content: "";
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/media/images/arrow-right.svg");
  width: 36px;
  height: 36px; }
  @media only screen and (max-width: 610px) {
    .slick-next {
      width: 20px;
      height: 20px; } }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  top: 0;
  left: 108px;
  display: flex;
  flex-direction: column;
  width: 120px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  height: 100%; }
  @media only screen and (max-width: 1250px) {
    .slick-dots {
      left: 40px; } }
  @media only screen and (max-width: 850px) {
    .slick-dots {
      position: relative;
      top: unset;
      flex-direction: row;
      left: unset;
      width: auto;
      justify-content: center; } }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 120px;
  height: calc(100% / 5 - 10px);
  margin: 5px 0;
  padding: 0;
  cursor: pointer;
  box-sizing: border-box; }
  @media only screen and (max-width: 850px) {
    .slick-dots li {
      margin: 0 5px;
      width: calc(100% / 5 - 10px);
      max-width: 120px;
      height: 80px; } }
  @media only screen and (max-width: 610px) {
    .slick-dots li {
      height: 50px; } }
  @media only screen and (max-width: 400px) {
    .slick-dots li {
      height: 40px; } }

.custom-paging_item {
  width: 100%;
  height: 100%; }
  .custom-paging_item img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  position: relative; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  border: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
  border: none; }

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  content: "";
  position: absolute;
  background-color: #000000;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  opacity: 0.25;
  transition: ease-in-out 0.2s;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-apartments-list-page {
  padding: 85px 0; }
  @media only screen and (max-width: 610px) {
    .lp-apartments-list-page {
      padding: 85px 0 50px 0; } }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-search {
  background: rgba(193, 198, 200, 0.1);
  padding: 50px 0; }
  @media only screen and (max-width: 610px) {
    .lp-search {
      padding: 50px 0 25px 0; } }
  .lp-search .search__container .search__title {
    padding-bottom: 50px;
    text-align: center;
    box-sizing: border-box;
    font-family: "montserrat-bold", sans-serif;
    font-size: 38px;
    line-height: 47px;
    color: #141313; }
    @media only screen and (max-width: 1079px) {
      .lp-search .search__container .search__title {
        padding: 0 20px 50px 20px; } }
    @media only screen and (max-width: 610px) {
      .lp-search .search__container .search__title {
        padding: 0 20px 25px 20px;
        font-size: 28px;
        line-height: 34px; } }
  .lp-search .search__container .search__form .button {
    font-family: "montserrat-medium", sans-serif;
    font-size: 12px;
    line-height: 22px;
    display: inline-block;
    text-decoration: none; }
  .lp-search .search__container .search__form .search__filters-show {
    color: #fff;
    width: 205px;
    height: 50px;
    line-height: 50px;
    background-color: #b79c66;
    display: none;
    cursor: pointer;
    margin: 0 auto 30px auto;
    font-size: 16px; }
    @media only screen and (max-width: 1079px) {
      .lp-search .search__container .search__form .search__filters-show {
        display: block; } }
    @media only screen and (max-width: 610px) {
      .lp-search .search__container .search__form .search__filters-show {
        height: 43px;
        line-height: 43px;
        margin: 0 auto; } }
  .lp-search .search__container .search__form .search__criteria__list {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (max-width: 1079px) {
      .lp-search .search__container .search__form .search__criteria__list {
        display: none; }
        .lp-search .search__container .search__form .search__criteria__list.mobile-active {
          display: flex; } }
    .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria {
      font-size: 12px;
      margin-bottom: 40px; }
      @media only screen and (max-width: 1250px) {
        .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria {
          text-align: center; } }
      @media only screen and (max-width: 610px) {
        .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria {
          margin: 20px 10px; } }
      @media only screen and (max-width: 1250px) {
        .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria.vertical {
          text-align: center; } }
      .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .search__text {
        font-family: "montserrat-bold", sans-serif;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #35363a;
        margin-bottom: 15px; }
      .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent {
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 1250px) {
          .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent {
            justify-content: center; } }
        @media only screen and (max-width: 1250px) {
          .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent.vertical {
            justify-content: center; } }
        .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .search__text {
          margin-bottom: 0;
          margin-right: 10px; }
        .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio {
          padding-left: 0;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media only screen and (max-width: 610px) {
            .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio.storey {
              flex-wrap: wrap; }
              .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio.storey li label {
                margin: 3px 5px; } }
          .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio li {
            font-size: 12px;
            display: block; }
            .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio li label {
              border: 1px solid #b79c66;
              margin: 0 3px;
              display: block;
              width: 40px;
              height: 40px;
              line-height: 40px;
              transition: 0.5s;
              cursor: pointer;
              text-align: center; }
              .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio li label:hover {
                background-color: #b79c66;
                color: #fff; }
          .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio.vertical {
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap; }
            @media only screen and (max-width: 1250px) {
              .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio.vertical {
                justify-content: center; } }
            .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio.vertical li {
              width: auto;
              margin: 5px 10px; }
              .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio.vertical li label {
                min-width: 110px;
                width: auto;
                padding: 0 5px;
                cursor: pointer;
                height: 40px;
                line-height: 40px;
                text-align: center;
                vertical-align: middle; }
          .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio input[type="radio"] {
            display: none; }
            .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio input[type="radio"]:checked + label {
              background: #b79c66;
              color: #fff; }
          .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio input[type="checkbox"] {
            display: none; }
            .lp-search .search__container .search__form .search__criteria__list .search__column .search__criteria .horizontal-radio-parent .horizontal-radio input[type="checkbox"]:checked + label {
              background: #b79c66;
              color: #fff; }
  .lp-search .search__container .search__form .search__actions {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 1079px) {
      .lp-search .search__container .search__form .search__actions {
        display: none;
        margin-top: 20px;
        padding-bottom: 40px; }
        .lp-search .search__container .search__form .search__actions.mobile-active {
          display: flex;
          flex-direction: column;
          justify-content: center; } }
    .lp-search .search__container .search__form .search__actions .search__button {
      width: 200px;
      height: 50px;
      background-color: #b79c66;
      line-height: 50px;
      margin-right: 30px;
      margin-left: 30px;
      font-size: 16px; }
      @media only screen and (max-width: 1079px) {
        .lp-search .search__container .search__form .search__actions .search__button {
          margin: 20px auto 20px auto; } }
      @media only screen and (max-width: 610px) {
        .lp-search .search__container .search__form .search__actions .search__button {
          height: 43px;
          line-height: 43px; } }
    .lp-search .search__container .search__form .search__actions .clean__button {
      width: 200px;
      height: 50px;
      line-height: 50px;
      display: inline-block;
      border: 0;
      color: #000;
      background: #fff;
      font-size: 16px; }
      @media only screen and (max-width: 1079px) {
        .lp-search .search__container .search__form .search__actions .clean__button {
          margin: 0 auto; } }
      @media only screen and (max-width: 610px) {
        .lp-search .search__container .search__form .search__actions .clean__button {
          height: 43px;
          line-height: 43px; } }


.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.list-mobile__item {
  padding: 0 15px;
  margin-top: 10px;
  box-sizing: border-box; }
  .list-mobile__item .list-mobile__title-wrapper {
    background-color: #edeeed;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 10px;
    margin: 0;
    max-width: 100%;
    box-sizing: border-box; }
    .list-mobile__item .list-mobile__title-wrapper .list-mobile__input-wrapper {
      width: 50%; }
      .list-mobile__item .list-mobile__title-wrapper .list-mobile__input-wrapper input[type="checkbox"] {
        display: none; }
      .list-mobile__item .list-mobile__title-wrapper .list-mobile__input-wrapper input[type="checkbox"] + label {
        display: block;
        width: 17px;
        height: 17px;
        border: 1px solid #707070;
        cursor: pointer;
        transition: 0.3s;
        background-color: #fff; }
        .list-mobile__item .list-mobile__title-wrapper .list-mobile__input-wrapper input[type="checkbox"] + label span {
          opacity: 0;
          color: #fff; }
      .list-mobile__item .list-mobile__title-wrapper .list-mobile__input-wrapper input[type="checkbox"]:checked + label {
        background: #b79c66;
        border: 1px solid #b79c66; }
        .list-mobile__item .list-mobile__title-wrapper .list-mobile__input-wrapper input[type="checkbox"]:checked + label span {
          opacity: 1; }
    .list-mobile__item .list-mobile__title-wrapper .list-mobile__title {
      width: 50%;
      color: #35363a;
      font-family: "montserrat-medium", sans-serif;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      margin: 0;
      padding: 0; }
  .list-mobile__item .list-mobile__text {
    max-width: 100%;
    box-sizing: border-box;
    color: #35363a;
    display: flex;
    align-items: center;
    font-family: "montserrat", sans-serif;
    font-size: 16px;
    line-height: 19px;
    padding: 7px 10px;
    margin: 0;
    border-top: 1px solid #edeeed;
    border-bottom: 1px solid #edeeed; }
    .list-mobile__item .list-mobile__text.sold {
      color: #7b1835; }
    .list-mobile__item .list-mobile__text.no-border-bottom {
      border-bottom: 0;
      margin-bottom: 10px; }
    .list-mobile__item .list-mobile__text span {
      width: 50%; }
      .list-mobile__item .list-mobile__text span .list-mobile__more {
        padding: 0;
        margin: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 0;
        background-color: transparent;
        outline: 0;
        color: #35363a;
        font-size: 14px;
        text-transform: uppercase;
        font-family: "montserrat-medium", sans-serif; }
        .list-mobile__item .list-mobile__text span .list-mobile__more .af-icon-minus-square,
        .list-mobile__item .list-mobile__text span .list-mobile__more .af-icon-plus-square {
          margin-right: 5px;
          font-size: 18px; }
      .list-mobile__item .list-mobile__text span .list-mobile__see {
        display: block;
        text-align: center;
        text-decoration: none;
        width: 90px;
        height: 31px;
        line-height: 31px;
        font-size: 14px;
        text-transform: uppercase;
        font-family: "montserrat-medium", sans-serif;
        background-color: #af9b69;
        color: #fff; }
      .list-mobile__item .list-mobile__text span .af-icon-envelope {
        font-size: 20px;
        width: 20px;
        height: auto;
        display: inline-block;
        margin-right: 8px; }
      .list-mobile__item .list-mobile__text span .download-pdf-icon {
        text-decoration: none;
        color: inherit; }
      .list-mobile__item .list-mobile__text span .list-mobile__send {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-apartments-list {
  padding: 10px 0 50px 0;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none; }
  .lp-apartments-list .lp-apartments-list-info {
    margin: 30px 0;
    font-size: 24px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    padding: 0 10px; }
  @media only screen and (max-width: 1250px) {
    .lp-apartments-list {
      overflow-x: scroll; } }
  .lp-apartments-list::-webkit-scrollbar {
    display: none; }
  @media only screen and (max-width: 610px) {
    .lp-apartments-list {
      padding: 0 0 50px 0; } }
  .lp-apartments-list .apartments-list__container .apartments-list__mobile {
    padding: 0 0 30px 0;
    display: block; }
  .lp-apartments-list .apartments-list__container table {
    width: 100%;
    margin-bottom: 30px;
    font-family: "montserrat", sans-serif;
    border: none;
    border-collapse: collapse;
    font-size: 16px;
    line-height: 22px;
    color: #35363a;
    min-width: 1200px; }
    .lp-apartments-list .apartments-list__container table thead th {
      position: relative; }
      .lp-apartments-list .apartments-list__container table thead th:hover {
        cursor: pointer; }
      .lp-apartments-list .apartments-list__container table thead th button {
        cursor: pointer; }
      .lp-apartments-list .apartments-list__container table thead th button,
      .lp-apartments-list .apartments-list__container table thead th .button__options {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        padding: 10px 0;
        width: 100%;
        min-height: 48px;
        font-family: "montserrat-bold", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #35363a;
        background: transparent;
        text-align: center; }
        .lp-apartments-list .apartments-list__container table thead th button button,
        .lp-apartments-list .apartments-list__container table thead th .button__options button {
          display: block;
          padding: 5px 0;
          cursor: pointer; }
      .lp-apartments-list .apartments-list__container table thead th .button__options {
        width: 125%;
        min-width: 130px;
        background-color: #fff;
        flex-direction: column;
        margin-top: 10px;
        position: absolute;
        top: 30px;
        z-index: 1;
        -webkit-transform: translateX(-25%);
                transform: translateX(-25%);
        border: 1px solid #ddd;
        cursor: auto; }
        .lp-apartments-list .apartments-list__container table thead th .button__options > div {
          display: flex;
          align-items: center; }
        .lp-apartments-list .apartments-list__container table thead th .button__options button {
          background: transparent;
          padding: 10px 0;
          cursor: pointer;
          min-height: 0; }
        .lp-apartments-list .apartments-list__container table thead th .button__options div.checked {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          position: absolute;
          margin-left: -21px; }
    .lp-apartments-list .apartments-list__container table tbody tr.list-item {
      height: 50px;
      position: relative;
      color: #35363a;
      border-bottom: 1px solid #c1c6c8;
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      text-align: center; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item input[type="checkbox"] + label::before {
        border: 1px solid #c1c6c8; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item input[type="checkbox"]:checked + label::before {
        background: #c1c6c8; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item.reservation {
        color: #B0B0B0; }
        .lp-apartments-list .apartments-list__container table tbody tr.list-item.reservation a {
          color: #B0B0B0; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item.sold {
        color: #7B1835; }
        .lp-apartments-list .apartments-list__container table tbody tr.list-item.sold a {
          color: #7B1835; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item:hover {
        background: #f9f9f9; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item td {
        font-family: "montserrat", sans-serif;
        font-size: 16px;
        line-height: 22px; }
        .lp-apartments-list .apartments-list__container table tbody tr.list-item td input[type="checkbox"] {
          display: none; }
        .lp-apartments-list .apartments-list__container table tbody tr.list-item td input[type="checkbox"] + label {
          display: block;
          width: 17px;
          height: 17px;
          border: 1px solid #C1C6C8;
          cursor: pointer;
          transition: 0.3s;
          margin-left: 10px; }
          .lp-apartments-list .apartments-list__container table tbody tr.list-item td input[type="checkbox"] + label span {
            opacity: 0;
            color: #fff; }
        .lp-apartments-list .apartments-list__container table tbody tr.list-item td input[type="checkbox"]:checked + label {
          background: #b79c66;
          border: 1px solid #b79c66; }
          .lp-apartments-list .apartments-list__container table tbody tr.list-item td input[type="checkbox"]:checked + label span {
            opacity: 1; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item .td-floor {
        text-transform: lowercase; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item a {
        text-decoration: none;
        cursor: pointer;
        font-family: "montserrat", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #35363a; }
      .lp-apartments-list .apartments-list__container table tbody tr.list-item .af-icon-pdf {
        font-size: 22px; }
  .lp-apartments-list span[class^="af-icon-"],
  .lp-apartments-list span[class*=" af-icon-"] {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
    .lp-apartments-list span[class^="af-icon-"]::before,
    .lp-apartments-list span[class*=" af-icon-"]::before {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .lp-apartments-list span[class^="af-icon-"] span,
    .lp-apartments-list span[class*=" af-icon-"] span {
      display: none; }
  .lp-apartments-list .af-icon-envelope {
    font-size: 20px; }
  .lp-apartments-list .control-button--up {
    border: solid #000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin-left: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
    .lp-apartments-list .control-button--up + span {
      cursor: pointer; }
  .lp-apartments-list .no-results-container {
    width: 100%;
    text-align: center;
    padding: 20px 30px; }
    .lp-apartments-list .no-results-container .no-results-title {
      margin: 20px auto;
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: #35363a;
      text-align: left; }
    .lp-apartments-list .no-results-container .no-results-button {
      width: 200px;
      height: 50px;
      line-height: 50px;
      background: #b79c66;
      text-align: center;
      color: #fff;
      display: block;
      font-size: 16px;
      cursor: pointer; }
  .lp-apartments-list .pagination {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center; }
    .lp-apartments-list .pagination i {
      line-height: 40px; }
    .lp-apartments-list .pagination a {
      display: block;
      color: #000;
      text-align: center;
      line-height: 40px;
      height: 40px;
      width: 40px;
      text-decoration: none;
      transition: background-color 0.3s;
      font-size: 14px; }
      .lp-apartments-list .pagination a:hover {
        cursor: pointer; }
    .lp-apartments-list .pagination a.active {
      background-color: #b79c66;
      color: white; }
    .lp-apartments-list .pagination a:hover:not(.active) {
      background-color: #ddd; }
  .lp-apartments-list .apartments-list__form-box {
    text-align: center; }
    @media only screen and (max-width: 1250px) {
      .lp-apartments-list .apartments-list__form-box {
        padding: 0 20px; } }
    .lp-apartments-list .apartments-list__form-box .form-box__info {
      margin: 50px 0 20px 0;
      font-size: 16px; }
      @media only screen and (max-width: 610px) {
        .lp-apartments-list .apartments-list__form-box .form-box__info {
          margin: 30px 0 20px 0; } }
      .lp-apartments-list .apartments-list__form-box .form-box__info span {
        font-family: "montserrat-bold", sans-serif; }
    .lp-apartments-list .apartments-list__form-box .apartments-list__sent {
      color: #141414;
      font-size: 16px;
      padding: 10px 0; }
    .lp-apartments-list .apartments-list__form-box form {
      margin: 0 auto; }
      .lp-apartments-list .apartments-list__form-box form .apartments-list__button {
        width: 200px;
        height: 50px;
        background: #b79c66;
        font-size: 16px;
        line-height: 50px;
        display: inline-block; }
        @media only screen and (max-width: 610px) {
          .lp-apartments-list .apartments-list__form-box form .apartments-list__button {
            display: block;
            margin: 0 auto; } }
      .lp-apartments-list .apartments-list__form-box form input {
        line-height: 50px;
        font-family: "montserrat", sans-serif;
        color: #c1c6c8;
        padding: 0 0 0 8px;
        outline: 0;
        transition: 0.3s;
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
        background: rgba(193, 198, 200, 0.1);
        border: 1px solid #c1c6c8;
        box-sizing: border-box;
        width: 448px;
        height: 50px; }
        @media only screen and (max-width: 610px) {
          .lp-apartments-list .apartments-list__form-box form input {
            display: block;
            width: 100%;
            max-width: 448px;
            margin: 10px auto; } }
        .lp-apartments-list .apartments-list__form-box form input ::-webkit-input-placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #000000;
          opacity: 1;
          /* Firefox */ }
        .lp-apartments-list .apartments-list__form-box form input ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #000000;
          opacity: 1;
          /* Firefox */ }
        .lp-apartments-list .apartments-list__form-box form input :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #000000; }
        .lp-apartments-list .apartments-list__form-box form input ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #000000; }
        .lp-apartments-list .apartments-list__form-box form input:focus {
          color: #000; }
      .lp-apartments-list .apartments-list__form-box form input[type="email"].error {
        border-bottom: 1px solid #ff0000; }
        .lp-apartments-list .apartments-list__form-box form input[type="email"].error::-webkit-input-placeholder {
          color: red; }
        .lp-apartments-list .apartments-list__form-box form input[type="email"].error::placeholder {
          color: red; }
      @media only screen and (max-width: 610px) {
        .lp-apartments-list .apartments-list__form-box form {
          flex-direction: column;
          justify-content: center;
          align-items: center; } }


.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-privacy {
  padding: 100px 0 50px 0; }
  @media only screen and (max-width: 610px) {
    .lp-privacy {
      padding: 150px 0 50px 0; } }
  .lp-privacy .title {
    margin: 0;
    padding: 40px 30px 10px 30px;
    text-align: center; }
    @media only screen and (max-width: 1079px) {
      .lp-privacy .title {
        padding: 20px 20px 10px 20px; } }
  .lp-privacy .text {
    padding: 30px; }
    @media only screen and (max-width: 1079px) {
      .lp-privacy .text {
        padding: 20px; } }
    .lp-privacy .text p {
      text-align: justify !important;
      font-size: 16px !important;
      font-family: "montserrat-light", sans-serif;
      line-height: 32px !important; }
    .lp-privacy .text span {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 600;
      font-family: "meriweather", serif; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-meeting {
  padding: 85px 0; }
  @media only screen and (max-width: 610px) {
    .lp-meeting {
      padding: 70px 0 0 0; } }
  .lp-meeting .meeting__banner {
    width: 100%;
    height: 680px;
    background: url(/static/media/meeting-background.19424c08.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media only screen and (min-width: 1800px) {
      .lp-meeting .meeting__banner {
        height: 800px; } }
    @media only screen and (max-width: 610px) {
      .lp-meeting .meeting__banner {
        width: 100vw;
        min-height: 500px; } }
    .lp-meeting .meeting__banner .meeting__container {
      padding-top: 250px; }
      @media only screen and (max-width: 1250px) {
        .lp-meeting .meeting__banner .meeting__container {
          padding-left: 20px; } }
      @media only screen and (max-width: 610px) {
        .lp-meeting .meeting__banner .meeting__container {
          padding-top: 100px;
          padding-right: 20px; } }
      @media only screen and (max-width: 350px) {
        .lp-meeting .meeting__banner .meeting__container {
          padding-top: 50px; } }
      .lp-meeting .meeting__banner .meeting__container .meeting__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 38px;
        line-height: 47px;
        color: #ffffff;
        max-width: 700px; }
        @media only screen and (max-width: 610px) {
          .lp-meeting .meeting__banner .meeting__container .meeting__title {
            max-width: 100%; } }
        .lp-meeting .meeting__banner .meeting__container .meeting__title span {
          background-color: #141313;
          line-height: 47px;
          padding: 0 25px;
          padding-bottom: 10px; }
          @media only screen and (max-width: 610px) {
            .lp-meeting .meeting__banner .meeting__container .meeting__title span {
              padding: 0;
              width: 100%;
              background-color: transparent;
              color: #141313;
              text-align: center;
              display: block; } }
          .lp-meeting .meeting__banner .meeting__container .meeting__title span.first {
            padding-top: 10px;
            padding-bottom: 0; }
            @media only screen and (max-width: 610px) {
              .lp-meeting .meeting__banner .meeting__container .meeting__title span.first {
                padding-top: 0; } }
      .lp-meeting .meeting__banner .meeting__container .meeting__text {
        font-family: "montserrat", sans-serif;
        font-size: 20px;
        line-height: 24px;
        color: #141313;
        max-width: 300px;
        margin: 30px 0 0 0; }
        @media only screen and (max-width: 610px) {
          .lp-meeting .meeting__banner .meeting__container .meeting__text {
            text-align: center;
            max-width: 100%; } }
        .lp-meeting .meeting__banner .meeting__container .meeting__text.bold {
          font-family: "montserrat-bold", sans-serif; }
          @media only screen and (max-width: 610px) {
            .lp-meeting .meeting__banner .meeting__container .meeting__text.bold {
              text-align: center; } }
      .lp-meeting .meeting__banner .meeting__container .meeting__form-link {
        width: 290px;
        height: 50px;
        line-height: 50px;
        background: #b79c66;
        font-family: "montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 30px; }
        @media only screen and (max-width: 610px) {
          .lp-meeting .meeting__banner .meeting__container .meeting__form-link {
            margin: 30px auto 0 auto; } }
  .lp-meeting .meeting__contact {
    background: rgba(193, 198, 200, 0.1);
    padding: 50px 0; }
    .lp-meeting .meeting__contact .meeting__container .meeting__title {
      font-family: "montserrat-bold", sans-serif;
      font-size: 38px;
      line-height: 47px;
      text-align: center;
      color: #141414;
      margin: 0;
      padding: 0; }
      @media only screen and (max-width: 610px) {
        .lp-meeting .meeting__contact .meeting__container .meeting__title {
          font-size: 28px;
          line-height: 34px; } }
    .lp-meeting .meeting__contact .meeting__container .meeting__text {
      font-family: "montserrat", sans-serif;
      font-size: 24px;
      line-height: 29px;
      color: #141414;
      text-align: center;
      margin: 30px 0 50px 0;
      padding: 0; }
      @media only screen and (max-width: 610px) {
        .lp-meeting .meeting__contact .meeting__container .meeting__text {
          margin: 30px 0; } }
  .lp-meeting .meeting__benefits {
    padding: 50px 0; }
    .lp-meeting .meeting__benefits .meeting__container .meeting__title {
      font-family: "montserrat-bold", sans-serif;
      font-size: 38px;
      line-height: 47px;
      text-align: center;
      color: #141414;
      margin: 0;
      padding: 0; }
      @media only screen and (max-width: 610px) {
        .lp-meeting .meeting__benefits .meeting__container .meeting__title {
          font-size: 28px;
          line-height: 34px; } }
    .lp-meeting .meeting__benefits .meeting__container .meeting__text {
      font-family: "montserrat", sans-serif;
      font-size: 24px;
      line-height: 29px;
      color: #141414;
      text-align: center;
      margin: 30px 0 70px 0;
      padding: 0; }
      @media only screen and (max-width: 610px) {
        .lp-meeting .meeting__benefits .meeting__container .meeting__text {
          margin: 30px 0; } }
    .lp-meeting .meeting__benefits .meeting__container .meeting__benefits-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
      .lp-meeting .meeting__benefits .meeting__container .meeting__benefits-box .meeting__benefit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        @media only screen and (max-width: 1079px) {
          .lp-meeting .meeting__benefits .meeting__container .meeting__benefits-box .meeting__benefit {
            margin-top: 15px; } }
        .lp-meeting .meeting__benefits .meeting__container .meeting__benefits-box .meeting__benefit .meeting__benefit-icon {
          display: block;
          font-size: 72px;
          line-height: 72px;
          color: #b79c66;
          margin-bottom: 15px; }
          @media only screen and (max-width: 610px) {
            .lp-meeting .meeting__benefits .meeting__container .meeting__benefits-box .meeting__benefit .meeting__benefit-icon {
              font-size: 55px;
              line-height: 55px; } }
        .lp-meeting .meeting__benefits .meeting__container .meeting__benefits-box .meeting__benefit .meeting__benefit-text {
          display: block;
          font-family: "montserrat", sans-serif;
          text-align: center;
          color: #000000;
          max-width: 250px;
          padding: 0 0 10px 0; }
          .lp-meeting .meeting__benefits .meeting__container .meeting__benefits-box .meeting__benefit .meeting__benefit-text.bold {
            font-family: "montserrat-bold", sans-serif;
            font-size: 18px;
            line-height: 22px;
            color: #141414; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-meeting-contact-form {
  position: relative; }
  .lp-meeting-contact-form .form__sent-box {
    background: #b79c66;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
    width: 90%;
    height: auto;
    min-height: 120px;
    max-width: 320px;
    padding: 20px;
    border-radius: 3px; }
    .lp-meeting-contact-form .form__sent-box .form__sent {
      position: relative;
      text-align: center; }
      .lp-meeting-contact-form .form__sent-box .form__sent .form__sent-info {
        font-size: 18px;
        font-family: "montserrat-bold", sans-serif;
        padding-top: 40px; }
      .lp-meeting-contact-form .form__sent-box .form__sent .form__sent-close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer; }
  .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs {
    flex-wrap: wrap;
    align-items: flex-end; }
    .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__input-box,
    .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__textarea {
      text-align: center; }
    .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__radio {
      padding-bottom: 30px;
      max-width: 720px;
      margin: 0 auto; }
      @media only screen and (max-width: 610px) {
        .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__radio {
          max-width: 90%; } }
      .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__radio .radio__text {
        font-family: "montserrat-bold", sans-serif;
        padding: 40px 0 20px; }
        @media only screen and (max-width: 1079px) {
          .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__radio .radio__text {
            padding: 20px 0 20px 0; } }
      .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__radio .radio__box {
        display: flex;
        justify-content: space-between; }
    .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__agreement-box {
      max-width: 720px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding-bottom: 20px; }
      @media only screen and (max-width: 610px) {
        .lp-meeting-contact-form .contact__form .meeting-contact-form__inputs .meeting-contact-form__agreement-box {
          max-width: 90%; } }
  .lp-meeting-contact-form .contact__form .required-info {
    max-width: 720px;
    margin: 0 auto;
    display: block;
    font-family: "montserrat-light", sans-serif;
    line-height: 15px;
    font-size: 12px;
    padding-left: 35px; }
    @media only screen and (max-width: 610px) {
      .lp-meeting-contact-form .contact__form .required-info {
        max-width: 90%; } }
  .lp-meeting-contact-form .contact__form .form__button-box {
    text-align: center; }
    .lp-meeting-contact-form .contact__form .form__button-box .form__button {
      width: 240px;
      height: 54px;
      background: #b79c66;
      border: 1px solid #b79c66;
      font-size: 16px;
      line-height: 54px;
      margin: 30px auto 0 auto; }
  .lp-meeting-contact-form .contact__form .textarea-label {
    display: block;
    width: 100%;
    text-align: left;
    font-family: "montserrat", sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #35363a;
    text-transform: uppercase;
    max-width: 480px;
    margin: 0 auto;
    padding-bottom: 8px; }
    @media only screen and (max-width: 1079px) {
      .lp-meeting-contact-form .contact__form .textarea-label {
        padding-top: 32px; } }
    @media only screen and (max-width: 610px) {
      .lp-meeting-contact-form .contact__form .textarea-label {
        max-width: 90%; } }
  .lp-meeting-contact-form .contact__form textarea,
  .lp-meeting-contact-form .contact__form input[type="email"],
  .lp-meeting-contact-form .contact__form input[type="text"] {
    font-family: "montserrat-medium", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 12px;
    color: #000000;
    padding: 32px 0 8px 0;
    border: none;
    border-bottom: 1px solid #000000;
    background: transparent;
    outline: 0;
    transition: 0.3s;
    display: block;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    max-width: 480px; }
    @media only screen and (max-width: 610px) {
      .lp-meeting-contact-form .contact__form textarea,
      .lp-meeting-contact-form .contact__form input[type="email"],
      .lp-meeting-contact-form .contact__form input[type="text"] {
        max-width: 90%; } }
    .lp-meeting-contact-form .contact__form textarea ::-webkit-input-placeholder, .lp-meeting-contact-form .contact__form input[type="email"] ::-webkit-input-placeholder, .lp-meeting-contact-form .contact__form input[type="text"] ::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000000;
      opacity: 1;
      /* Firefox */ }
    .lp-meeting-contact-form .contact__form textarea ::placeholder,
    .lp-meeting-contact-form .contact__form input[type="email"] ::placeholder,
    .lp-meeting-contact-form .contact__form input[type="text"] ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000000;
      opacity: 1;
      /* Firefox */ }
    .lp-meeting-contact-form .contact__form textarea :-ms-input-placeholder,
    .lp-meeting-contact-form .contact__form input[type="email"] :-ms-input-placeholder,
    .lp-meeting-contact-form .contact__form input[type="text"] :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000000; }
    .lp-meeting-contact-form .contact__form textarea ::-ms-input-placeholder,
    .lp-meeting-contact-form .contact__form input[type="email"] ::-ms-input-placeholder,
    .lp-meeting-contact-form .contact__form input[type="text"] ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #000000; }
    .lp-meeting-contact-form .contact__form textarea:focus,
    .lp-meeting-contact-form .contact__form input[type="email"]:focus,
    .lp-meeting-contact-form .contact__form input[type="text"]:focus {
      border-bottom: 1px solid #000000; }
    .lp-meeting-contact-form .contact__form textarea.error,
    .lp-meeting-contact-form .contact__form input[type="email"].error,
    .lp-meeting-contact-form .contact__form input[type="text"].error {
      border-bottom: 1px solid #ff0000; }
    .lp-meeting-contact-form .contact__form textarea:disabled,
    .lp-meeting-contact-form .contact__form input[type="email"]:disabled,
    .lp-meeting-contact-form .contact__form input[type="text"]:disabled {
      color: #658385; }
  .lp-meeting-contact-form .contact__form textarea {
    color: #b79c66;
    background: #ffffff;
    border: 0;
    height: 168px;
    max-width: 480px;
    margin: 0 auto;
    padding: 10px 5px; }
    .lp-meeting-contact-form .contact__form textarea:focus {
      border-bottom: 0; }
    @media only screen and (max-width: 610px) {
      .lp-meeting-contact-form .contact__form textarea {
        max-width: 90%;
        height: 80px; } }
    .lp-meeting-contact-form .contact__form textarea ::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #b79c66;
      opacity: 1;
      /* Firefox */ }
    .lp-meeting-contact-form .contact__form textarea ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #b79c66;
      opacity: 1;
      /* Firefox */ }
    .lp-meeting-contact-form .contact__form textarea :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b79c66; }
    .lp-meeting-contact-form .contact__form textarea ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #b79c66; }
  .lp-meeting-contact-form .contact__form input[type="radio"] {
    display: none; }
  .lp-meeting-contact-form .contact__form input[type="radio"] + label {
    position: relative;
    display: block;
    padding-left: 25px;
    font-size: 14px; }
    @media only screen and (max-width: 1079px) {
      .lp-meeting-contact-form .contact__form input[type="radio"] + label {
        font-size: 12px;
        padding-left: 20px; } }
  .lp-meeting-contact-form .contact__form input[type="radio"] + label::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
    border: 1px solid #000000;
    cursor: pointer;
    transition: 0.3s; }
    @media only screen and (max-width: 1079px) {
      .lp-meeting-contact-form .contact__form input[type="radio"] + label::before {
        width: 15px;
        height: 15px; } }
  .lp-meeting-contact-form .contact__form input[type="radio"]:checked + label::before {
    background: #b79c66;
    border: 1px solid #b79c66; }
  .lp-meeting-contact-form .contact__form input[type="checkbox"] {
    display: none; }
  .lp-meeting-contact-form .contact__form .agreement-span {
    display: inline-block;
    font-family: "montserrat-light", sans-serif;
    line-height: 22px;
    font-size: 14px;
    margin-left: 15px; }
  .lp-meeting-contact-form .contact__form input[type="checkbox"] + label {
    width: 17px;
    height: 17px;
    border: 1px solid #000000;
    cursor: pointer;
    transition: 0.3s; }
    .lp-meeting-contact-form .contact__form input[type="checkbox"] + label span {
      opacity: 0;
      color: #fff; }
  .lp-meeting-contact-form .contact__form input[type="checkbox"]:checked + label {
    background: #b79c66;
    border: 1px solid #b79c66; }
    .lp-meeting-contact-form .contact__form input[type="checkbox"]:checked + label span {
      opacity: 1; }
  .lp-meeting-contact-form .contact__form input[type="checkbox"].error + label {
    border-color: #ff0000; }

