@import "../../assets/scss/base.scss";

.lp-benefits {
  padding: 50px 0;

  .benefits__container {
    .benefits__title {
      text-align: center;
      margin-bottom: 50px;
      box-sizing: border-box;
      font-size: 38px;
      line-height: 47px;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px;
      }
      @media only screen and (max-width: $bp-small) {
        margin-bottom: 35px;
        font-size: 28px;
        line-height: 34px;
      }
    }

    .benefits__main {
      align-items: center;
      flex-wrap: wrap;
      max-width: 1180px;
      margin: 0 auto;
      padding: 40px;

      @media only screen and (max-width: 800px) {
        flex-direction: column;
        padding: 0;
      }

      .benefits__col {
        @media only screen and (max-width: 800px) {
          width: 100%;
        }

        .benefits__box {
          padding: 25px;
          display: flex;
          align-items: center;
          box-sizing: border-box;

          @media only screen and (max-width: $bp-small) {
            padding: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .benefits__icon {
            width: 80px;
            height: 80px;
            background: #b79c66;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: $bp-small) {
              width: 60px;
              height: 60px;
              margin-right: 0;
              margin-bottom: 10px;
            }

            img {
              display: block;
              height: 45px;
              width: auto;
              object-fit: contain;

              @media only screen and (max-width: $bp-small) {
                height: 35px;
              }
            }
          }

          .benefits__details {
            max-width: calc(100% - 100px);

            @media only screen and (max-width: $bp-small) {
              max-width: 100%;
            }

            .benefits__details-text {
              font-size: 12px;
              line-height: 15px;
              padding: 8px 0;
              text-align: left;

              @media only screen and (max-width: $bp-small) {
                text-align: center;
              }

              .text {
                display: block;
                font-family: "montserrat", sans-serif;
                font-size: 17px;
                line-height: 25px;
              }
              .accent {
                display: block;
                font-family: "montserrat-bold", sans-serif;
                font-size: 26px;
                line-height: 32px;
                padding-bottom: 10px;

                @media only screen and (max-width: $bp-small) {
                  font-size: 20px;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
    .benefits__button {
      width: 291px;
      height: 54px;
      background: #b79c66;
      border: 1px solid #b79c66;
      font-size: 16px;
      line-height: 54px;
      margin: 35px auto;
    }
  }
}
