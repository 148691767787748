@import "../../../assets/scss/base";

.list-mobile__item {
  padding: 0 15px;
  margin-top: 10px;
  box-sizing: border-box;
 

  &.reservation {
  }

  &.sold {
  }
  .list-mobile__title-wrapper {
    background-color: #edeeed;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 10px;
    margin: 0;
    max-width: 100%;
    box-sizing: border-box;

    .list-mobile__input-wrapper {
      width: 50%;
      input[type="checkbox"] {
        display: none;
      }
      input[type="checkbox"] + label {
        display: block;
        width: 17px;
        height: 17px;
        border: 1px solid #707070;
        cursor: pointer;
        transition: 0.3s;
        background-color: #fff;

        span {
          opacity: 0;
          color: #fff;
        }
      }
      input[type="checkbox"]:checked + label {
        background: #b79c66;
        border: 1px solid #b79c66;
        span {
          opacity: 1;
        }
      }
    }

    .list-mobile__title {
      width: 50%;

      color: #35363a;
      font-family: "montserrat-medium", sans-serif;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
  }

  .list-mobile__text {
    max-width: 100%;
    box-sizing: border-box;
    color: #35363a;
    display: flex;
    align-items: center;
    font-family: "montserrat", sans-serif;
    font-size: 16px;
    line-height: 19px;
    padding: 7px 10px;
    margin: 0;
    border-top: 1px solid #edeeed;
    border-bottom: 1px solid #edeeed;

    &.reservation {
    }

    &.sold {
      color: #7b1835;
    }

    &.no-border-bottom {
      border-bottom: 0;
      margin-bottom: 10px;
    }

    span {
      width: 50%;

      .list-mobile__more {
        padding: 0;
        margin: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 0;
        background-color: transparent;
        outline: 0;
        color: #35363a;
        font-size: 14px;
        text-transform: uppercase;
        font-family: "montserrat-medium", sans-serif;

        .af-icon-minus-square,
        .af-icon-plus-square {
          margin-right: 5px;
          font-size: 18px;
        }
      }
      .list-mobile__see {
        display: block;
        text-align: center;
        text-decoration: none;
        width: 90px;
        height: 31px;
        line-height: 31px;
        font-size: 14px;
        text-transform: uppercase;
        font-family: "montserrat-medium", sans-serif;
        background-color: #af9b69;
        color: #fff;
      }

      .af-icon-envelope {
        font-size: 20px;
        width: 20px;
        height: auto;
        display: inline-block;
        margin-right: 8px;
      }
      .download-pdf-icon {
        text-decoration: none;
        color: inherit;
      }

      .list-mobile__send {
        text-decoration: none;
        display: flex;
        align-items: center;
        color:inherit;
        text-decoration: none;
      }
    }
  }
}
