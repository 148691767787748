@import "../../assets/scss/base.scss";

.lp-header {
  background: transparent;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  max-width: 100vw;

  &.header-moving,
  &.black {
    background: rgba(20, 20, 20, 1);
    background: -moz-linear-gradient(
      top,
      rgba(20, 20, 20, 1) 0%,
      rgba(20, 20, 20, 0.92) 9%,
      rgba(20, 20, 20, 0.84) 57%,
      rgba(52, 52, 52, 0.8) 84%,
      rgba(65, 65, 65, 0.8) 95%,
      rgba(110, 110, 110, 0.8) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(20, 20, 20, 1)),
      color-stop(9%, rgba(20, 20, 20, 0.92)),
      color-stop(57%, rgba(20, 20, 20, 0.84)),
      color-stop(84%, rgba(52, 52, 52, 0.8)),
      color-stop(95%, rgba(65, 65, 65, 0.8)),
      color-stop(100%, rgba(110, 110, 110, 0.8))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(20, 20, 20, 1) 0%,
      rgba(20, 20, 20, 0.92) 9%,
      rgba(20, 20, 20, 0.84) 57%,
      rgba(52, 52, 52, 0.8) 84%,
      rgba(65, 65, 65, 0.8) 95%,
      rgba(110, 110, 110, 0.8) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(20, 20, 20, 1) 0%,
      rgba(20, 20, 20, 0.92) 9%,
      rgba(20, 20, 20, 0.84) 57%,
      rgba(52, 52, 52, 0.8) 84%,
      rgba(65, 65, 65, 0.8) 95%,
      rgba(110, 110, 110, 0.8) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(20, 20, 20, 1) 0%,
      rgba(20, 20, 20, 0.92) 9%,
      rgba(20, 20, 20, 0.84) 57%,
      rgba(52, 52, 52, 0.8) 84%,
      rgba(65, 65, 65, 0.8) 95%,
      rgba(110, 110, 110, 0.8) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(20, 20, 20, 1) 0%,
      rgba(20, 20, 20, 0.92) 9%,
      rgba(20, 20, 20, 0.84) 57%,
      rgba(52, 52, 52, 0.8) 84%,
      rgba(65, 65, 65, 0.8) 95%,
      rgba(110, 110, 110, 0.8) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#141414', endColorstr='#6e6e6e', GradientType=0 );
  }

  &.white {
  }

  .nav__toApartments {
    width: 100%;
    height: 0px;
    background-color: #fff;
    color: #141313;
    align-items: center;
    justify-content: center;
    display: none;
    text-transform: unset;
    transition: height 0.3s ease-in-out;
    opacity: 0;

    .icon {
      font-size: 30px;
      margin-right: 10px;
      line-height: unset;
    }
    .text {
      font-family: "montserrat-medium", sans-serif;
      font-size: 16px;
    }
    @media only screen and (max-width: $bp-small) {
      display: flex;
    }

    &.active {
      opacity: 1;
      height: 50px;
    }
  }

  .header__navigation {
    background: rgba(20, 20, 20, 0.95);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: -100;
    transition: all 0.5s ease-in-out;

    &.active {
      display: block;
    }

    .navigation__container {
      width: 100%;
      height: 100%;
      position: relative;

      .nav__list {
        display: flex;
        width: 100%;
        height: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 85px 0 0 0;

        @media only screen and (max-width: $bp-medium) {
          flex-direction: column;
          padding-top: 150px;
        }
        .nav__list-item {
          width: 25%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid rgba(240, 240, 240, 0.7);

          &:last-of-type {
            border-right: 0;

            @media only screen and (max-width: $bp-medium) {
              border-bottom: 0;
            }
          }

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
            border-right: 0;
            max-height: 120px;
          }
          @media only screen and (max-width: $bp-small) {
            max-height: 75px;
          }

          .nav__list-item-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 150px;

            @media only screen and (max-width: $bp-medium) {
              height: auto;
            }

            .nav__link {
              display: block;
              text-decoration: none;
              color: #ffffff;
              font-family: "montserrat-medium", sans-serif;
              font-size: 32px;
              line-height: 40px;
              transition: 0.5s;
              padding-bottom: 30px;

              @media only screen and (max-width: $bp-medium) {
                padding-bottom: 10px;
              }
              @media only screen and (max-width: $bp-small) {
                padding-bottom: 6px;
                font-size: 20px;
                line-height: 24px;
              }

              &:hover {
                cursor: pointer;
              }
            }
            .nav__sublink {
              display: block;
              text-decoration: none;
              transition: 0.5s;
              font-family: "montserrat", sans-serif;
              font-size: 16px;
              line-height: 20px;
              color: #ffffff;
              padding-bottom: 10px;

              @media only screen and (max-width: $bp-medium) {
                padding-bottom: 5px;
              }
              @media only screen and (max-width: $bp-small) {
                padding-bottom: 0;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .header__container {
    padding: 20px 0;

    @media only screen and (max-width: $bp-small) {
      padding: 16px 0;
    }

    .header-left__col {
      display: flex;
      align-items: center;

      .header__menu-button {
        border: 0;
        outline: 0;
        width: auto;
        height: auto;
        background: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;

        .header__menu {
          color: #fff;
          margin-left: 20px;
          font-size: 32px;
          @media only screen and (max-width: $bp-medium) {
            font-size: 28px;
          }
        }
        .header__menu-text {
          font-size: 12px;
          color: #ffffff;
          text-transform: uppercase;
          font-family: "montserrat-medium", sans-serif;
          margin-left: 10px;
          display: none;

          &.active {
            display: block;

            @media only screen and (max-width: $bp-small) {
              display: none;
            }
          }
        }
      }
    }
    .header-main__col {
      .header__logo-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-decoration: none;

        .header__logo {
          display: block;
          height: 45px;
          width: auto;
          margin: 0 auto;

          @media only screen and (max-width: $bp-small) {
            height: 38px;
          }
        }
        .header__logo-title {
          text-transform: uppercase;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          letter-spacing: 0.3em;
          padding: 2px 0;
        }
        .header__logo-text {
          text-transform: uppercase;
          font-size: 10px;
          line-height: 13px;
          text-align: center;
          letter-spacing: 0.2em;
        }
      }
    }
    .header-right__col {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .header__nav {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;

        @media only screen and (max-width: $bp-medium) {
          display: none;
        }

        .header__nav-item {
          padding: 0 15px;

          .header__link {
            color: #fff;
            text-decoration: none;
            font-size: 14px;
            line-height: 16px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.27);
            text-transform: uppercase;
            font-family: "montserrat-bold", sans-serif;
          }
        }
      }
    }
  }
}
