@import "../../assets/scss/base";

.lp-apartments-list {
  padding: 10px 0 50px 0;

  .lp-apartments-list-info {
    margin: 30px 0;
    font-size: 24px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    padding: 0 10px;
  }

  @media only screen and (max-width: $bp-large) {
    overflow-x: scroll;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }


  @media only screen and (max-width: $bp-small) {
    padding: 0 0 50px 0;
  }

  .apartments-list__container {

    .apartments-list__mobile{
      padding:0 0 30px 0;
      display: block;
    }

    table {
      width: 100%;
      margin-bottom: 30px;
      font-family: "montserrat", sans-serif;
      border: none;
      border-collapse: collapse;
      font-size: 16px;
      line-height: 22px;
      color: #35363a;
      min-width: 1200px;

      thead {
        th {
          position: relative;

          &:hover {
            cursor: pointer;
          }

          button {
            cursor: pointer;
          }

          button,
          .button__options {
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
            padding: 10px 0;
            width: 100%;
            min-height: 48px;
            font-family: "montserrat-bold", sans-serif;
            font-size: 14px;
            line-height: 17px;
            color: #35363a;
            background: transparent;
            text-align: center;

            button {
              display: block;
              padding: 5px 0;
              cursor: pointer;
            }
          }

          .button__options {
            width: 125%;
            min-width: 130px;
            background-color: #fff;
            flex-direction: column;
            margin-top: 10px;
            position: absolute;
            top: 30px;
            z-index: 1;
            transform: translateX(-25%);
            border: 1px solid #ddd;
            cursor: auto;

            & > div {
              display: flex;
              align-items: center;
            }

            button {
              background: transparent;
              padding: 10px 0;
              cursor: pointer;
              min-height: 0;
            }

            div.checked {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              position: absolute;
              margin-left: -21px;
            }
          }
        }
      }

      tbody {
        tr.list-item {
          height: 50px;
          position: relative;
          color: #35363a;
          border-bottom: 1px solid #c1c6c8;
          font-family: "montserrat", sans-serif;
          font-size: 16px;
          text-align: center;

          input[type="checkbox"] + label::before {
            border: 1px solid #c1c6c8;
          }
          input[type="checkbox"]:checked + label::before {
            background: #c1c6c8;
          }

          &.reservation {
            color: #B0B0B0;

            a {
              color: #B0B0B0;
            }
            input[type="checkbox"] + label::before {
            }
            input[type="checkbox"]:checked + label::before {
            }
          }

          &.sold {
            color: #7B1835;
            a {
              color: #7B1835;
            }
            input[type="checkbox"] + label::before {

            }
            input[type="checkbox"]:checked + label::before {

            }
          }

          &:hover {
            background: #f9f9f9;
          }

          td {
            font-family: "montserrat", sans-serif;
            font-size: 16px;
            line-height: 22px;

            input[type="checkbox"] {
              display: none;
            }
            input[type="checkbox"] + label {
              display: block;
              width: 17px;
              height: 17px;
              border: 1px solid #C1C6C8;
              cursor: pointer;
              transition: 0.3s;
              margin-left: 10px;

              span {
                opacity: 0;
                color: #fff;
              }
            }
            input[type="checkbox"]:checked + label {
              background: #b79c66;
              border: 1px solid #b79c66;
              span {
                opacity: 1;
              }
            }
          }

          .td-floor {
            text-transform: lowercase;
          }


          a {
            text-decoration: none;
            cursor: pointer;
            font-family: "montserrat", sans-serif;
            font-size: 16px;
            line-height: 22px;
            color: #35363a;
          }

          .af-icon-pdf {
            font-size: 22px;
          }
        }
      }
    }
  }

  span[class^="af-icon-"],
  span[class*=" af-icon-"] {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    span {
      display: none;
    }
  }
  .af-icon-envelope{
    font-size: 20px;
  }


  .control-button--up {
    border: solid #000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin-left: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    + span {
      cursor: pointer;
    }
  }

  .no-results-container {
    width: 100%;
    text-align: center;
    padding: 20px 30px;

    .no-results-title {
      margin: 20px auto;
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: #35363a;
      text-align: left;
    }
    .no-results-button {
      width: 200px;
      height: 50px;
      line-height: 50px;
      background: #b79c66;
      text-align: center;
      color: #fff;
      display: block;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .pagination {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;

    i {
      line-height: 40px;
    }
    a {
      display: block;
      color: #000;
      text-align: center;
      line-height: 40px;
      height: 40px;
      width: 40px;
      text-decoration: none;
      transition: background-color 0.3s;
      font-size: 14px;
      
      &:hover {
        cursor: pointer;
      }
    }
    a.active {
      background-color: #b79c66;
      color: white;
    }
    a:hover:not(.active) {
      background-color: #ddd;
    }
  }

  .apartments-list__form-box {
    text-align: center;

    @media only screen and (max-width: $bp-large) {
      padding: 0 20px;
    }

    .form-box__info {
      margin: 50px 0 20px 0;
      font-size: 16px;
      @media only screen and (max-width: $bp-small) {
        margin: 30px 0 20px 0;

      }
      span {
        font-family: "montserrat-bold", sans-serif;
      }
    }

    .apartments-list__sent{
      color: #141414;
      font-size: 16px;
      padding:10px 0;
    }

    form {
      margin: 0 auto;

      .apartments-list__button {
        width: 200px;
        height: 50px;
        background: #b79c66;
        font-size: 16px;
        line-height: 50px;
        display: inline-block;

        @media only screen and (max-width: $bp-small) {
          display: block;
          margin:0 auto;
        }
      }

      input {
        line-height: 50px;
        font-family: "montserrat", sans-serif;
        color: #c1c6c8;
        padding: 0 0 0 8px;
        outline: 0;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
        background: rgba(193, 198, 200, 0.1);
        border: 1px solid #c1c6c8;
        box-sizing: border-box;
        width: 448px;
        height: 50px;


        @media only screen and (max-width: $bp-small) {
          display: block;
          width:100%;
          max-width: 448px;
          margin:10px auto;
        }

        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #000000;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #000000;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #000000;
        }

        &:focus {
          color: #000;
        }
      }

      input[type="email"] {
        &.error {
          border-bottom: 1px solid #ff0000;
          &::placeholder {
            color: red;
          }
        }
      }

      @media only screen and (max-width: $bp-small) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
