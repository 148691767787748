@charset "UTF-8";
.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }

.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media only screen and (min-width: 1500px) {
    .container-new {
      max-width: 80%; } }
  @media only screen and (min-width: 1920px) {
    .container-new {
      max-width: 1650px; } }

.row {
  display: flex; }

.col-1 {
  width: 8.33%; }

.col-133p {
  width: 13.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-3-5 {
  width: 29.16%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 1250px) {
  .col-l-1 {
    width: 8.33%; }
  .col-l-2 {
    width: 16.66%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33%; }
  .col-l-5 {
    width: 41.66%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33%; }
  .col-l-8 {
    width: 66.66%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33%; }
  .col-l-11 {
    width: 91.66%; }
  .col-l-12 {
    width: 100%; } }

@media only screen and (max-width: 1079px) {
  .col-m-1 {
    width: 8.33%; }
  .col-m-2 {
    width: 16.66%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33%; }
  .col-m-5 {
    width: 41.66%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33%; }
  .col-m-8 {
    width: 66.66%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33%; }
  .col-m-11 {
    width: 91.66%; }
  .col-m-12 {
    width: 100%; } }

@media only screen and (max-width: 610px) {
  .col-s-1 {
    width: 8.33%; }
  .col-s-2 {
    width: 16.66%; }
  .col-s-3 {
    width: 25%; }
  .col-s-4 {
    width: 33.33%; }
  .col-s-5 {
    width: 41.66%; }
  .col-s-6 {
    width: 50%; }
  .col-s-7 {
    width: 58.33%; }
  .col-s-8 {
    width: 66.66%; }
  .col-s-9 {
    width: 75%; }
  .col-s-10 {
    width: 83.33%; }
  .col-s-11 {
    width: 91.66%; }
  .col-s-12 {
    width: 100%; } }

.lp-localization-info {
  padding: 50px 0;
  position: relative; }
  @media only screen and (max-width: 610px) {
    .lp-localization-info {
      padding: 0 0 50px 0; } }
  .lp-localization-info .localization-info__container {
    flex-wrap: wrap; }
    .lp-localization-info .localization-info__container .localization-info__left .localization-info__title {
      color: #141313;
      padding: 0 0 50px 50px; }
      @media only screen and (max-width: 610px) {
        .lp-localization-info .localization-info__container .localization-info__left .localization-info__title {
          padding: 0 20px 35px 20px; } }
    .lp-localization-info .localization-info__container .localization-info__left .localization-info__text {
      font-family: "montserrat", sans-serif;
      font-size: 17px;
      line-height: 30px;
      color: #141313;
      padding: 0 0 50px 50px; }
      @media only screen and (max-width: 1079px) {
        .lp-localization-info .localization-info__container .localization-info__left .localization-info__text {
          padding: 0 50px 70px 50px; } }
      @media only screen and (max-width: 610px) {
        .lp-localization-info .localization-info__container .localization-info__left .localization-info__text {
          padding: 0 20px 70px 20px; } }
    .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent {
      /* Slider */
      /* Arrows */
      /* Slider */ }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item {
        display: block;
        text-decoration: none; }
        .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item .slider-item__image {
          width: 408px;
          height: 408px;
          position: relative;
          overflow: hidden; }
          @media only screen and (max-width: 1079px) {
            .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item .slider-item__image {
              width: 300px;
              height: 300px; } }
          @media only screen and (max-width: 610px) {
            .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item .slider-item__image {
              width: 95%;
              max-width: 300px;
              max-height: 300px; } }
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .image-parent .slider-item .slider-item__image img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-loading .slick-list {
        background: #fff center center no-repeat; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: -70px;
        display: block;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        outline: none;
        background: transparent;
        border: 0;
        z-index: 1; }
        @media only screen and (max-width: 1079px) {
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev,
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
            top: -40px; } }
        .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev::before,
        .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next::before {
          position: relative;
          top: -3px; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:hover,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:focus,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:hover,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:focus {
        color: #000;
        outline: none; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:hover:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:focus:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:hover:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:focus:before {
        opacity: 1; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev.slick-disabled:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next.slick-disabled:before {
        opacity: 0.5; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next:before {
        font-size: 30px;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev {
        right: 200px;
        left: auto; }
        @media only screen and (max-width: 740px) {
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev {
            left: 50px;
            right: auto; } }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-prev {
        right: 15px;
        left: auto; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-prev {
        display: block;
        content: "";
        background-image: url("../../assets/icons/thin-arrow-left.svg");
        background-position: center;
        background-size: contain;
        width: 80px;
        height: 20px;
        background-repeat: no-repeat; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-prev:before {
        content: "→"; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
        right: 100px; }
        @media only screen and (max-width: 740px) {
          .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
            right: 50px; } }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-next {
        right: auto;
        left: 15px; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-next {
        display: block;
        content: "";
        background-image: url("../../assets/icons/thin-arrow-right.svg");
        background-position: center;
        background-size: contain;
        width: 80px;
        height: 20px;
        background-repeat: no-repeat; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-next:before {
        content: "←"; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-list:focus {
        outline: none; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-list.dragging {
        cursor: pointer;
        cursor: hand; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slider .slick-track,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-track:before,
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-track:after {
        display: table;
        content: ""; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-track:after {
        clear: both; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-loading .slick-track {
        visibility: hidden; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent [dir="rtl"] .slick-slide {
        float: right; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slide img {
        display: block; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slide.slick-loading img {
        display: none; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-slide.dragging img {
        pointer-events: none; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-initialized .slick-slide {
        display: block; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-loading .slick-slide {
        visibility: hidden; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent; }
      .lp-localization-info .localization-info__container .localization-info__main .localization-info__slider-parent .slick-arrow.slick-hidden {
        display: none; }
