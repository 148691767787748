@import "../../assets/scss/base.scss";

.banner__side-contact {
  width: 50px;
  height: auto;
  position: fixed;
  z-index: 1;
  right: 0;
  top: 30%;

  @media only screen and (max-width: $bp-small) {
    width: 35px;
    top: 100px;
    display: none;
  }
  .side__link {
    display: block;
    background: #35363a;
    width: 50px;
    height: 50px;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $bp-small) {
      width: 35px;
      height: 35px;
    }

    &.social {
      background: rgba(53, 54, 58, 0.7);
    }

    &.meeting {
      margin-top: 25px;
      background-color: #b79c66;
      @media only screen and (max-width: $bp-small) {
        margin-top: 15px;
      }
    }

    &.meeting-text {
      background-color: #b79c66;
      text-transform: uppercase;
      height: 226px;
      font-family: "montserrat-medium", sans-serif;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;

      @media only screen and (max-width: $bp-small) {
        font-size: 14px;
        line-height: 18px;
        height: auto;
        padding: 10px 0;
      }

      span {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        white-space: nowrap;
      }
    }

    .side__icon {
      color: #fff;
      font-size: 22px;

      @media only screen and (max-width: $bp-small) {
        font-size: 16px;
      }
    }
    .af-icon-mail {
      font-size: 18px;

      @media only screen and (max-width: $bp-small) {
        font-size: 13px;
      }
    }
  }
}
