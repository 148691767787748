@import "../../assets/scss/base.scss";

.lp-estate-new {
  width: 100%;
  max-width: 100vw;
  height: 50vw;
  max-height: 50vw;

  .estate-new__bacground-wrapper{
    width:100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;

      .estate-new__background{
        width:100%;
        height:calc(100% + 200px);
        object-fit: cover;
        object-position: center;
      }
  }
}
