@import "../../assets/scss/base.scss";

.lp-meeting {
  padding: 85px 0;

  @media only screen and (max-width: $bp-small) {
    padding: 70px 0 0 0;
  }

  .meeting__banner {
    width: 100%;
    height: 680px;
    background: url("../../assets/images/meeting-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (min-width: 1800px) {
      height: 800px;
    }

    @media only screen and (max-width: $bp-small) {
      width: 100vw;
      min-height: 500px;
    }

    .meeting__container {
      padding-top: 250px;

      @media only screen and (max-width: $bp-large) {
        padding-left: 20px;
      }

      @media only screen and (max-width: $bp-small) {
        padding-top: 100px;
        padding-right: 20px;
      }
      @media only screen and (max-width: 350px) {
        padding-top: 50px;
      }

      .meeting__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 38px;
        line-height: 47px;
        color: #ffffff;
        max-width: 700px;

        @media only screen and (max-width: $bp-small) {
          max-width: 100%;
        }

        span {
          background-color: #141313;
          line-height: 47px;
          padding: 0 25px;
          padding-bottom: 10px;

          @media only screen and (max-width: $bp-small) {
            padding: 0;
            width: 100%;
            background-color: transparent;
            color: #141313;
            text-align: center;
            display: block;
          }

          &.first {
            padding-top: 10px;
            padding-bottom: 0;

            @media only screen and (max-width: $bp-small) {
              padding-top: 0;
            }
          }
        }
      }
      .meeting__text {
        font-family: "montserrat", sans-serif;
        font-size: 20px;
        line-height: 24px;
        color: #141313;

        max-width: 300px;
        margin: 30px 0 0 0;

        @media only screen and (max-width: $bp-small) {
          text-align: center;
          max-width: 100%;
        }

        &.bold {
          font-family: "montserrat-bold", sans-serif;
          @media only screen and (max-width: $bp-small) {
            text-align: center;
          }
        }
      }

      .meeting__form-link {
        width: 290px;
        height: 50px;
        line-height: 50px;
        background: #b79c66;
        font-family: "montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 30px;

        @media only screen and (max-width: $bp-small) {
          margin: 30px auto 0 auto;
        }
      }
    }
  }
  .meeting__contact {
    background: rgba(193, 198, 200, 0.1);
    padding: 50px 0;

    .meeting__container {
      .meeting__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 38px;
        line-height: 47px;
        text-align: center;
        color: #141414;
        margin: 0;
        padding: 0;

        @media only screen and (max-width: $bp-small) {
          font-size: 28px;
          line-height: 34px;
        }
      }
      .meeting__text {
        font-family: "montserrat", sans-serif;
        font-size: 24px;
        line-height: 29px;
        color: #141414;

        text-align: center;
        margin: 30px 0 50px 0;
        padding: 0;

        @media only screen and (max-width: $bp-small) {
          margin: 30px 0;
        }
      }
    }
  }

  .meeting__benefits {
    padding: 50px 0;

    .meeting__container {
      .meeting__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 38px;
        line-height: 47px;
        text-align: center;
        color: #141414;
        margin: 0;
        padding: 0;

        @media only screen and (max-width: $bp-small) {
          font-size: 28px;
          line-height: 34px;
        }
      }
      .meeting__text {
        font-family: "montserrat", sans-serif;
        font-size: 24px;
        line-height: 29px;
        color: #141414;
        text-align: center;
        margin: 30px 0 70px 0;
        padding: 0;

        @media only screen and (max-width: $bp-small) {
          margin: 30px 0;
        }
      }

      .meeting__benefits-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .meeting__benefit {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: $bp-medium) {
            margin-top: 15px;
          }

          .meeting__benefit-icon {
            display: block;
            font-size: 72px;
            line-height: 72px;
            color: #b79c66;
            margin-bottom: 15px;

            @media only screen and (max-width: $bp-small) {
              font-size: 55px;
              line-height: 55px;
            }
          }
          .meeting__benefit-text {
            display: block;
            font-family: "montserrat", sans-serif;
            text-align: center;
            color: #000000;
            max-width: 250px;
            padding: 0 0 10px 0;

            &.bold {
              font-family: "montserrat-bold", sans-serif;
              font-size: 18px;
              line-height: 22px;
              color: #141414;
            }
          }
        }
      }
    }
  }
}
