@import "../../assets/scss/mixins";
@import "../../assets/scss/base";
@import "../../assets/scss/colors";

.lp-apartment-view {
  position: relative;
  padding: 50px 0;
  overflow: hidden;

  .apartment-view__container {
    .apartment-view__title {
      line-height: 40px;
      text-align: center;
      color: #0f0e0f;
      padding: 0 0 25px 0;
      font-family: "montserrat-medium", sans-serif;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px 25px 20px;
      }

      @media only screen and (max-width: $bp-small) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .apartment-view__data {
      @media only screen and (max-width: $bp-medium) {
        width: 100%;
      }
      .apartment-view__info {
        font-size: 14px;
        line-height: 16px;
        padding: 0 0 20px 0;
        margin: 0;
        max-width: 940px;
        margin: 0 auto;

        @media only screen and (max-width: $bp-medium) {
          padding: 0 20px 20px 20px;
        }
        @media only screen and (max-width: $bp-small) {
          max-width: 100%;
        }
      }

      .apartment-data {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-width: 940px;
        margin: 0 auto;
        box-sizing: border-box;

        @media only screen and (max-width: $bp-medium) {
          padding: 0 20px;
        }
        @media only screen and (max-width: $bp-small) {
          max-width: 100%;
        }

        .apartment-data__item {
          width: 25%;
          text-align: left;
          padding: 0;
          margin: 0;
          font-family: "montserrat", sans-serif;
          font-size: 16px;
          line-height: 19px;
          color: #35363a;
          padding: 0 0 20px 0;
          box-sizing: border-box;
          span {
            font-family: "montserrat-medium", sans-serif;
            margin-left: 5px;
            text-transform: none;
            color: #35363a;

            s {
              margin-right: 5px;
            }
          }

          a {
            font-family: "montserrat-medium", sans-serif;
            margin-left: 5px;
            text-transform: none;
            color: #35363a;
            text-decoration: none;
          }

          @media only screen and (max-width: $bp-medium) {
            width: 50%;
          }
          @media only screen and (max-width: $bp-small) {
            width: 100%;
          }
        }
      }
    }

    .apartment-view__links {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      padding: 0 20px;
      justify-content: center;
      align-items: center;

      .apartment-view__link {
        display: block;
        height: 50px;
        line-height: 50px;
        border: 1px solid #c1c5c7;
        background-color: transparent;
        padding: 0 32px;
        font-size: 16px;
        color: #38393d;
        font-family: "montserrat", sans-serif;
        margin: 0 10px;
        text-transform: unset;

        @media only screen and (max-width: $bp-small) {
          display: none;
        }

        &.accent {
          color: #fff;
          border: 1px solid #af9b69;
          background: #af9b69;

          @media only screen and (max-width: $bp-small) {
            display: block;
          }
        }

        @media only screen and (max-width: $bp-medium) {
          margin: 10px;
        }
      }
    }

    .apartment-view__slider {
      text-align: center;
      padding: 20px 0;

      @media only screen and (max-width: $bp-small) {
        padding: 0;
      }

      .carousel {
        height: 100%;

        .carousel-status {
          display: none;
        }

        .slide {
          background-color: #fff;
          height: 100%;

          div {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              object-fit: contain;
              vertical-align: middle;
              max-width: 100%;
              height: auto;
              max-height: 420px;

              @media only screen and (max-width: $bp-medium) {
                max-height: 400px;
              }

              @media only screen and (max-width: $bp-small) {
                max-height: 250px;
              }
            }
          }
        }

        .slider-wrapper {
        }

        .thumbs-wrapper {
          margin: 0;
        }

        ul.thumbs,
        ul.animated {
          padding: 0;
          margin: 0;
          margin-top: 25px;
        }

        .thumbs {
          @media only screen and (max-width: $bp-medium) {
            display: flex;
            flex-wrap: wrap;
            transform: unset;
            transition-duration: unset;
          }
        }

        .thumb {
          width: 180px !important;
          height: 120px;
          overflow: hidden;
          margin: 0 10px;
          cursor: pointer;
          border: none;
          transition: 0.15s ease-in;

          @media only screen and (max-width: $bp-small) {
            width: 136px !important;
            height: 96px;
            margin: 8px;
          }

          &:focus {
            outline: none;
            border: none;
          }

          &.selected,
          &:hover {
            border: none;
          }

          img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            max-height: 120px;

            @media only screen and (max-width: $bp-small) {
              width: 136px;
              height: 96px;
            }
          }
        }
      }
    }
  }
}
