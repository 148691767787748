@import "../../assets/scss/base.scss";

.lp-localization-view {
  box-sizing: border-box;
  margin: 30px 0;
  height: 665px;
  background: url("../../assets/images/map-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px 0;
  background-position-x: right;
  position: relative;

  @media only screen and (max-width: $bp-medium) {
    height: 450px;
  }
  @media only screen and (max-width: $bp-small) {
    height: 200px;
    margin: 0 0 50px 0;

  }

  .localization-view__container {
  }
}
