@import "../../assets/scss/base.scss";

.lp-gallery {
  padding: 100px 0;

  @media only screen and (max-width: $bp-small) {
    padding: 150px 0 50px 0;
  }

  .gallery_container {
    flex-wrap: wrap;
    justify-content: center;

    .gallery__title {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #0f0e0f;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px;
      }
    }
    .gallery__icon {
      height: 12px;
      width: 40px;
      object-fit: contain;
      display: block;
      margin: 25px auto;
      color: #dfb452;
    }
    .gallery__text {
      font-family: "montserrat-light", sans-serif;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      max-width: 780px;
      margin: 0 auto;
      padding-bottom: 50px;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px 50px 20px;
      }
    }

    .gallery__button {
      width: 210px;
      height: 54px;
      background: #b79c66;
      border: 1px solid #b79c66;
      margin: 60px auto;
      text-transform: unset;
      line-height: 54px;

      span {
        color: #fff;
      }
    }

    .gallery__main {
      flex-wrap: wrap;

      .gallery-image-box {
        padding: 7px;
        box-sizing: border-box;

        .gallery-image {
          margin: 0 auto;
          display: block;
          width: 100%;
          height: auto;
          object-fit: cover;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          transition: all 0.3s ease 0s;

          &:hover {
            box-shadow: 0 4px 15px rgba(5, 5, 6, 0.1);
            -webkit-box-shadow: 0 3px 20px rgba(5, 5, 6, 0.1);
            cursor: pointer;
          }
        }
      }
    }
  }

  .image__container-outside {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 901;

    .image__container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width:90%;
      max-width: 90vw;
      height:100%;
      max-height: 51vw;
      z-index: 1000;
      background-color: transparent;

      
      @media only screen and (max-width: $bp-medium) {
        width: 100%;
        max-width: 100vw;
        height:100%;
        max-height: 56vw;
      }

      .image__container-inside {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: center;

        .gallery-button {
          z-index: 100;
          position: absolute;
          width: 54px;
          height: 54px;
          background: #b79c66;
          border: 1px solid #b79c66;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          @media only screen and (max-width: $bp-small) {
            width: 30px;
            height: 30px;
            font-size: 16px;
          }
          span {
            display: block;
            color: #fff;
          }
        }

        .gallery-button-left {
          bottom: 20px;
          left: calc(50% - 60px);

          @media only screen and (max-width: $bp-small) {
            left: calc(50% - 35px);
          }
        }
        .gallery-button-right {
          bottom: 20px;
          right: calc(50% - 60px);
          background: #ffffff;
          span {
            color: #b79c66;
          }
          @media only screen and (max-width: $bp-small) {
            right: calc(50% - 35px);
          }
        }
        .gallery-button-close {
          top: 0;
          right: 0;
        }

        .gallery-image {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
