@import "../../assets/scss/base.scss";

.lp-apartments-new {
  width: 100%;
  max-width: 100vw;
  height: 640px;
  position: relative;
  overflow-x: hidden;

  @media only screen and (max-width: $bp-medium) {
    background: transparent;
    height: auto;
  }

  .apartments-new__container {
    height: 100%;

    .apartments-new__top {
      height: 100%;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column;
      }

      .apartments-new__top-left {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        width: 100%;

        @media only screen and (max-width: $bp-medium) {
          flex-direction: column;
          height: auto;
        }

        .apartments-new__left-top-left {
          width: 50%;
          background-color: #efefef;

          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
          }

          &:before {
            content: "";
            display: block;
            min-width: 100%;
            width: calc(100% + 200px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #efefef;
            transform: translateX(-99%);

            @media only screen and (max-width: $bp-medium) {
              display: none;
            }
          }

          &:after {
            content: "";
            display: block;
            width:48px;
            height: 48px;
            position: absolute;
            top: 50%;
            right: -5px;
            background-color: #efefef;
            transform: rotate(45deg) translateY(-50%);
    z-index:1;
            @media only screen and (max-width: $bp-medium) {
              top:unset;
              right:unset;
              bottom:-38px;
              left:50%;
              transform: rotate(45deg) translateX(-50%) translateY(0);
            }
          }

          .apartments-new__title {
            font-family: "montserrat-bold", sans-serif;
            font-size: 38px;
            line-height: 47px;
            color: #141313;
            padding: 0 20px;

            @media only screen and (max-width: $bp-large) {
              padding: 42px 20px 0 20px;
            }

            @media only screen and (max-width: $bp-small) {
              font-size: 28px;
              line-height: 34px;
            }
          }
          .apartments-new__text {
            font-family: "montserrat", sans-serif;
            font-size: 17px;
            line-height: 30px;
            color: #141313;
            padding: 32px 20px;
            max-width: 380px;
          }
          .apartments-new__button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 47px;
            line-height: 47px;
            width: 221px;
            text-transform: unset;
            margin: 0 0 0 20px;
            font-family: "montserrat-medium", sans-serif;
            font-size: 16px;

            @media only screen and (max-width: $bp-medium) {
              margin: 0 0 42px 20px;
            }
          }
        }

        .apartments-new__left-top-right {
          display: flex;
          flex-wrap: wrap;
          background-color: #fff;
          position: relative;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 100%;

          @media only screen and (max-width: $bp-medium) {
            height: auto;
            width: 100%;
          }

          @media only screen and (max-width: $bp-small) {
            flex-direction: column;
            justify-content: center;
            padding-top: 30px;
          }

          .apartments-new__box {
            box-sizing: border-box;
            width: 50%;
            height:225px;
            padding: 0 20px;

            @media only screen and (max-width: $bp-small) {
              width: 80%;
            }

            .apartments-new__box-title {
              text-align: center;
              color: #b79c66;
              font-family: "montserrat-bold", sans-serif;
              font-size: 72px;
              line-height: 88px;
              padding: 0 0 15px 0;
              margin: 0;
            }
            .apartments-new__box-text {
              line-height: 24px;
              font-size: 16px;
              color: #363636;
              text-align: center;
              padding: 0;
              margin: 0;
            }
          }
        }
      }

      .apartments-new__top-right {
        position: relative;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url("../../assets/images/apartments-new1.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .apartments-new__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none;

          @media only screen and (max-width: $bp-medium) {
            display: block;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }
    }
  }
}
