@import "../../assets/scss/mixins";
@import "../../assets/scss/base";

.lp-footer {
  background: #000000;
  padding: 50px 0;

  .footer__container {
    @media only screen and (max-width: $bp-medium) {
      flex-direction: column;
    }

    .footer__left {
      text-align: left;

      @media only screen and (max-width: $bp-medium) {
        text-align: center;
      }

      .footer__logo-link {
        display: block;
        text-decoration: none;
        width: auto;
        height: auto;

        .footer__logo {
          display: block;
          width: 180px;
          height: auto;
          object-fit: contain;
        }

        @media only screen and (max-width: $bp-large) {
          padding-left: 20px;
        }
      }
      .footer__text {
        color: #fff;
        font-family: "montserrat", sans-serif;
        font-size: 16px;
        line-height: 23px;
        padding: 15px 0;
        max-width: 360px;
        text-align: justify;

        @media only screen and (max-width: $bp-large) {
          padding-left: 20px;
        }
        @media only screen and (max-width: $bp-medium) {
          padding: 15px 20px;
          max-width: 100%;
        }
      }
    }

    .footer__main {
      @media only screen and (max-width: $bp-medium) {
        text-align: center;
      }

      .footer__title {
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        font-family: "montserrat-medium", sans-serif;

        @media only screen and (max-width: $bp-medium) {
          padding: 25px 20px 0 20px;
        }
      }

      .footer__investments {
        padding-top: 52px;
        display: flex;

        @media only screen and (max-width: $bp-medium) {
          justify-content: center;
          padding-top: 25px;
        }

        @media only screen and (max-width: $bp-medium) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .footer__investment-link {
          display: block;
          text-decoration: none;
          margin: 0 10px;
          width: 120px;
          height: 80px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: $bp-medium) {
            margin: 10px;

            &:first-of-type {
              margin-left: 10px;
            }
          }

          &:first-of-type {
            margin-left: 0;
          }

          .investment-logo {
            width: 90%;
            height: auto;
            margin: 0 auto;
            object-fit: contain;
          }
        }
      }
    }

    .footer__right {
      .footer__links {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: $bp-large) {
          padding-right: 20px;
        }

        @media only screen and (max-width: $bp-medium) {
          justify-content: center;
          align-items: center;
          padding: 25px 20px 0 20px;
        }

        .link {
          display: block;
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 25px;
          text-align: right;
          margin: 10px 0;

          .af-icon-arrow-right {
            color: #dfb452;
            font-size: 8px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
