@import "../../assets/scss/base.scss";

.header__side-contact {
  height: auto;
  position: absolute;
  right: 0;
  top: 186px;
  display: none;

  @media only screen and (max-width: $bp-small) {
    display: block;
  }

  .side__link {
    display: block;
    background: #35363a;
    width: 40px;
    height: 40px;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &.social {
      background: rgba(53, 54, 58, 0.7);
    }

    .side__icon {
      color: #fff;
      font-size: 16px;
    }
    .af-icon-mail {
      font-size: 13px;
    }
  }
}
