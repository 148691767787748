@import "../../assets/scss/base.scss";

.lp-journal {
  width: 100%;
  max-width: 100vw;
  height: 640px;
  position: relative;
  overflow-x: hidden;

  @media only screen and (max-width: $bp-medium) {
    background: transparent;
    height: auto;
  }

  .journal__container {
    height: 100%;

    .journal__top {
      height: 100%;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column;
      }

      .journal__top-left {
        position: relative;
        height: 100%;
        background: #f8f8f8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @media only screen and (max-width: $bp-medium) {
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: #f8f8f8;
          z-index: -1;

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .journal__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px;

          @media only screen and (max-width: $bp-large) {
            padding: 42px 20px 0 20px;
          }

          @media only screen and (max-width: $bp-small) {
            font-size: 28px;
            line-height: 34px;
          }
        }
        .journal__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #363636;
          padding: 32px 20px;
          max-width: 380px;

          @media only screen and (max-width: $bp-large) {
            padding: 32px 20px;
          }
        }
        .journal__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;
    
          text-transform: unset;
          margin: 0 0 0 20px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px;

          @media only screen and (max-width: $bp-medium) {
            margin: 0 0 42px 20px;
          }
        }
      }

      .journal__top-right {
        display: flex;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          flex-direction: column;
          height: auto;
        }

        .journal__right-top-left {
          position: relative;

          width: 50%;
          height: 100%;

          @media only screen and (max-width: $bp-medium) {
            height: auto;
            width: 100%;
          }

          .journal__background {
            width: 100%;
            height: 50%;
            object-fit: cover;
            display: block;

            @media only screen and (max-width: $bp-small) {
           
            }
          }
        }

        .journal__right-top-right {
          width: 50%;
          position: relative;
          text-align: center;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
            height: auto;
          }

          &:before {
            content: "";
            display: block;
            width: calc(100% + 200px);
            height: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url("../../assets/images/journal-section1.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            @media only screen and (max-width: $bp-medium) {
              display: none;
            }
          }
          &:after {
            content: "";
            display: block;
            width: calc(100% + 200px);
            height: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            background: url("../../assets/images/journal-section.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            @media only screen and (max-width: $bp-medium) {
              display: none;
            }
          }

          .journal__background {
            width: 100%;
            height: auto;
            object-fit: cover;
            display: none;

            @media only screen and (max-width: $bp-medium) {
              display: block;
            }
            @media only screen and (max-width: $bp-small) {
              width: auto;
              height: 100vw;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
