@import "../../assets/scss/base.scss";

.buildings-banner__label {
  width: 220px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.16);

  position: absolute;
  z-index: 3;
  padding:20px;
  box-sizing: border-box;

  @media only screen and (max-width: $bp-medium) {
    display: none;
  }

  .buildings-banner__label-title {
    font-family: "montserrat-bold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    text-align: center;
    padding: 0 0 6px 0;
    color: #35363a;
    text-transform: uppercase;
    text-align: center;
  }

  .buildings-banner__label-text {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    text-align: center;
    padding: 0;
    color: #35363a;
  }
}
