@import "../../assets/scss/mixins";
@import "../../assets/scss/base";

.lp-contact {
  position: relative;
  background-color: rgba(193, 198, 200, 0.1);
  z-index: 1;
  padding: 0 0 118px 0;

  @media only screen and (max-width: $bp-medium) {
    color: #000;
    padding: 0;
  }
  @media only screen and (max-width: $bp-small) {
  }

  .contact__container {
    @media only screen and (max-width: $bp-medium) {
      flex-direction: column-reverse;
    }

    .contact__info-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;

      @media only screen and (max-width: $bp-large) {
        padding: 0 20px;
      }
      @media only screen and (max-width: $bp-medium) {
        width: 100%;
        padding: 48px 20px;
        height: auto;
        background-repeat: no-repeat;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-color: rgba(0, 0, 0, 0.8);
        background-image: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(0, 0, 0, 0.8)),
            color-stop(100%, rgba(0, 0, 0, 0.8))
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: -o-linear-gradient(
            top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: -ms-linear-gradient(
            top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% + 400px);
        height: calc(100% + 118px);
        display: block;
        background-repeat: no-repeat;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-color: rgba(0, 0, 0, 0.8);
        background-image: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(0, 0, 0, 0.8)),
            color-stop(100%, rgba(0, 0, 0, 0.8))
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: -o-linear-gradient(
            top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: -ms-linear-gradient(
            top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url("../../assets/images/contact-section.jpg");

        @media only screen and (max-width: $bp-medium) {
          display: none;
        }
      }

      .contact__subtitle {
        font-family: "montserrat-medium", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #fff;
        margin: 0;
        padding: 0 0 32px 0;
        max-width: 340px;

        @media only screen and (max-width: $bp-medium) {
          max-width: 100%;
        }
      }
      .contact__info {
        text-align: justify;
        display: block;
        font-family: "montserrat", sans-serif;
        font-size: 12px;
        line-height: 18px;
        color: #fff;
        box-sizing: border-box;
        max-width: 340px;
        padding: 12px 0 0 0;
        margin: 0;

        @media only screen and (max-width: $bp-medium) {
          max-width: 100%;
        }
      }

      .contact__text {
        font-family: "montserrat", sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        margin: 0;
        padding: 0 0 20px 0;

        &:first-of-type {
          padding: 0 0 32px 0;
        }

        span {
          display: block;
        }
        a {
          display: block;
          text-decoration: none;
          color: #fff;
        }
      }
    }

    .contact__form-box {
      padding: 50px 0 0 50px;
      box-sizing: border-box;

      @media only screen and (max-width: $bp-large) {
        padding: 50px 20px 0 20px;
      }
      @media only screen and (max-width: $bp-medium) {
        padding: 30px 20px 48px 20px;
      }

      .contact__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 42px;
        line-height: 52px;
        padding: 0 0 47px 0;
        color: #35363a;

        @media only screen and (max-width: $bp-medium) {
          padding: 0 0 20px 0;
          text-align: center;
        }
        @media only screen and (max-width: $bp-small) {
          font-size: 28px;
          line-height: 34px;
        }
      }
      .contact__subtitle {
        font-family: "montserrat-medium", sans-serif;
        color: #b79c66;
        font-size: 28px;
        line-height: 34px;
        padding-bottom: 20px;

        @media only screen and (max-width: $bp-medium) {
          text-align: center;
          padding-bottom: 15px;
        }

        @media only screen and (max-width: $bp-small) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }
}
