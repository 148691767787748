@import "../../assets/scss/base.scss";

.lp-area {
  padding: 50px 0;

  .area__container {
    .area__title {
      text-align: center;
      color: #141313;
      text-transform: uppercase;
      font-size: 38px;
      line-height: 47px;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px;
      }
      @media only screen and (max-width: $bp-small) {
        font-size: 28px;
        line-height: 34px;
      }
    }
    .area__icon {
      height: 12px;
      width: 40px;
      object-fit: contain;
      display: block;
      margin: 25px auto;
      color: #dfb452;
    }

    .area__main {
      max-width: 780px;
      margin: 0 auto;

      .area__text {
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #141313;
        padding: 10px;
        text-align: center;

        @media only screen and (max-width: $bp-medium) {
          padding: 0 20px;
        }
      }
      .area__left {
        .area__text {
        }
      }
      .area__right {
        .area__text {
        }
      }
    }
  }
}
