@import "../../assets/scss/base.scss";

.lp-localization-area {
  margin: 50px 0;
  height: 600px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: $bp-medium) {
    height: auto;
  }

  &::after {
    content: "";
    display: block;
    background: url("../../assets/images/dluga15-exterior-ujecie6-01.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 690px;
    height: 500px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @media only screen and (min-width: 1460px) {
      width: 50%;
    }
    @media only screen and (max-width: 1350px) {
      width: 50%;
    }
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }

  .localization-area__container {
    justify-content: flex-end;
    height: 100%;

    @media only screen and (max-width: $bp-medium) {
      flex-direction: column-reverse;
      justify-content: flex-end;
    }

    .localization-area__left {
      .localization-area__image {
        width: 100%;
        height: auto;
        object-fit: contain;
        display: none;

        @media only screen and (max-width: $bp-medium) {
          display: block;
        }
      }
    }

    .localization-area__right {
      height: 100%;
      background: #ededeb;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        padding: 20px 0;
      }
      &::before {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 120px;
        background-color: #ededeb;
        left: -100px;
        top: 0;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background-color: #ededeb;
        right: -99%;
        top: 0;
      }
      .localization-area__title {
        color: #141313;

        padding: 0 40px 50px 40px;

        @media only screen and (max-width: $bp-medium) {
          padding: 20px;
        }

        @media only screen and (max-width: $bp-small) {
          padding: 20px 20px 10px 20px;
        }
      }

      .localization-area__text {
        padding: 0 40px;
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #141313;

        @media only screen and (max-width: $bp-medium) {
          padding: 0 20px;
        }
      }
    }
  }
}
