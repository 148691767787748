@import "../../assets/scss/base.scss";

.building__label {
  width: 206px;
  background-color: #35363a;
  position: absolute;
  z-index: 3;
  padding: 22px 32px;
  box-sizing: border-box;
  left: 62%;

  top: calc(20% + 70px);
  @media only screen and (max-width: 1600px) {
    top: calc(16% + 70px);
  }
  @media only screen and (max-width: 1450px) {
    top: calc(10% + 70px);
  }
  @media only screen and (max-width: $bp-large) {
    top: calc(6% + 70px);
    width: 160px;
    padding: 15px 20px;
  }

  @media only screen and (max-width: $bp-medium) {
    width: 66px;
    padding: 8px 11px;
    left: 64%;

    top: calc(30% + 28px);
  }
  @media only screen and (max-width: 850px) {
    top: calc(25% + 28px);
  }
  @media only screen and (max-width: $bp-small) {
    left: 60%;
    top: calc(15% + 28px);
  }

  &.t {
    top: 20%;
    left: 33%;

    @media only screen and (max-width: 1600px) {
      top: 16%;
    }
    @media only screen and (max-width: 1450px) {
      top: 10%;
    }
    @media only screen and (max-width: $bp-large) {
      top: 6%;
      width: 160px;
      padding: 15px 20px;
    }
    @media only screen and (max-width: $bp-medium) {
      width: 66px;
      padding: 8px 11px;
      left: 36%;
      top: 30%;
    }
    @media only screen and (max-width: 850px) {
      top: 25%;
    }

    @media only screen and (max-width: $bp-small) {
      left: 28%;
      top: 15%;
    }
  }

  .building__label-text {
    font-family: "montserrat", sans-serif;
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    text-align: center;
    padding: 0;
    color: #fff;

    span {
      font-family: "montserrat-bold", sans-serif;
    }

    @media only screen and (max-width: $bp-large) {
      font-size: 16px;
      line-height: 20px;
    }

    @media only screen and (max-width: $bp-medium) {
      font-size: 8px;
      line-height: 12px;
    }
  }
}
