@font-face {
  font-family: "montserrat-bold";
  src: url("../fonts/montserrat-bold-webfont.eot");
  src: url("../fonts/montserrat-bold-webfont.eot") format("embedded-opentype"),
    url("../fonts/Montserrat-Bold.ttf") format("truetype"),
    url("../fonts/montserrat-bold-webfont.woff") format("woff"),
    url("../fonts/montserrat-bold-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat-medium";
  src: url("../fonts/montserrat-medium-webfont.eot");
  src: url("../fonts/montserrat-medium-webfont.eot") format("embedded-opentype"),
    url("../fonts/Montserrat-Medium.ttf") format("truetype"),
    url("../fonts/montserrat-medium-webfont.woff") format("woff"),
    url("../fonts/montserrat-medium-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat-light";
  src: url("../fonts/montserrat-light-webfont.eot");
  src: url("../fonts/montserrat-light-webfont.eot") format("embedded-opentype"),
    url("../fonts/Montserrat-Light.ttf") format("truetype"),
    url("../fonts/montserrat-light-webfont.woff") format("woff"),
    url("../fonts/montserrat-light-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat";
  src: url("../fonts/montserrat-regular-webfont.eot");
  src: url("../fonts/montserrat-regular-webfont.eot")
      format("embedded-opentype"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype"),
    url("../fonts/montserrat-regular-webfont.woff") format("woff"),
    url("../fonts/montserrat-regular-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "merriweather-black";
  src: url("../fonts/merriweather-black-webfont.eot");
  src: url("../fonts/merriweather-black-webfont.eot")
      format("embedded-opentype"),
    url("../fonts/Merriweather-Black.ttf") format("truetype"),
    url("../fonts/merriweather-black-webfont.woff") format("woff"),
    url("../fonts/merriweather-black-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "merriweather-bold";
  src: url("../fonts/merriweather-bold-webfont.eot");
  src: url("../fonts/merriweather-bold-webfont.eot") format("embedded-opentype"),
    url("../fonts/Merriweather-Bold.ttf") format("truetype"),
    url("../fonts/merriweather-bold-webfont.woff") format("woff"),
    url("../fonts/merriweather-bold-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "merriweather-light";
  src: url("../fonts/merriweather-light-webfont.eot");
  src: url("../fonts/merriweather-light-webfont.eot")
      format("embedded-opentype"),
    url("../fonts/Merriweather-Light.ttf") format("truetype"),
    url("../fonts/merriweather-light-webfont.woff") format("woff"),
    url("../fonts/merriweather-light-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "merriweather";
  src: url("../fonts/merriweather-regular-webfont.eot");
  src: url("../fonts/merriweather-regular-webfont.eot")
      format("embedded-opentype"),
    url("../fonts/Merriweather-Regular.ttf") format("truetype"),
    url("../fonts/merriweather-regular-webfont.woff") format("woff"),
    url("../fonts/merriweather-regular-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
