@import "../../assets/scss/base.scss";

.lp-banner {
  width: 100%;
  max-width: 100vw;
  height: 62vw;
  max-height: 100vh;

  @media only screen and (max-width: 1400px) {
    width: 100vw;
    height: 100vh;
  }
  @media only screen and (max-width: $bp-small) {
    width: 100vw;
    min-height: 500px;
  }

  .banner__container {
    height: 100%;
    display: flex;
    align-items: flex-end;

    .banner__media {
      width: 100%;
      padding: 25px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .contact__icon-box {
        color: #fff;
        text-decoration: none;
      }
      .contact__icon {
        color: #fff;
        display: block;
      }
      .af-icon-scroll {
        font-size: 42px;
      }
    }
  }
}
