@import "../../assets/scss/base.scss";

.lp-centrum {
  width: 100%;
  max-width: 100vw;
  height: 640px;
  position: relative;

  @media only screen and (max-width: $bp-medium) {
    background: transparent;
    height: auto;
  }

  &:after {
    content: "";
    display: block;
    width: 67%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    background: url("../../assets/images/space-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 25%;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }

  .centrum__container {
    height: 100%;

    @media only screen and (max-width: $bp-medium) {
      flex-direction: column;
    }

    .centrum__left {
      background-color: #141313;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        width: 100%;
        padding-bottom: 42px;
      }

      &:before {
        content: "";
        display: block;
        min-width: 100%;
        width: calc(100% + 200px);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #141313;
        transform: translateX(-99%);

        @media only screen and (max-width: $bp-medium) {
          display: none;
        }
      }
      &:after {
        content: "";
        display: block;
        width:48px;
        height: 48px;
        position: absolute;
        top: 50%;
        right: -5px;
        background-color: #141313;

        transform: rotate(45deg) translateY(-50%);

        @media only screen and (max-width: $bp-medium) {
          display: none;
        }
      }

      .centrum__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 38px;
        line-height: 47px;
        color: #fff;
        padding: 0 20px;

        @media only screen and (max-width: $bp-medium) {
          padding: 42px 20px 0 20px;
        }

        @media only screen and (max-width: $bp-small) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      .centrum__text {
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #fff;
        padding: 32px 20px 32px 20px;
        max-width: 380px;

        @media only screen and (max-width: $bp-medium) {
          padding: 32px 20px 32px 20px;
        }
      }
      .centrum__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 47px;
        line-height: 47px;
        width: 221px;
      
        text-transform: unset;
        margin: 0 0 0 20px;
        font-family: "montserrat-medium", sans-serif;
        font-size: 16px;
      }
    }

    .centrum__right {
      display: none;

      @media only screen and (max-width: $bp-medium) {
        display: block;
      }

      .centrum__background {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}
