@import "../../assets/scss/base.scss";

.lp-privacy {
  padding: 100px 0 50px 0;

  @media only screen and (max-width:$bp-small){
    padding:150px 0 50px 0;
  }

  .title {
    margin: 0;
    padding: 40px 30px 10px 30px;
    text-align: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 20px 20px 10px 20px;
    }
  }
  .text {
    padding: 30px;

    @media only screen and (max-width: $bp-medium) {
      padding: 20px;
    }

    p {
      text-align: justify !important;
      font-size: 16px !important;
      font-family: "montserrat-light", sans-serif;
      line-height: 32px !important;
    }

    span {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 600;
      font-family: "meriweather", serif;
    }
  }
}
