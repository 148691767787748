@import "../../assets/scss/base.scss";

.lp-estate {
  padding: 50px 0;
  height: 700px;
  background: url("../../assets/images/estate.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  position: relative;

  @media only screen and (max-width: $bp-medium) {
    height: 450px;
  }
  @media only screen and (max-width: $bp-small) {
    height: 200px;
    margin:0;
  }

  .estate__button-left, .estate__button-right {
    position: absolute;
    width: 54px;
    height: 54px;
    transform: translateY(-50%);
    background: #b79c66;
    border: 1px solid #b79c66;
    box-sizing: border-box;

    @media only screen and (max-width:$bp-small){
      width: 34px;
      height: 34px;
    }

    span{
      color:#fff;
      font-size:14px;
      @media only screen and (max-width:$bp-small){
       font-size: 12px;
      }
    }


  }

  .estate__button-left{
    left: 0px;
    top: 50%;
  }
  .estate__button-right{
    right: 0px;
    top: 50%;
  }

  .estate__container {
  }
}
