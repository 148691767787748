@import "../../assets/scss/base.scss";

.lp-about {
  padding: 50px 0;

  .about__container {
    .about__title {
      text-align: center;
      color: #141313;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px;
      }
    }
    .about__icon {
      height: 12px;
      width: 40px;
      object-fit: contain;
      display: block;
      margin: 25px auto;
      color: #dfb452;
    }
    .about__text {
      font-family: "montserrat", sans-serif;
      font-size: 17px;
      line-height: 30px;
      text-align: center;
      color: #141313;
      max-width: 780px;
      margin: 0 auto;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px;
      }
    }
  }
}
