@import "../../assets/scss/base.scss";

.lp-detail {
  padding: 50px 0;

  @media only screen and (max-width:$bp-small){
    padding: 0;
  }


  .detail__container {
    .detail__main {
      max-width: 800px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
        max-width: 100%;
      }

      .detail__col {
        @media only screen and (max-width: $bp-medium) {
          text-align: center;
        }
      }

      .detail__image {
        padding: 10px 15px;
        position: relative;
        max-width: 380px;

        @media only screen and (max-width: $bp-medium) {
          margin: 0 auto;
        }
        @media only screen and (max-width: $bp-small) {
          max-width: 260px;
          padding: 10px;
        }

        &::before {
          border: 1px solid #b79c66;
          width: 380px;
          height: 488px;
          position: absolute;
          content: "";
          display: block;
          left: -25px;
          top: 40px;
          z-index: -1;

          @media only screen and (max-width: $bp-small) {
            width:260px;
            height: 334px;
            left: -10px;
            top: 30px;
          }
        }

        img, picture {
          display: block;
          width: 380px;
          height: 488px;
          object-fit: contain;

          @media only screen and (max-width: $bp-small) {
            width:260px;
            height: 334px;
          }
        }
      }
      .detail__description {
        padding: 10px 15px;

        @media only screen and (max-width: $bp-small) {
          padding: 10px 20px;
        }

        .detail__title {
          color: #141313;
          padding-bottom: 45px;
          font-size: 38px;
          line-height: 47px;

          @media only screen and (max-width: $bp-medium) {
            text-align: center;
            padding:30px 0;
          }
          @media only screen and (max-width: $bp-small) {
            font-size: 28px;
            line-height: 34px;
          }
        }

        .detail__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #141313;

          @media only screen and (max-width: $bp-medium) {
            text-align: center;
            max-width: 780px;
            margin:0 auto;
          }
        }
      }
    }
  }
}
