@import "../../assets/scss/mixins";
@import "../../assets/scss/base";
@import "../../assets/scss/colors";

.apartment-new-slick {
  width: 100%;
  max-width: 100%;

  .apartment-new-slick-child {
    width: calc(100% - 420px) !important;
    border: none;
    outline: none;

    @media only screen and (max-width: 850px) {
      width: 100% !important;
    }

    .apartment-new-slick__image {
      display: block;
      width: auto;
      height: 100%;
      max-height: 440px;
      object-fit: contain;
      margin: 0 auto;
      cursor: pointer;

      @media only screen and (max-width: $bp-medium) {
        max-height: 400px;
      }

      @media only screen and (max-width: $bp-small) {
        max-height: 250px;
      }
    }
  }

  .image__container-outside {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 901;

    .image__container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width:90%;
      max-width: 90vw;
      height:100%;
      max-height: 51vw;
      z-index: 1000;
      background-color: transparent;

      
      @media only screen and (max-width: $bp-medium) {
        width: 100%;
        max-width: 100vw;
        height:100%;
        max-height: 56vw;
      }

      .image__container-inside {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .gallery-button {
          z-index: 100;
          position: absolute;
          width: 54px;
          height: 54px;
          background: #141414;
          border: 0;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          @media only screen and (max-width: $bp-small) {
            width: 30px;
            height: 30px;
            font-size: 16px;
          }
          span {
            display: block;
            color: #fff;
          }
        }

        .gallery-button-left {
          bottom: 20px;
          left: calc(50% - 60px);

          @media only screen and (max-width: $bp-small) {
            left: calc(50% - 35px);
          }
        }
        .gallery-button-right {
          bottom: 20px;
          right: calc(50% - 60px);
          background: #ffffff;
          span {
            color: #141414;
          }
          @media only screen and (max-width: $bp-small) {
            right: calc(50% - 35px);
          }
        }
        .gallery-button-close {
          position: absolute;
          top: 0;
          right: 0;
        }

        .gallery-image {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 0;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
  border: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff;
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: #fff;
  z-index: 1;
  opacity: 0.25;
  transition: ease-in-out 0.2s;

  &:hover {
    opacity: 0.75;
  }
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  border: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
  border: none;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[dir="rtl"] .slick-prev {
  right: 0;
  left: auto;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: 0;
}

.slick-prev {
  left: 0;
  display: block;
  content: "";
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/media/images/arrow-left.svg");
  width: 36px;
  height: 36px;

  @media only screen and (max-width: 610px) {
    width: 20px;
    height: 20px;
  }
}

.slick-next {
  right: 0;
  display: block;
  content: "";
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/media/images/arrow-right.svg");
  width: 36px;
  height: 36px;

  @media only screen and (max-width: 610px) {
    width: 20px;
    height: 20px;
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  top: 0;
  left: 108px;
  display: flex;
  flex-direction: column;
  width: 120px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  height: 100%;

  @media only screen and (max-width: 1250px) {
    left: 40px;
  }
  @media only screen and (max-width: 850px) {
    position: relative;
    top: unset;
    flex-direction: row;
    left: unset;
    width: auto;
    justify-content: center;
  }
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 120px;
  height: calc(100% / 5 - 10px);
  margin: 5px 0;
  padding: 0;
  cursor: pointer;
  box-sizing: border-box;

  @media only screen and (max-width: 850px) {
    margin: 0 5px;
    width: calc(100% / 5 - 10px);
    max-width: 120px;
    height: 80px;
  }
  @media only screen and (max-width: 610px) {
    height: 50px;
  }
  @media only screen and (max-width: 400px) {
    height: 40px;
  }
}

.custom-paging_item {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  position: relative;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  border: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
  border: none;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  content: "";
  position: absolute;
  background-color: #000000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  opacity: 0.25;
  transition: ease-in-out 0.2s;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
