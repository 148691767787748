@import "../../assets/scss/base.scss";

.lp-investment {
  width: 100%;
  max-width: 100vw;
  height: 1280px;
  position: relative;
overflow:hidden;
  @media only screen and (max-width: $bp-medium) {
    background: transparent;
    height: auto;
  }

  &:after {
    content: "";
    display: block;
    width: 69%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    background: url("../../assets/images/investment-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }

  .investment__container {
    height: 100%;

    .investment__top {
      display: flex;
      justify-content: flex-end;
      height: 50%;
      z-index:1;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column-reverse;
      }

      .investment__top-left {
        display: none;
        width: 100%;

        @media only screen and (max-width: $bp-medium) {
          display: block;
        }
        .investment__background {
          width: 100%;
          height: auto;
          object-fit: contain;

          @media only screen and (max-width: $bp-small) {
            width: 100%;
            height: 100vw;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      .investment__top-right {
        background-color: #ffffff;
        height: 100%;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: $bp-medium) {
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          min-width: 100%;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          transform: translateX(99%);

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .investment__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px 0 115px;

          @media only screen and (max-width: $bp-large) {
            padding: 42px 20px 0 20px;
          }

          @media only screen and (max-width: $bp-small) {
            font-size: 28px;
            line-height: 34px;
          }
        }

        .investment__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #141313;
          padding: 32px 20px 32px 115px;
          max-width: 380px;

          @media only screen and (max-width: $bp-large) {
            padding: 32px 20px 32px 20px;
          }
        }
        .investment__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;
         
          text-transform: unset;
          margin: 0 0 0 115px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px;

          @media only screen and (max-width: $bp-large) {
            margin: 0 0 0 20px;
          }
          @media only screen and (max-width: $bp-medium) {
            margin: 0 0 42px 20px;
          }
        }
      }
    }

    .investment__bottom {
      height: 50%;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column;
      }

      .investment__bottom-left {
        display: flex;
        height: 100%;
        z-index: 1;

        @media only screen and (max-width: $bp-medium) {
          flex-direction: column;
          height: auto;
        }

        .investment__left-bottom-left {
          width: 100%;
          background-color: #141313;

          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
          }

          &:before {
            content: "";
            display: block;
            min-width: 100%;
            width: calc(100% + 200px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #141313;
            transform: translateX(-99%);

            @media only screen and (max-width: $bp-medium) {
              display: none;
            }
          }

          .investment__title {
            font-family: "montserrat-bold", sans-serif;
            font-size: 38px;
            line-height: 47px;
            color: #fff;
            padding: 0 20px;

            @media only screen and (max-width: $bp-large) {
              padding: 42px 20px 0 20px;
            }

            @media only screen and (max-width: $bp-small) {
              font-size: 28px;
              line-height: 34px;
            }
          }
          .investment__text {
            font-family: "montserrat", sans-serif;
            font-size: 17px;
            line-height: 30px;
            color: #fff;
            padding: 32px 20px;
            max-width: 380px;
          }
          .investment__button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 47px;
            line-height: 47px;
            width: 221px;
          
            text-transform: unset;
            margin: 0 0 0 20px;
            font-family: "montserrat-medium", sans-serif;
            font-size: 16px;

       
            @media only screen and (max-width: $bp-medium) {
              margin: 0 0 42px 20px;
            }
          }
        }
      }

      .investment__bottom-right {
        position: relative;
        height: 100%;
        z-index:-1;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

        .investment__background {
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
            height: auto;
            object-fit: contain;
            position: relative;
            top:unset;
            left:unset;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }
    }
  }
}
