@import "../../assets/scss/base.scss";

.lp-floor-view {
  padding: 50px 0 100px 0;
  height: 780px;
  box-sizing: border-box;

  @media only screen and (max-width: $bp-medium) {
    height: auto;
    min-height: 720px;
    padding: 50px 0 0 0;
  }
  @media only screen and (max-width: $bp-small) {
    padding: 25px 0 0 0;
  }

  .floor-view__container {
    height: 100%;

    .floor-view__title {
      line-height: 40px;
      text-align: center;
      color: #0f0e0f;
      padding: 0 0 35px 0;
      font-family: "montserrat-medium", sans-serif;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px 20px 20px;
      }
      @media only screen and (max-width: $bp-small) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .floor-view__main {
      align-items: flex-start;
      height: 100%;

      @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
      }

      .floor-view__left {
        @media only screen and (max-width: $bp-medium) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @media only screen and (max-width: 800px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .floor-view__box {
          width: 100%;
          display: flex;
          padding: 40px 0;
          box-sizing: border-box;

          @media only screen and (max-width: $bp-large) {
            padding: 20px;
          }

          @media only screen and (max-width: $bp-medium) {
            flex-direction: column;
          }

          @media only screen and (max-width: $bp-small) {
            padding: 0 20px;
          }
          @media only screen and (max-width: 350px) {
            padding: 0 15px;
          }

          .box-select {
            display: none;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            border-bottom: 1px solid #d7d7d7;
            border-top: 1px solid #d7d7d7;
            margin: 0;

            @media only screen and (max-width: $bp-medium) {
              display: flex;
            }

            .box-select__text {
              color: #35363a;
              font-family: "montserrat-medium", sans-serif;
              font-size: 16px;
              line-height: 19px;
              padding: 0;
              margin: 0;
            }
            .box-select__icon {
              color: #35363a;
              font-size: 21px;
            }
          }

          .box {
            margin-right: 60px;

            &:first-of-type {
              margin-right: 40px;
              @media only screen and (max-width: $bp-medium) {
                margin: 0;
              }
            }

            &.active {
              display: block;
            }

            @media only screen and (max-width: $bp-medium) {
              margin: 0;
              padding: 15px 0;
              display: none;
            }

            .text {
              font-family: "montserrat-bold", sans-serif;
              font-size: 13px;
              line-height: 17px;
              color: #35363a;
              text-transform: uppercase;
              padding: 0 0 7px 0;
              margin: 0;

              @media only screen and (max-width: $bp-medium) {
                padding: 0 0 10px 0;
              }
            }

            .box-choose {
              display: flex;
              flex-direction: column;
              align-items: center;

              @media only screen and (max-width: $bp-medium) {
                flex-direction: row;
              }

              .choose-building__button {
                width: 60px;
                height: 60px;
                background: transparent;
                outline: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "montserrat-medium", sans-serif;
                font-size: 28px;
                line-height: 60px;
                color: #35363a;
                border: 0;
                margin: 5px 0;

                &.active {
                  background-color: #af9b69;
                  color: #fff;
                }
                @media only screen and (max-width: $bp-medium) {
                  margin: 5px 16px 5px 0;
                }
                @media only screen and (max-width: 350px) {
                  margin: 5px 5px 5px 0;
                }
              }

              .choose-storey__button {
                width: 140px;
                height: 60px;
                background: transparent;
                outline: 0;
                font-family: "montserrat-medium", sans-serif;
                font-size: 16px;
                color: #35363a;
                border: 0;
                margin: 5px 0;
                padding: 10px 8px;
                box-sizing: border-box;
                text-align: left;

                &.active {
                  background-color: #f9f9f9;
                  border-left: 3px solid #af9b69;
                }
                @media only screen and (max-width: $bp-medium) {
                  margin: 5px 16px 5px 0;
                }
                @media only screen and (max-width: 350px) {
                  margin: 5px 5px 5px 0;
                }

                span {
                  display: block;
                  font-size: 14px;
                  font-family: "montserrat", sans-serif;
                }
              }

              .choose-flats__button {
                width: 140px;
                height: 40px;
                background: transparent;
                outline: 0;
                font-family: "montserrat-medium", sans-serif;
                font-size: 16px;
                color: #35363a;
                border: 0;
                margin: 5px 0;
                padding: 10px 8px;
                box-sizing: border-box;
                text-align: left;

                @media only screen and (max-width: $bp-medium) {
                  margin: 5px 16px 5px 0;
                }
                @media only screen and (max-width: 350px) {
                  margin: 5px 5px 5px 0;
                }

                &.active {
                  background-color: #f9f9f9;
                  border-left: 3px solid #af9b69;

                  &.m1 {
                    border-right: 17px solid #f9f093;
                  }
                  &.m2 {
                    border-right: 17px solid #f8b67b;
                  }
                  &.m1_ {
                    border-right: 17px solid #f3978c;
                  }
                  &.m3 {
                    border-right: 17px solid #ed7560;
                  }
                  &.m4 {
                    border-right: 17px solid #e8452f;
                  }
                  &.u {
                    border-right: 17px solid #bec0e2;
                  }
                }
              }
            }
          }
        }
      }

      .floor-view__right {
        display: flex;
        height: 100%;
        position: relative;
        justify-content: center;

        @media only screen and (max-width: $bp-medium) {
          box-sizing: border-box;
          padding: 20px;
          flex-direction: column;
          min-height: 560px;
          justify-content: flex-end;
        }

        .floor-mapper {
          height: 100%;
          width: calc(100% - 250px);
          opacity: 0;
          z-index: -1;

          &.active {
            opacity: 1;
            z-index: 0;
          }

          @media only screen and (max-width: $bp-large) {
            width: calc(100% - 300px);
          }

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
            margin: 0 auto;
          }

          svg {
            max-width: 100%;
            object-fit: contain;

            @media only screen and (max-width: $bp-medium) {
              min-height: 430px;
            }
          }
        }
        .floor-loader {
          height: 100%;
          position: absolute;
          top: 40%;
          transform: translate(-50%, -50%);
          left: calc((100% - 250px) / 2);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;

          @media only screen and (max-width: $bp-large) {
            left: calc((100% - 300px) / 2);
          }

          @media only screen and (max-width: $bp-medium) {
            left: 50%;
            min-height: 430px;
          }
        }

        img {
          max-width: 100%;
          width: 100%;
          max-height: 100%;
          object-fit: contain;
        }

        .floor-view__info {
          width: 250px;
          padding-bottom: 100px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
            max-width: 320px;
            display: block;
            flex-direction: unset;
            justify-content: unset;
            padding: 0;
          }

          .floor-view__status {
            width: 100%;
            box-sizing: border-box;
            padding: 70px 0 0 0;

            @media only screen and (max-width: $bp-medium) {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              padding: 25px 0 0 0;
            }

            .status {
              display: flex;
              align-items: center;
              padding: 5px 0;
              width: 100%;
              box-sizing: border-box;

              @media only screen and (max-width: $bp-medium) {
                width: 50%;
                padding: 5px 0 10px 0;
              }

              .status-item {
                width: 16px;
                height: 16px;
                display: block;
                box-sizing: border-box;

                &.free {
                  border: 1px solid #c1c6c8;
                  background: transparent;
                }
                &.reserved {
                  background: #c0c6c7;
                  border: 1px solid #c0c6c7;
                }
                &.sold {
                  background: #020202;
                  border: 1px solid #020202;
                  opacity: 0.7;
                }
                &.current {
                  border: 3px solid green;
                }
              }
              .status-text {
                font-family: "montserrat", sans-serif;
                font-size: 15px;
                line-height: 17px;
                color: #35363a;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
