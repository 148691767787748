@import "../../assets/scss/base.scss";

.lp-map-section {
  position: relative;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin: 30px 0;
  overflow: hidden;

  @media only screen and (max-width: $bp-small) {
    margin: 0;
  }

  .map-section__box {
    width: 100%;
    height: 540px;
    margin-top: 50px;

    @media only screen and (max-width: $bp-medium) {
      height: 400px;
    }
  }

  .map-section__container {
    justify-content: flex-end;

    .map-section__title {
      text-align: center;
      color: #141313;
      padding: 0 20px 35px 20px;
    }

    .map-section__top {

      @media only screen and (max-width:$bp-medium){
        text-align: center;
        padding:0 20px;
      }

      .map-section__select {
        height: 47px;
        line-height: 47px;
        width: 221px;
        text-transform: unset;
        font-family: "montserrat-medium", sans-serif;
        font-size: 16px;
        background-color: #b79c66;
        border: 1px solid #b79c66;
        margin-top: 35px;
        cursor: pointer;

        @media only screen and (max-width:$bp-medium){
          margin:35px auto 0 auto;
        }
      }

      .map-section__list {
        margin: 0;
        padding: 0;
        list-style: none;
        flex-wrap: wrap;
        align-items: center;

        .map-section__list-item {
          font-size: 16px;
          line-height: 22px;
          color: #707070;
          font-family: "montserrat", sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding: 10px 0;
          cursor: pointer;

          &.active {
            color: #000000;
          }

          @media only screen and (max-width:$bp-small){
            font-size: 14px;
            line-height: 18px;
          }

          .icon-box {
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            span {
              display: block;
              font-size: 22px;
              line-height: 22px;
              color: #707070;

              &.active {
                color: #b79c66;
              }
            }
            .af-icon-education {
              font-size: 12px;
              line-height: 12px;
            }
            .af-icon-healt-serive,
            .af-icon-culture,
            .af-icon-bank {
              font-size: 17px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}
