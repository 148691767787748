@import "../../assets/scss/base";

.lp-contact-form {
  position: relative;
  .form__sent-box {
    background: #b79c66;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 90%;
    height: auto;
    min-height: 120px;
    max-width: 320px;
    padding: 20px;
    border-radius: 3px;
    .form__sent {
      position: relative;
      text-align: center;
      .form__sent-info {
        font-size: 18px;
        font-family: "montserrat-bold", sans-serif;
        padding-top: 40px;
      }
      .form__sent-close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .contact__form {
    .contact-form__inputs {
      flex-wrap: wrap;
      .contact-form__input-box {
      }
      .contact-form__textarea {
        textarea {
        }
      }

      .contact-form__radio {
        padding-bottom: 45px;
        @media only screen and (max-width: $bp-small) {
          padding-bottom: 25px;

        }
        .radio__text {
          padding: 32px 0 20px;
          font-size: 16px;
          line-height: 19px;
          color: #35363a;
          font-family: "montserrat-medium", sans-serif;

          @media only screen and (max-width: $bp-medium) {
            padding: 20px 0 20px 0;
          }
        }
        .radio__box {
          display: flex;
          justify-content: space-between;
          max-width: 750px;

          @media only screen and (max-width: $bp-small) {
            flex-wrap: wrap;
            max-width: 100%;
            justify-content: flex-start;
          }
        }
      }
      .contact-form__agreement-box {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
      }
    }

    .required-info {
      display: block;
      font-family: "montserrat-light", sans-serif;
      line-height: 15px;
      font-size: 12px;
      padding-left: 35px;
    }

    .form__button-box {
      @media only screen and (max-width: $bp-medium) {
        text-align: center;
      }
      .form__button {
        width: 264px;
        height: 56px;
        background: #b79c66;
        border: 1px solid #b79c66;
        margin-top: 50px;
        font-size: 16px;
        line-height: 56px;
        font-family: "montserrat-medium", sans-serif;

        @media only screen and (max-width: $bp-medium) {
          margin: 30px auto 0 auto;
        }
      }
    }

    textarea,
    input[type="email"],
    input[type="text"] {
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      height: 56px;
      line-height: 56px;
      color: #35363a;
      padding: 0 0 0 8px;
      border: none;
      border: 1px solid #d7d7d7;
      background: #fff;
      outline: 0;
      transition: 0.3s;
      display: block;
      width: calc(100% - 58px);
      margin: 10px 0;
      box-sizing: border-box;

      @media only screen and (max-width: $bp-medium) {
        max-width: 100%;
        width: 100%;
        margin: 10px auto;
      }
      @media only screen and (max-width: $bp-small) {
        font-size: 12px;
      }
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #35363a;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #35363a;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #35363a;
      }

      &:focus {
        border: 1px solid #000000;
      }
      &.error {
        border: 1px solid #ff0000;
      }
      &:disabled {
        color: #658385;
      }
    }
    textarea {
      color: #35363a;
      border: 1px solid #d7d7d7;
      background: #fff;
      width: calc(100% - 58px);
      height: 112px;
      padding: 0 0 0 8px;
      margin: 10px 0;

      @media only screen and (max-width: $bp-medium) {
        max-width: 100%;
        width: 100%;
        margin: 10px auto;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #35363a;
        opacity: 1; /* Firefox */
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #35363a;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #35363a;
      }
    }

    .radio__wrapper{
      @media only screen and (max-width: $bp-small) {
        width:50%;
        padding:10px 0 20px 0;
      }
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label {
      position: relative;
      display: block;
      padding-left: 35px;
      color: #35363a;
      font-size: 16px;
      font-family: "montserrat", sans-serif;
    }

    input[type="radio"] + label::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid #707070;
      cursor: pointer;
      transition: 0.3s;
    }

    input[type="radio"]:checked + label::before {
      background: #b79c66;
      border: 1px solid #b79c66;
    }

    input[type="checkbox"] {
      display: none;
    }

    .contact-form__privacy-info {
      color: #35363a;
      font-size: 16px;
      font-family: "montserrat", sans-serif;
      line-height: 23px;
      margin: 0;
      padding: 0 0 25px 0;
    }

    .agreement-span {
      display: inline-block;
      font-family: "montserrat", sans-serif;
      line-height: 22px;
      font-size: 14px;
      margin-left: 12px;
      color: #35363a;
      max-width: 80%;
    }

    input[type="checkbox"] + label {
      width: 24px;
      height: 24px;
      border: 1px solid #707070;
      cursor: pointer;
      transition: 0.3s;
      text-align: center;

      span {
        opacity: 0;
        color: #fff;
        line-height: 24px;
      }
    }
    input[type="checkbox"]:checked + label {
      background: #b79c66;
      border: 1px solid #b79c66;
      span {
        opacity: 1;
      }
    }

    input[type="checkbox"].error + label {
      border-color: #ff0000;
    }
  }
}
