@import "../../assets/scss/base.scss";

.lp-quality {
  margin: 50px 0;
  height: 650px;
  background: url("../../assets/images/quality-background.jpg");
  background-repeat: no-repeat;
  background-size: 60%;
  overflow: hidden;

  @media only screen and (max-width: $bp-medium) {
    background-size: cover;
  }

  .quality__container {
    justify-content: flex-end;
    height: 100%;

    @media only screen and (max-width: $bp-medium) {
      flex-direction: column;
      justify-content: flex-end;
    }

    .quality__right {
      height: 55%;
      background: rgba(20, 20, 20, 0.7);
      background: -moz-linear-gradient(
        left,
        rgba(20, 20, 20, 0.7) 0%,
        rgba(0, 0, 0, 0.9) 59%,
        rgba(20, 20, 20, 1) 81%,
        rgba(20, 20, 20, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(20, 20, 20, 0.7)),
        color-stop(59%, rgba(0, 0, 0, 0.9)),
        color-stop(81%, rgba(20, 20, 20, 1)),
        color-stop(100%, rgba(20, 20, 20, 1))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(20, 20, 20, 0.7) 0%,
        rgba(0, 0, 0, 0.9) 59%,
        rgba(20, 20, 20, 1) 81%,
        rgba(20, 20, 20, 1) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(20, 20, 20, 0.7) 0%,
        rgba(0, 0, 0, 0.9) 59%,
        rgba(20, 20, 20, 1) 81%,
        rgba(20, 20, 20, 1) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(20, 20, 20, 0.7) 0%,
        rgba(0, 0, 0, 0.9) 59%,
        rgba(20, 20, 20, 1) 81%,
        rgba(20, 20, 20, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(20, 20, 20, 0.7) 0%,
        rgba(0, 0, 0, 0.9) 59%,
        rgba(20, 20, 20, 1) 81%,
        rgba(20, 20, 20, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#141414', endColorstr='#141414', GradientType=1 );
      position: relative;
      @media only screen and (max-width: $bp-medium) {
        height: auto;
        padding: 20px 0;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background-color: #141414;
        right: -99%;
        top: 0;
      }
      .quality__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 38px;
        line-height: 47px;
        color: #ffffff;
        padding: 30px 40px 50px 40px;

        @media only screen and (max-width: $bp-medium) {
          padding: 20px;
        }

        @media only screen and (max-width: $bp-small) {
          padding: 20px 20px 10px 20px;
          font-size: 28px;
          line-height: 34px;
        }
      }

      .quality__text {
        display: flex;
        align-items: center;
        padding: 0 40px;

        @media only screen and (max-width: $bp-small) {
          padding: 0 20px;
        }

        .af-icon-list-item {
          font-size: 8px;
          letter-spacing: 0.28em;
          color: #b79c66;
        }
        .text {
          margin-left: 10px;
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #ffffff;
        }
      }
    }
  }
}
