@import "../../assets/scss/base.scss";

.floor-view__label {
  padding: 24px;
  height: auto;
  border: 1px solid #c0c6c7;
  width:180px;

  @media only screen and (max-width: $bp-medium) {
    display: none;
  }

  .floor-view__label-title {
    font-family: "montserrat-bold", sans-serif;
    font-size: 16px;
    line-height: 20px;

    margin: 0;
    text-align: center;
    padding: 0 0 15px 0;
    color: #35363a;
  }

  .floor-view__label-text {
    font-family: "montserrat-medium", sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding: 0 0 15px 0;
    margin: 0;
    color: #35363a;
    text-align: center;

    span {
      font-family: "montserrat", sans-serif;
      display: block;
      padding: 0 0 2px 0;
    }
  }
}
