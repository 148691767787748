@import "../../assets/scss/base.scss";

.lp-space {
  width: 100%;
  max-width: 100vw;
  height: 1280px;
  position: relative;
  overflow-x: hidden;

  @media only screen and (max-width: $bp-medium) {
    background: transparent;
    height: auto;
  }

  .space__container {
    height: 100%;

    .space__top {
      height: 50%;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column-reverse;
      }

      .space__top-left {
        position: relative;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: url("../../assets/images/space-new-background.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .space__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none;

          @media only screen and (max-width: $bp-medium) {
            display: block;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }

      .space__top-right {
        display: flex;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          flex-direction: column;
          height: auto;
        }

        .space__right-top-left {
          width: 50%;
          background-color: #fff;

          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
          }

          .space__title {
            font-family: "montserrat-bold", sans-serif;
            font-size: 38px;
            line-height: 47px;
            color: #141313;
            padding: 0 20px 0 80px;

            @media only screen and (max-width: $bp-large) {
              padding: 42px 20px 0 20px;
            }

            @media only screen and (max-width: $bp-small) {
              font-size: 28px;
              line-height: 34px;
            }
          }
          .space__text {
            font-family: "montserrat", sans-serif;
            font-size: 17px;
            line-height: 30px;
            color: #141313;
            padding: 32px 20px 32px 80px;
            max-width: 380px;

            @media only screen and (max-width: $bp-large) {
              padding: 32px 20px;
            }
          }
          .space__button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 47px;
            line-height: 47px;
            width: 221px;
            
            text-transform: unset;
            margin: 0 0 0 80px;
            font-family: "montserrat-medium", sans-serif;
            font-size: 16px;

            @media only screen and (max-width: $bp-large) {
              margin: 0 0 0 20px;
            }

            @media only screen and (max-width: $bp-medium) {
              margin: 0 0 42px 20px;
            }
          }
        }

        .space__right-top-right {
          width: 50%;
          position: relative;
          text-align: center;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
            height: auto;
          }
          @media only screen and (max-width: $bp-small) {
            margin-bottom: 50px;
          }

          &:before {
            content: "";
            display: block;
            width: 130%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url("../../assets/images/K2-P4-1.jpg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @media only screen and (max-width: 1400px) {
              width:115%;
            }
            @media only screen and (max-width: $bp-large) {
              width:100%;

            }
            @media only screen and (max-width: $bp-medium) {
              display: none;
            }
          }

          .space__background {
            width: 80%;
            height: auto;
            object-fit: contain;
            display: none;
            margin: 0 auto;

            @media only screen and (max-width: $bp-medium) {
              display: block;
            }
            @media only screen and (max-width: $bp-small) {
              width: 100%;
            }
          }
        }
      }
    }

    .space__bottom {
      height: 50%;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column-reverse;
      }

      .space__bottom-left {
        position: relative;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: url("../../assets/images/space2.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .space__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none;

          @media only screen and (max-width: $bp-medium) {
            display: block;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }

      .space__bottom-right {
        position: relative;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url("../../assets/images/space3.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .space__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none;

          @media only screen and (max-width: $bp-medium) {
            display: block;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }
    }
  }
}
