@import "../../assets/scss/base.scss";

.lp-region {
  width: 100%;
  max-width: 100vw;
  height: 1280px;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f8f8f8;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }


  @media only screen and (max-width: $bp-medium) {
    background: transparent;
    height: auto;
  }

  .region__container {
    height: 100%;

    .region__top {
      height: 50%;
      z-index:0;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column;
      }

      .region__top-left {
        position: relative;
        height: 100%;
        background: #f8f8f8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @media only screen and (max-width: $bp-medium) {
          height: auto;
        }

        .region__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px;

          @media only screen and (max-width: $bp-large) {
            padding: 42px 20px 0 20px;
          }

          @media only screen and (max-width: $bp-small) {
            font-size: 28px;
            line-height: 34px;
          }
        }
        .region__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #363636;
          padding: 32px 20px;
          max-width: 380px;

          @media only screen and (max-width: $bp-large) {
            padding: 32px 20px;
          }
        }
        .region__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;

          text-transform: unset;
          margin: 0 0 0 20px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px;

          @media only screen and (max-width: $bp-medium) {
            margin: 0 0 42px 20px;
          }
        }
      }

      .region__top-right {
        display: flex;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          flex-direction: column;
          height: auto;
        }

        .region__right-top-left {
          width: 50%;
          position: relative;
          text-align: center;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
            height: auto;
          }

          .region__background {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;

            @media only screen and (max-width: $bp-small) {
              width: auto;
              height: 100vw;
              object-fit: cover;
            }
          }
        }

        .region__right-top-right {
          display: flex;
          flex-wrap: wrap;
          background: #f8f8f8;
          position: relative;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 100%;

          @media only screen and (max-width: $bp-medium) {
            height: auto;
            width: 100%;
          }

          @media only screen and (max-width: $bp-small) {
            flex-direction: column;
            justify-content: center;
          }

          .region__box {
            padding: 25px 50px;
            box-sizing: border-box;
            max-width: 50%;
            height: fit-content;

            @media only screen and (max-width: $bp-small) {
              max-width: 80%;
            }

            .region__box-title {
              text-align: center;
              color: #b79c66;
              font-family: "montserrat-bold", sans-serif;
              font-size: 72px;
              line-height: 88px;
              padding: 0 0 15px 0;
              margin: 0;
            }
            .region__box-text {
              line-height: 24px;
              font-size: 16px;
              color: #363636;
              text-align: center;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }

    .region__bottom {
      height: 50%;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column-reverse;
      }

      .region__bottom-left {
        position: relative;
        height: 100%;
        z-index:-1;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

   
        .region__background-bigger {
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          object-fit: cover;

          @media only screen and (max-width: $bp-medium) {
            width: 100%;
            height: auto;
            object-fit: contain;
            position: relative;
            top:unset;
            left:unset;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }

      .region__bottom-right {
        position: relative;
        height: 100%;
        z-index:0;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url("../../assets/images/region2.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: left;

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .region__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none;

          @media only screen and (max-width: $bp-medium) {
            display: block;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }
    }
  }
}
