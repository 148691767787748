@import "../../assets/scss/base";

.lp-meeting-contact-form {
  position: relative;
  .form__sent-box {
    background: #b79c66;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 90%;
    height: auto;
    min-height: 120px;
    max-width: 320px;
    padding: 20px;
    border-radius: 3px;
    .form__sent {
      position: relative;
      text-align: center;
      .form__sent-info {
        font-size: 18px;
        font-family: "montserrat-bold", sans-serif;
        padding-top: 40px;
      }
      .form__sent-close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .contact__form {
    .meeting-contact-form__inputs {
      flex-wrap: wrap;
      align-items: flex-end;

      .meeting-contact-form__input-box,
      .meeting-contact-form__textarea {
        text-align: center;
      }

      .meeting-contact-form__radio {
        padding-bottom: 30px;
        max-width: 720px;
        margin: 0 auto;

        @media only screen and (max-width: $bp-small) {
          max-width: 90%;
        }

        .radio__text {
          font-family: "montserrat-bold", sans-serif;
          padding: 40px 0 20px;

          @media only screen and (max-width: $bp-medium) {
            padding: 20px 0 20px 0;
          }
        }
        .radio__box {
          display: flex;
          justify-content: space-between;
        }
      }
      .meeting-contact-form__agreement-box {
        max-width: 720px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding-bottom: 20px;

        @media only screen and (max-width: $bp-small) {
          max-width: 90%;
        }
      }
    }
    .required-info {
      max-width: 720px;
      margin: 0 auto;
      display: block;
      font-family: "montserrat-light", sans-serif;
      line-height: 15px;
      font-size: 12px;
      padding-left: 35px;

      @media only screen and (max-width: $bp-small) {
        max-width: 90%;
      }
    }

    .form__button-box {
      text-align: center;

      .form__button {
        width: 240px;
        height: 54px;
        background: #b79c66;
        border: 1px solid #b79c66;
        font-size: 16px;
        line-height: 54px;
        margin: 30px auto 0 auto;
      }
    }

    .textarea-label {
      display: block;
      width: 100%;
      text-align: left;
      font-family: "montserrat", sans-serif;
      font-size: 12px;
      line-height: 14px;
      color: #35363a;
      text-transform: uppercase;
      max-width: 480px;
      margin: 0 auto;
      padding-bottom: 8px;

      @media only screen and (max-width: $bp-medium) {
        padding-top: 32px;
      }
      @media only screen and (max-width: $bp-small) {
        max-width: 90%;
      }
    }

    textarea,
    input[type="email"],
    input[type="text"] {
      font-family: "montserrat-medium", sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 12px;
      color: #000000;
      padding: 32px 0 8px 0;
      border: none;
      border-bottom: 1px solid #000000;
      background: transparent;
      outline: 0;
      transition: 0.3s;
      display: block;
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      max-width: 480px;
      @media only screen and (max-width: $bp-small) {
        max-width: 90%;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #000000;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000000;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #000000;
      }

      &:focus {
        border-bottom: 1px solid #000000;
      }
      &.error {
        border-bottom: 1px solid #ff0000;
      }
      &:disabled {
        color: #658385;
      }
    }
    textarea {
      color: #b79c66;
      background: #ffffff;
      border: 0;
      height: 168px;
      max-width: 480px;
      margin: 0 auto;
      padding: 10px 5px;

      &:focus {
        border-bottom: 0;
      }
      @media only screen and (max-width: $bp-small) {
        max-width: 90%;
        height: 80px;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b79c66;
        opacity: 1; /* Firefox */
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b79c66;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b79c66;
      }
    }

    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label {
      position: relative;
      display: block;
      padding-left: 25px;
      font-size: 14px;

      @media only screen and (max-width: $bp-medium) {
        font-size: 12px;
        padding-left: 20px;
      }
    }

    input[type="radio"] + label::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;
      border: 1px solid #000000;
      cursor: pointer;
      transition: 0.3s;

      @media only screen and (max-width: $bp-medium) {
        width: 15px;
        height: 15px;
      }
    }

    input[type="radio"]:checked + label::before {
      background: #b79c66;
      border: 1px solid #b79c66;
    }

    input[type="checkbox"] {
      display: none;
    }

    .agreement-span {
      display: inline-block;
      font-family: "montserrat-light", sans-serif;
      line-height: 22px;
      font-size: 14px;
      margin-left: 15px;
    }

    input[type="checkbox"] + label {
      width: 17px;
      height: 17px;
      border: 1px solid #000000;
      cursor: pointer;
      transition: 0.3s;

      span {
        opacity: 0;
        color: #fff;
      }
    }
    input[type="checkbox"]:checked + label {
      background: #b79c66;
      border: 1px solid #b79c66;
      span {
        opacity: 1;
      }
    }

    input[type="checkbox"].error + label {
      border-color: #ff0000;
    }
  }
}
