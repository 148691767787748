@font-face {
    font-family: "icons";
    src: url("../fonts-icons/icons.eot?af0c19f7f4084b2623c3b802f19be396?#iefix") format("embedded-opentype"),
url("../fonts-icons/icons.woff2?af0c19f7f4084b2623c3b802f19be396") format("woff2"),
url("../fonts-icons/icons.woff?af0c19f7f4084b2623c3b802f19be396") format("woff"),
url("../fonts-icons/icons.ttf?af0c19f7f4084b2623c3b802f19be396") format("truetype"),
url("../fonts-icons/icons.svg?af0c19f7f4084b2623c3b802f19be396#icons") format("svg");
}

span[class^="af-icon-"]:before, span[class*=" af-icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.af-icon-3d:before {
    content: "\f101";
}
.af-icon-3d_2:before {
    content: "\f102";
}
.af-icon-accent:before {
    content: "\f103";
}
.af-icon-alpine:before {
    content: "\f104";
}
.af-icon-angle-down-solid:before {
    content: "\f105";
}
.af-icon-angle-left-solid:before {
    content: "\f106";
}
.af-icon-angle-right-solid:before {
    content: "\f107";
}
.af-icon-angle-up-solid:before {
    content: "\f108";
}
.af-icon-apartment-finish-line:before {
    content: "\f109";
}
.af-icon-arrow-bottom:before {
    content: "\f10a";
}
.af-icon-arrow-down:before {
    content: "\f10b";
}
.af-icon-arrow-left:before {
    content: "\f10c";
}
.af-icon-arrow-right:before {
    content: "\f10d";
}
.af-icon-arrow-top:before {
    content: "\f10e";
}
.af-icon-bank:before {
    content: "\f10f";
}
.af-icon-bars-solid:before {
    content: "\f110";
}
.af-icon-beauty:before {
    content: "\f111";
}
.af-icon-benefits-center:before {
    content: "\f112";
}
.af-icon-benefits-comfort:before {
    content: "\f113";
}
.af-icon-benefits-infrastructure:before {
    content: "\f114";
}
.af-icon-benefits-key:before {
    content: "\f115";
}
.af-icon-benefits-kids:before {
    content: "\f116";
}
.af-icon-benefits-nature:before {
    content: "\f117";
}
.af-icon-benefits-park:before {
    content: "\f118";
}
.af-icon-benefits-safety:before {
    content: "\f119";
}
.af-icon-bottom:before {
    content: "\f11a";
}
.af-icon-building:before {
    content: "\f11b";
}
.af-icon-button-left:before {
    content: "\f11c";
}
.af-icon-button-right:before {
    content: "\f11d";
}
.af-icon-camera:before {
    content: "\f11e";
}
.af-icon-church:before {
    content: "\f11f";
}
.af-icon-close:before {
    content: "\f120";
}
.af-icon-construction:before {
    content: "\f121";
}
.af-icon-culture:before {
    content: "\f122";
}
.af-icon-door:before {
    content: "\f123";
}
.af-icon-education:before {
    content: "\f124";
}
.af-icon-elevator:before {
    content: "\f125";
}
.af-icon-envelope:before {
    content: "\f126";
}
.af-icon-facebook:before {
    content: "\f127";
}
.af-icon-health-service:before {
    content: "\f128";
}
.af-icon-instagram:before {
    content: "\f129";
}
.af-icon-left:before {
    content: "\f12a";
}
.af-icon-list-item:before {
    content: "\f12b";
}
.af-icon-logo:before {
    content: "\f12c";
}
.af-icon-long-arrow-alt-left-solid:before {
    content: "\f12d";
}
.af-icon-long-arrow-alt-right-solid:before {
    content: "\f12e";
}
.af-icon-mail:before {
    content: "\f12f";
}
.af-icon-map:before {
    content: "\f130";
}
.af-icon-meeting-calculator:before {
    content: "\f131";
}
.af-icon-meeting-camera:before {
    content: "\f132";
}
.af-icon-meeting-chat:before {
    content: "\f133";
}
.af-icon-meeting-comfort:before {
    content: "\f134";
}
.af-icon-meeting-join:before {
    content: "\f135";
}
.af-icon-meeting-mail:before {
    content: "\f136";
}
.af-icon-meeting-pen:before {
    content: "\f137";
}
.af-icon-meeting-safety:before {
    content: "\f138";
}
.af-icon-meeting-time:before {
    content: "\f139";
}
.af-icon-menu:before {
    content: "\f13a";
}
.af-icon-minus-square:before {
    content: "\f13b";
}
.af-icon-nursery:before {
    content: "\f13c";
}
.af-icon-pdf:before {
    content: "\f13d";
}
.af-icon-pharmacy:before {
    content: "\f13e";
}
.af-icon-phone:before {
    content: "\f13f";
}
.af-icon-pin:before {
    content: "\f140";
}
.af-icon-playground:before {
    content: "\f141";
}
.af-icon-plus-square:before {
    content: "\f142";
}
.af-icon-pub:before {
    content: "\f143";
}
.af-icon-recreation:before {
    content: "\f144";
}
.af-icon-right:before {
    content: "\f145";
}
.af-icon-scroll:before {
    content: "\f146";
}
.af-icon-search:before {
    content: "\f147";
}
.af-icon-shop:before {
    content: "\f148";
}
.af-icon-system:before {
    content: "\f149";
}
.af-icon-table-arrow-down:before {
    content: "\f14a";
}
.af-icon-table-arrow-up:before {
    content: "\f14b";
}
.af-icon-tasks-solid:before {
    content: "\f14c";
}
.af-icon-thin-arrow-left:before {
    content: "\f14d";
}
.af-icon-thin-arrow-right:before {
    content: "\f14e";
}
.af-icon-tick:before {
    content: "\f14f";
}
.af-icon-times-solid:before {
    content: "\f150";
}
.af-icon-top:before {
    content: "\f151";
}
.af-icon-walls:before {
    content: "\f152";
}
.af-icon-window:before {
    content: "\f153";
}
.af-icon-youtube:before {
    content: "\f154";
}
