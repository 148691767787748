$bp-small: 610px;
$bp-medium: 1079px;
$bp-large: 1250px;

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box;
}

// new landing page container
.container-new {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (min-width: 1500px) {
    max-width: 80%;
  }
  @media only screen and (min-width: 1920px) {
    max-width: 1650px;
  }
}

.row {
  display: flex;
}

.col-1 {
  width: 8.33%;
}
.col-133p {
  width: 13.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-3-5 {
  width: 29.16%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

@media only screen and (max-width: $bp-large) {
  .col-l-1 {
    width: 8.33%;
  }
  .col-l-2 {
    width: 16.66%;
  }
  .col-l-3 {
    width: 25%;
  }
  .col-l-4 {
    width: 33.33%;
  }
  .col-l-5 {
    width: 41.66%;
  }
  .col-l-6 {
    width: 50%;
  }
  .col-l-7 {
    width: 58.33%;
  }
  .col-l-8 {
    width: 66.66%;
  }
  .col-l-9 {
    width: 75%;
  }
  .col-l-10 {
    width: 83.33%;
  }
  .col-l-11 {
    width: 91.66%;
  }
  .col-l-12 {
    width: 100%;
  }
}

@media only screen and (max-width: $bp-medium) {
  .col-m-1 {
    width: 8.33%;
  }
  .col-m-2 {
    width: 16.66%;
  }
  .col-m-3 {
    width: 25%;
  }
  .col-m-4 {
    width: 33.33%;
  }
  .col-m-5 {
    width: 41.66%;
  }
  .col-m-6 {
    width: 50%;
  }
  .col-m-7 {
    width: 58.33%;
  }
  .col-m-8 {
    width: 66.66%;
  }
  .col-m-9 {
    width: 75%;
  }
  .col-m-10 {
    width: 83.33%;
  }
  .col-m-11 {
    width: 91.66%;
  }
  .col-m-12 {
    width: 100%;
  }
}

@media only screen and (max-width: $bp-small) {
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
}
