@import "../../assets/scss/base.scss";

.lp-home-banner {
  width: 100%;
  max-width: 100vw;
  height: 62vw;
  max-height: 100vh;
  min-height: 800px;
  position: relative;

  * {
    box-sizing: border-box;
  }

  @media only screen and (max-width: 1400px) {
    height: 100vh;
  }

  @media only screen and (max-width: 1400px) {
    height: 100vh;
  }
  @media only screen and (max-width: $bp-small) {
    min-height: 500px;
    max-height: calc(100vh - 80px);
  }

  .home-banner__container {
    height: 100%;
    display: flex;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media only screen and (max-width: $bp-small) {
      justify-content: flex-start;
    }

    .home-banner__left {
      @media only screen and (max-width: $bp-small) {
        margin-top: 165px;
      }

      .home-banner__subtitle {
        text-transform: uppercase;
        font-family: "montserrat-bold", sans-serif;
        font-size: 32px;
        line-height: 39px;
        color: #fff;
        padding: 0 0 15px 20px;

        @media only screen and (max-width: $bp-medium) {
          padding: 0 20px 15px 20px;
          text-align: center;
        }

        @media only screen and (max-width: $bp-small) {
          font-size: 16px;
          line-height: 19px;
        }
      }
      .home-banner__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 66px;
        line-height: 81px;
        color: #fff;
        padding: 200px 0 54px 20px;

        span {
          font-size: 37px;
          white-space: nowrap;

          @media only screen and (max-width: $bp-medium) {
            white-space: normal;
          }
        }

        @media only screen and (max-width: $bp-medium) {
          padding: 0 20px 18px 20px;
          text-align: center;
        }
        @media only screen and (max-width: $bp-small) {
          font-size: 38px;
          line-height: 47px;

          span {
            font-size: 24px;
          }
        }
      }

      .home-banner__text {
        font-family: "montserrat-bold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #fff;
        padding: 0 0 18px 20px;

        @media only screen and (max-width: $bp-medium) {
          padding: 0 20px 18px 20px;
          max-width: 288px;
          margin: 0 auto;
        }

        @media only screen and (max-width: $bp-small) {
          font-size: 16px;
          line-height: 19px;
        }
      }
      .home-banner__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 62px;
        line-height: 62px;
        width: 276px;
        text-transform: unset;
        margin: 0 0 20px 20px;

        @media only screen and (max-width: $bp-medium) {
          height: 52px;
          line-height: 52px;
          width: 288px;
          margin: 0 auto 20px auto;
        }

        .home-banner__button-icon {
          font-size: 30px;
          margin-right: 10px;
          line-height: unset;

        }
        .home-banner__button-text {
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px;
        }
      }
    }

    .home-banner__media {
      width: 100%;
      padding: 150px 0 25px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 60px;
      left: 0;

      @media only screen and (max-width: $bp-medium) {
        display: none;
      }
      .contact__icon-box {
        color: #fff;
        text-decoration: none;
        text-align: center;
        font-size: 14px;
        font-family: "montsserat-bold", sans-serif;
        line-height: 18px;
      }
      .contact__icon {
        color: #fff;
        display: block;
        margin-top: 10px;
        font-size: 32px;
      }
    }
  }
}
