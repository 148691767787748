body {
  position: relative;
  box-sizing: border-box;
}

.text-basic {
  font-family: "montserrat", sans-serif;
  font-size: 17px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
}

.title-basic {
  font-family: "montserrat-bold", sans-serif;
  font-size: 38px;
    line-height: 47px;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
}

.button-basic {
  font-family: "montserrat-medium", sans-serif;
  display: block;
  color: #fff;
  outline: 0;
  text-decoration: none;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
}

.button-white{
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  position: relative;
  backface-visibility: hidden;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(255,255,255,1);
    -webkit-transform: translate3d(-100%, -50%, 0);
    transform: translate3d(-100%, -50%, 0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
  }

  &:hover {
    color: #141313;
    &:before {
      transform: translate3d(0, 0, 0);
    }
  }
}

.button-black{
  border: 2px solid #141313;
  color: #141313;
  background-color: transparent;
  position: relative;
  backface-visibility: hidden;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #141313;
    -webkit-transform: translate3d(-100%, -50%, 0);
    transform: translate3d(-100%, -50%, 0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
  }

  &:hover {
    color: #fff;
    &:before {
      transform: translate3d(0, 0, 0);
    }
  }
}

.button-white-other{
  border: 2px solid #efefef;
  color: #141313;
  background-color: #efefef;
  position: relative;
  backface-visibility: hidden;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(255,255,255,1);
    border: 2px solid #fff;
    -webkit-transform: translate3d(-100%, -50%, 0);
    transform: translate3d(-100%, -50%, 0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;

  }

  &:hover {
    &:before {
      transform: translate3d(0, 0, 0);
    }
  }
}