@import "../../assets/scss/base.scss";

.meeting-label {
  width: 100%;
  height: 50px;
  background-color: #b79c66;
  align-items: center;
  justify-content: center;
  display: none;

  @media only screen and (max-width: $bp-small) {
    display: flex;
  }

  .meeting-label__link {
    display: block;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-family: "montserrat-medium", sans-serif;
    font-size: 16px;
    line-height: 19px;

    .meeting-label__icon {
      color: #fff;
      font-size: 22px;
      margin-left: 12px;
    }
  }
}
