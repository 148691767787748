@import "../../assets/scss/base.scss";

.lp-search {
  background: rgba(193, 198, 200, 0.1);
  padding: 50px 0;

  @media only screen and (max-width: $bp-small) {
    padding: 50px 0 25px 0;
  }

  .search__container {
    .search__title {
      padding-bottom: 50px;
      text-align: center;
      box-sizing: border-box;
      font-family: "montserrat-bold", sans-serif;
      font-size: 38px;
      line-height: 47px;
      color: #141313;

      @media only screen and (max-width: $bp-medium) {
        padding: 0 20px 50px 20px;
      }
      @media only screen and (max-width: $bp-small) {
        padding: 0 20px 25px 20px;
        font-size: 28px;
        line-height: 34px;
      }
    }

    .search__form {
      .button {
        font-family: "montserrat-medium", sans-serif;
        font-size: 12px;
        line-height: 22px;
        display: inline-block;
        text-decoration: none;
      }

      .search__filters-show {
        color: #fff;
        width: 205px;
        height: 50px;
        line-height: 50px;
        background-color: #b79c66;
        display: none;
        cursor: pointer;
        margin: 0 auto 30px auto;
        font-size: 16px;

        @media only screen and (max-width: $bp-medium) {
          display: block;
        }
        @media only screen and (max-width: $bp-small) {
          height: 43px;
          line-height: 43px;
          margin: 0 auto;
        }
      }

      .search__criteria__list {
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: $bp-medium) {
          display: none;

          &.mobile-active {
            display: flex;
          }
        }

        .search__column {
          .search__criteria {
            font-size: 12px;
            margin-bottom: 40px;

            @media only screen and (max-width: $bp-large) {
              text-align: center;
            }
            @media only screen and (max-width: $bp-small) {
              margin: 20px 10px;
            }

            &.vertical {
              @media only screen and (max-width: $bp-large) {
                text-align: center;
              }
            }

            .search__text {
              font-family: "montserrat-bold", sans-serif;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: #35363a;
              margin-bottom: 15px;
            }

            .horizontal-radio-parent {
              display: flex;
              align-items: center;

              @media only screen and (max-width: $bp-large) {
                justify-content: center;
              }

              &.vertical {
                @media only screen and (max-width: $bp-large) {
                  justify-content: center;
                }
              }

              .search__text {
                margin-bottom: 0;
                margin-right: 10px;
              }

              .horizontal-radio {
                padding-left: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &.storey {
                  @media only screen and (max-width: $bp-small) {
                    flex-wrap: wrap;

                    li {
                      label {
                        margin: 3px 5px;
                      }
                    }
                  }
                }

                li {
                  font-size: 12px;
                  display: block;

                  label {
                    border: 1px solid #b79c66;
                    margin: 0 3px;
                    display: block;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    transition: 0.5s;
                    cursor: pointer;
                    text-align: center;

                    &:hover {
                      background-color: #b79c66;
                      color: #fff;
                    }
                  }
                }

                &.vertical {
                  width: 100%;
                  align-items: center;
                  justify-content: flex-start;
                  flex-wrap: wrap;

                  @media only screen and (max-width: $bp-large) {
                    justify-content: center;
                  }

                  li {
                    width: auto;
                    margin: 5px 10px;

                    label {
                      min-width: 110px;
                      width: auto;
                      padding: 0 5px;
                      cursor: pointer;
                      height: 40px;
                      line-height: 40px;
                      text-align: center;
                      vertical-align: middle;
                    }
                  }
                }

                input[type="radio"] {
                  display: none;

                  &:checked + label {
                    background: #b79c66;
                    color: #fff;
                  }
                }
                input[type="checkbox"] {
                  display: none;

                  &:checked + label {
                    background: #b79c66;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      .search__actions {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: $bp-medium) {
          display: none;
          margin-top: 20px;
          padding-bottom: 40px;

          &.mobile-active {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        .search__button {
          width: 200px;
          height: 50px;
          background-color: #b79c66;
          line-height: 50px;
          margin-right: 30px;
          margin-left: 30px;
          font-size: 16px;

          @media only screen and (max-width: $bp-medium) {
            margin: 20px auto 20px auto;
          }
          @media only screen and (max-width: $bp-small) {
            height: 43px;
            line-height: 43px;
          }
        }

        .clean__button {
          width: 200px;
          height: 50px;
          line-height: 50px;
          display: inline-block;
          border: 0;
          color: #000;
          background: #fff;
          font-size: 16px;

          @media only screen and (max-width: $bp-medium) {
            margin: 0 auto;
          }
          @media only screen and (max-width: $bp-small) {
            height: 43px;
            line-height: 43px;
          }
        }
      }
    }
  }
}
