@import "../../assets/scss/base.scss";

.lp-time {
  width: 100%;
  max-width: 100vw;
  height: 1280px;
  position: relative;
  overflow-x: hidden;

  @media only screen and (max-width: $bp-medium) {
    background: transparent;
    height: auto;
  }

  &:after {
    content: "";
    display: block;
    width: 69%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    background: url("../../assets/images/time-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }

  .time__container {
    height: 100%;

    .time__top {
      display: flex;
      justify-content: flex-end;
      height: 50%;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column;
      }

      .time__top-left {
        display: none;
        width: 100%;

        @media only screen and (max-width: $bp-medium) {
          display: block;
        }
        .time__background {
          width: 100%;
          height: auto;
          object-fit: contain;

          @media only screen and (max-width: $bp-small) {
            width: 100%;
            height: 100vw;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      .time__top-right {
        background-color: #ffffff;
        height: 100%;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: $bp-medium) {
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          min-width: 100%;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          transform: translateX(99%);

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .time__title {
          font-family: "montserrat-bold", sans-serif;
          font-size: 38px;
          line-height: 47px;
          color: #363636;
          padding: 0 20px 0 115px;

          @media only screen and (max-width: $bp-large) {
            padding: 42px 20px 0 20px;
          }

          @media only screen and (max-width: $bp-small) {
            font-size: 28px;
            line-height: 34px;
          }
        }

        .time__text {
          font-family: "montserrat", sans-serif;
          font-size: 17px;
          line-height: 30px;
          color: #141313;
          padding: 32px 20px 32px 115px;
          max-width: 380px;

          @media only screen and (max-width: $bp-large) {
            padding: 32px 20px 32px 20px;
          }
        }
        .time__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          line-height: 47px;
          width: 221px;

          text-transform: unset;
          margin: 0 0 0 115px;
          font-family: "montserrat-medium", sans-serif;
          font-size: 16px;

          @media only screen and (max-width: $bp-large) {
            margin: 0 0 0 20px;
          }
          @media only screen and (max-width: $bp-medium) {
            margin: 0 0 42px 20px;
          }
        }
      }
    }

    .time__bottom {
      height: 50%;

      @media only screen and (max-width: $bp-medium) {
        height: auto;
        flex-direction: column;
      }

      .time__bottom-left {
        position: relative;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: url("../../assets/images/time1.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .time__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none;

          @media only screen and (max-width: $bp-medium) {
            display: block;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }

      .time__bottom-right {
        position: relative;
        height: 100%;

        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: auto;
        }

        &:before {
          content: "";
          display: block;
          width: calc(100% + 200px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url("../../assets/images/time2.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .time__background {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: none;

          @media only screen and (max-width: $bp-medium) {
            display: block;
          }
          @media only screen and (max-width: $bp-small) {
            width: auto;
            height: 100vw;
            object-fit: cover;
          }
        }
      }
    }
  }
}
