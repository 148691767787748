@import "../../assets/scss/base.scss";

.lp-dluga-section {
  width: 100%;
  max-width: 100vw;
  height: 59vw;
  max-height: 100vh;
  background: url("../../assets/images/dluga15-exterior-tram.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media only screen and (max-width: $bp-medium) {
    background: transparent;
    height: auto;
    max-height: auto;
  }

  .dluga-section__container {
    height: 100%;

    @media only screen and (max-width: $bp-medium) {
      flex-direction: column;
    }

    .dluga-section__left {
      background-color: #f8f8f8;
      height: 415px;
      position: relative;

      @media only screen and (max-width: $bp-medium) {
        width: 100%;
        height: auto;
        padding-bottom: 42px;
      }

      &:before {
        content: "";
        display: block;
        min-width: 100%;
        width: calc(100% + 200px);
        height: 415px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f8f8f8;
        transform: translateX(-99%);

        @media only screen and (max-width: $bp-medium) {
          display: none;
        }
      }

      .dluga-section__title {
        font-family: "montserrat-bold", sans-serif;
        font-size: 42px;
        line-height: 52px;
        color: #363636;
        padding: 80px 20px 0 20px;

        @media only screen and (max-width: $bp-medium) {
          padding: 42px 20px 0 20px;
        }

        @media only screen and (max-width: $bp-small) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      .dluga-section__text {
        font-family: "montserrat", sans-serif;
        font-size: 17px;
        line-height: 30px;
        color: #363636;
        padding: 32px 20px 32px 20px;
        max-width: 380px;

        @media only screen and (max-width: $bp-medium) {
          padding: 32px 20px 32px 20px;
        }
      }
      .dluga-section__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 47px;
        line-height: 47px;
        width: 221px;
        text-transform: unset;
        margin: 0 20px 0 20px;
        font-family: "montserrat-medium", sans-serif;
        font-size: 16px;
      }
    }

    .dluga-section__right {
      display: none;

      @media only screen and (max-width: $bp-medium) {
        display: block;
      }

      .dluga__background {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}
