@import "../../assets/scss/base.scss";

.lp-buildings-banner {
  width: 100%;
  height: auto;
  margin-top: 85px;
  max-width: 100vw;

  @media only screen and (max-width: $bp-small) {
    margin-top: 70px;

  }

  .buildings-banner__wrapper {
    width: 100%;
    max-width: 100%;
    height: 52vw;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: $bp-medium) {
      height: 62vw;
    }

    .buildings-banner__image {
      width: 150vw;
      max-width: 150vw;
      height: 67vw;
      object-fit: contain;
      z-index: 0;
      position: absolute;
      top: -140px;

      right: 0;

      @media only screen and (max-width: $bp-medium) {
        top: 0;
      }
    }

    .buildings-banner__mapper {
      position: absolute;
      right: 0;
      top: -140px;

      width: 150vw;
      max-width: 150vw;
      height: 67vw;

      z-index: 1;

      @media only screen and (max-width: $bp-medium) {
        top: 0;
      }
    }
  }

  .buildings-banner__container {
    height: 100%;
    display: flex;
    align-items: flex-end;

    .buildings-banner__media {
      width: 100%;
      padding: 25px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .contact__icon-box {
        color: #fff;
        text-decoration: none;
      }
      .contact__icon {
        color: #fff;
        display: block;
      }
      .af-icon-scroll {
        font-size: 42px;
      }
    }
  }
}
