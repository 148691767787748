@import "../../assets/scss/base.scss";

.lp-radius {
  width: 100%;
  max-width: 100vw;
  height: 56vw;
  max-height: 57vw;
  position: relative;
  overflow: hidden;

  .radius__background-wrapper {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    transition: 0.5 ease-in-out;


    .radius__background {
      width: calc(100% + 400px);
      height: calc(100% + 400px);
      object-fit: cover;
      object-position: center;
    }
  }
  .radius__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 102%;
    height: 102%;
    object-fit: cover;
    transition: 0.2 ease-in-out;

  }
}
