.google-map {
  width: 100%;
  height: 100%;
}
.map-marker {
  .map-marker-circle {
    width: 34px;
    height: 34px;
    background: #2f2a31;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    span {
      display: block;
      font-size: 17px;
      color: #cfae77;

      &::before {
        line-height: inherit;
      }
    }

    .map-pin-name{
      background: #141414;
      color:#fff;
      position: absolute;
      left:40px;
      transform: unset;
      margin:0;
      padding:5px 8px;
      font-size: 12px;
      line-height: 14px;
      width:100px;
    }
  }
}

.main-pin {
  display: block;
  position: relative;
  transform: translateY(-100%);
  background: #fff;
  border-bottom: 4px solid #b79c66;
  width: 162px;
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.75);
box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.75);

  &:after {
    content: "";
    display: block;
    width: 28px;
    height: 25px;
    position: absolute;
    bottom: -25px;
    left:10px;
    background:url('../../assets/images/map-pin-element.svg');
    background-size: contain;
    background-repeat: no-repeat;

  }
  img {
    width: 117.4px;
    height: 33.35px;
    object-fit: contain;
  }
}
